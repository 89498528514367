import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Input from '../../../Components/Common/Input'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Users = (props: any) => {
  const { sessionStore, organizationStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language, user } = sessionStore
  const { loading, organizationUsers, users } = organizationStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      organizationStore.getAllUsers()
    } else {
      organizationStore.getMyOrganizationUsers()
    }
  }, [])

  const toUser = (row: any) => {
    if (row?.organizationId) {
      navigate(`/organizations/${row?.organizationId}/users/${row?.id}`)
    } else {
      // Admin user
      navigate(`/users/admin/${row.id}`)
    }
  }

  const toInviteUser = () => navigate(`/users/invite`)

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: t('user_name'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={`${params.row.firstName} ${params.row.lastName}`.trim()}
          onClick={() => toUser(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'role',
      headerName: t('user_role'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => t(params.value)
    },
    {
      field: 'archivedAt',
      headerName: t('status'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params?.value ? t('archived') : t('active')
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true
    },
    {
      field: 'phone',
      headerName: t('phone_number'),
      flex: 1,
      maxWidth: 250,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
    },
    /*
    {
      field: 'lastSeen',
      headerName: t('last_login_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          const datetime = DateTime.fromISO(params.value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH:mm')}`
        }
        return '-'
      }
    }
    */
  ], [language])

  const rows = useMemo(() => {
    let foundUsers = ['superadmin', 'admin'].includes(user?.role) ? users : organizationUsers
    if (searchKey) {
      foundUsers = users.filter((user: any) => `${user?.firstName} ${user?.lastName}`?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return foundUsers
  }, [organizationUsers, users, searchKey, filters])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={toInviteUser}
      />
      <Table rows={rows} columns={columns} loading={loading} />
    </Box>
  )
}

export default observer(Users)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    p: '1.25rem',
    border: `1px solid ${Colors.border}`,
    borderBottom: 0,
    borderTopLeftRadius: '0.625rem',
    borderTopRightRadius: '0.625rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  }
} as const
