import React from 'react'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Logo from '../../Assets/Icons/logo-full.png'
import { Colors } from '../../Utils/theme'

const AuthLayout = (props: any) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.contentContainer}>
        <Box component='img' src={Logo} sx={styles.logo} alt='logo' />
        <Box component='main' sx={styles.content}>
          {props.children}
        </Box>
      </Box>
      <Typography variant='body2' sx={styles.footerText}>
        @ Copyright Premium Disc Golf Oy
      </Typography>
    </Box>
  )
}

export default observer(AuthLayout)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    background: Colors.brandBlue,
    padding: {
      xs: '0 2rem'
    }
  },
  contentContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    width: {
      xxl: '20rem',
      xs: '15rem'
    },
    height: 'auto',
    margin: {
      xxl: '6rem 0 6rem 0',
      xl: '5rem 0 5rem 0',
      sm: '4rem 0 4rem 0',
      xs: '2rem 0 3rem 0'
    }
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      lg: 'center',
      xs: 'flex-start'
    },
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden'
  },
  footerText: {
    color: Colors.white,
    textAlign: 'center',
    margin: '1.5rem 0'
  }
} as const
