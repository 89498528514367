import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import BackIcon from '../../Assets/Icons/back.svg'
import SortIcon from '../../Assets/Icons/sort.svg'
import { ButtonBase, Typography } from '@mui/material'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  back: BackIcon,
  sort: SortIcon
}

const SquareButton = React.forwardRef((props: any, ref: any) => {
  const { text, sx, variant, onClick, loading, ...rest } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.orangeButton }

    if (props.blue) buttonStyles = { ...styles.blueButton }
    if (props.grey) buttonStyles = { ...styles.greyButton }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props?.mr) buttonStyles.mr = props.mr
    if (props?.ml) buttonStyles.ml = props.ml
    if (props?.mt) buttonStyles.mt = props.mt
    if (props?.size) {
      buttonStyles.height = props.size
      buttonStyles.width = props.size
    }

    return buttonStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props?.iconSize) {
      iconStyles.height = props.iconSize
      iconStyles.width = 'auto'
    }
    return iconStyles
  }

  return (
    <ButtonBase
      sx={getStyles()}
      onClick={onClick}
      disabled={loading}
      ref={ref ?? null}
      {...rest}
    >
      <Box component='img' src={props.icon} sx={getIconStyle()} />
    </ButtonBase>
  )
})

export default SquareButton

const styles = {
  orangeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.25rem',
    width: '2.25rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.orange,
    ':hover': {
      backgroundColor: Colors.orangeDarker,
      boxShadow: 0
    },
  },
  blueButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.25rem',
    width: '2.25rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.brandPrimary,
    ':hover': {
      backgroundColor: Colors.brandPrimaryDarker,
      boxShadow: 0
    },
  },
  greyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.25rem',
    width: '2.25rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.chip,
    ':hover': {
      backgroundColor: Colors.chipDarker,
      boxShadow: 0
    },
  },
  icon: {
    height: '1.375rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
  }
} as const
