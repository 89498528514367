import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import BackIcon from '../../Assets/Icons/back.svg'
import SortIcon from '../../Assets/Icons/sort.svg'
import { Colors } from '../../Utils/theme'
import { ButtonBase, Typography } from '@mui/material'

const IconMappings: any = {
  back: BackIcon,
  sort: SortIcon
}

const IconButton = React.forwardRef((props: any, ref: any) => {
  const { text, sx, variant, onClick, loading, ...rest } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props.mb) buttonStyles.marginBottom = props.mb
    if (props.mt) buttonStyles.marginTop = props.mt
    if (props.mr) buttonStyles.marginRight = props.mr
    if (props.ml) buttonStyles.marginLeft = props.ml
    if (props?.width) buttonStyles.width = props.width
    if (props?.height) buttonStyles.height = props.height
    return buttonStyles
  }

  const getIconStyles = () => {
    let iconStyles: any = { ...styles.icon }
    if (props.iconSx) {
      iconStyles = { ...iconStyles, ...props.iconSx }
    }
    if (props?.iconSize) {
      iconStyles = { ...iconStyles, width: props?.iconSize, height: props?.iconSize }
    }
    return iconStyles
  }

  const renderIcon = () => {
    return (
      <Box
        component='img'
        src={props.icon}
        sx={getIconStyles()}
      />
    )
  }

  const renderText = () => {
    if (props.text) {
      return <Typography sx={styles.text}>{props.text}</Typography>
    }
    return null
  }

  return (
    <ButtonBase
      sx={getStyles()}
      variant={variant ?? 'contained'}
      onClick={onClick}
      disabled={loading}
      ref={ref ?? null}
      {...rest}
    >
      {renderIcon()}
      {renderText()}
    </ButtonBase>
  )
})

export default IconButton

const styles = {
  button: {
    height: '3.125rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '0.75rem 1.75rem',
    boxShadow: 0,
    ':hover': {
      backgroundColor: 'transparent',
      boxShadow: 0
    },
    ':disabled': {
      backgroundColor: 'transparent',
      color: Colors.text
    }
  },
  text: {
    fontSize: '1.125rem',
    color: Colors.text,
    marginLeft: '0.5rem'
  },
  progress: {
    color: Colors.white
  },
  icon: {
    height: '1rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none'
  }
} as const
