import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { validateMessageInput } from '../../../Utils/validation'
import { PublicMessageTypes } from '../../../Utils/constants'
import { t } from 'i18next'
import LangSelect from '../../../Components/Common/LangSelect'
import AttachmentModal from '../../../Components/Common/AttachmentModal'
import TimeInput from '../../../Components/Common/TimeInput'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Colors } from '../../../Utils/theme'

const Message = (props: any) => {
  const { sessionStore, fieldStore, messageStore }: any = useStore()
  const { user } = sessionStore
  const { message } = messageStore

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [lang, setLang] = useState('fi')
  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [titleSv, setTitleSv] = useState('')
  const [type, setType] = useState('general')
  const [receivers, setReceivers] = useState('all')
  const [trackId, setTrackId] = useState(null)
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [contentSv, setContentSv] = useState('')
  const [htmlFi, setHtmlFi] = useState('')
  const [htmlEn, setHtmlEn] = useState('')
  const [htmlSv, setHtmlSv] = useState('')
  const [linkType, setLinkType] = useState(null)
  const [linkTarget, setLinkTarget] = useState('')
  const [photo, setPhoto] = useState(null)
  const [video, setVideo] = useState(null)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [attachmentType, setAttachmentType] = useState('')
  const [publishMode, setPublishMode] = useState('now')
  const [publishedAtTime, setPublishedAtTime] = useState<any>(null)
  const [publishedAtDate, setPublishedAtDate] = useState<any>(null)

  const openAddPhotoAttachmentModal = () => {
    setShowAttachmentModal(true)
    setAttachmentType('photo')
  }
  const closePhotoAttachmentModal = () => {
    setShowAttachmentModal(false)
    setAttachmentType('')
  }
  const openAddVideoAttachmentModal = () => {
    setShowAttachmentModal(true)
    setAttachmentType('video')
  }
  const closeVideoAttachmentModal = () => {
    setShowAttachmentModal(false)
    setAttachmentType('')
  }

  const updatePhoto = (photo: any) => {
    setPhoto(photo)
    closePhotoAttachmentModal()
  }
  const updateVideo = (video: any) => {
    setVideo(video)
    closeVideoAttachmentModal()
  }

  useEffect(() => {
    if (sessionStore.user?.role === 'field_admin') {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
      messageStore.getOrganizationTrackMessage(params.trackId, params.messageId)
    } else if (sessionStore.user?.role === 'admin') {
      messageStore.getMessage(params.messageId)
    }
  }, [])

  useEffect(() => {
    if (message) {
      setTrackId(message.trackId)
      setTitleFi(message?.titleFi || '')
      setTitleEn(message.titleEn || '')
      setTitleSv(message.titleSv || '')
      setContentFi(message.contentFi || '')
      setContentEn(message.contentEn || '')
      setContentSv(message.contentSv || '')
      setHtmlFi(message.htmlFi || '')
      setHtmlEn(message.htmlEn || '')
      setHtmlSv(message.htmlSv || '')
      setType(message.type || 'general')
      setLinkType(message.linkType || null)
      setLinkTarget(message.linkTarget || null)
      setPhoto(message.photo || null)
      setVideo(message.video || null)
      setPublishedAtTime(DateTime.fromISO(message.publishedAt).toFormat('HH:mm'))
      setPublishedAtDate(DateTime.fromISO(message.publishedAt).toFormat('yyyy-MM-dd'))
      if (message?.publishedAt) {
        setPublishMode('timed')
      }
    }
  }, [message])

  const getTypeOptions = () => {
    return PublicMessageTypes.map((value: any) => {
      return { value, label: t(value) }
    })
  }

  const getReceiverOptions = () => {
    if (['admin', 'superadmin']?.includes(user?.role)) {
      return [
        { value: 'all', label: t('all') }
      ]
    }
    return []
  }

  const getTrackOptions = () => {
    if (['field_admin']?.includes(user?.role)) {
      const tracks = flatten(fieldStore.fields?.map((field: any) => field?.tracks?.map((tracks: any) => ({ ...tracks, field }) || null).filter((track: any) => track) || []))
      return tracks.map((track: any) => {
        return { value: track.id, label: `${track?.field?.nameFi} - ${track?.nameFi}` }
      })
    }
    return []
  }

  const getLinkTypeOptions = () => {
    return [
      { value: 'app_screen', label: t('app_screen') },
      { value: 'url', label: t('url') }
    ]
  }

  const getLinkTargetOptions = () => {
    return [
      { value: 'TracksScreen', label: t('tracks') },
      { value: 'ProShopScreen', label: t('proshop') },
      { value: 'OwnScreen', label: t('own') },
      { value: 'MessagesScreen', label: t('messages') }
    ]
  }

  const save = () => {
    let publishedAt: any = null
    if (publishMode !== 'now') {
      publishedAt = DateTime
        .fromFormat(`${publishedAtDate} ${publishedAtTime}`, `yyyy-MM-dd HH:mm`)
        .toJSDate()
    }

    const message = {
      type,
      titleFi,
      titleEn,
      titleSv,
      contentFi,
      contentEn,
      contentSv,
      htmlFi,
      htmlEn,
      htmlSv,
      photo,
      video,
      linkType,
      linkTarget,
      publishedAt
    }

    if (['admin', 'superadmin']?.includes(user?.role)) {
      messageStore.updateMessageInfo(message)
    } else {
      messageStore.updateTrackMessage(
        sessionStore.user?.organizationId,
        trackId,
        message
      )
    }
  }

  const cancel = () => navigate(-1)

  const getIsSaveDisabled = () => {
    if (['admin', 'superadmin']?.includes(user?.role)) {
      if (!receivers || receivers !== 'all') {
        return true
      }
    } else {
      if (!trackId) {
        return true
      }
    }

    const isValid = validateMessageInput({
      titleFi,
      titleEn,
      titleSv,
      type,
      receivers,
      contentFi,
      contentEn,
      contentSv,
      htmlFi,
      htmlEn,
      htmlSv,
      photo,
      video,
      publishMode,
      publishedAtTime,
      publishedAtDate
    })
    return !isValid
  }

  const renderCategorySelect = () => {
    if (['admin', 'superadmin']?.includes(user?.role)) {
      return (
        <Select
          label={t('category')}
          options={getTypeOptions()}
          value={type}
          onChange={setType}
          width='24rem'
          mr='2rem'
        />
      )
    }
    return null
  }

  const renderReceiverSelect = () => {
    if (['admin', 'superadmin']?.includes(user?.role)) {
      return (
        <Select
          label={t('receivers')}
          options={getReceiverOptions()}
          value={receivers}
          onChange={setReceivers}
        />
      )
    } else {
      return (
        <Select
          label={t('track')}
          options={getTrackOptions()}
          value={trackId}
          onChange={setTrackId}
        />
      )
    }
  }

  const renderLinkTargetSelect = () => {
    if (linkType) {
      if (linkType === 'app_screen') {
        return (
          <Select
            label={t('app_screen')}
            options={getLinkTargetOptions()}
            value={linkTarget}
            onChange={setLinkTarget}
            width='17rem'
          />
        )
      }
      return (
        <Input
          label={t('url')}
          value={linkTarget}
          onChange={setLinkTarget}
          width='17rem'
        />
      )
    }
    return null
  }

  const renderLinkingSection = () => {
    if (['admin', 'superadmin']?.includes(user?.role)) {
      if (receivers === 'all') {
        return (
          <>
            <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('linking')}</Typography>
            <Box sx={styles.formContainer}>
              <Box sx={styles.row}>
                <Select
                  label={t('link')}
                  options={getLinkTypeOptions()}
                  value={linkType}
                  onChange={setLinkType}
                  width='17rem'
                  mr='2rem'
                />
                {renderLinkTargetSelect()}
              </Box>
            </Box>
          </>
        )
      }
    }
    return null
  }

  const renderAttachmentActions = () => {
    if (photo) {
      return (
        <>
          <Box component='img' src={photo} sx={styles.photo} />
          <Box sx={styles.row}>
            <Button
              text={t('change_photo')}
              onClick={openAddPhotoAttachmentModal}
              width='11rem'
              mr='2rem'
            />
            <Button
              text={t('remove_photo')}
              onClick={() => setPhoto(null)}
              variant='secondary'q
              width='11rem'
            />
          </Box>
        </>
      )
    } else if (video) {
      return (
        <>
          <Box sx={styles.row}>
            <Typography variant='body1' mb='0.5rem' ml='0.5rem' fontSize='1.125rem'>
              {video}
            </Typography>
          </Box>
          {/* <Box component='video' src={video} sx={styles.photo} controls /> */}
          <Box sx={styles.row}>
            <Button
              text={t('change_video')}
              onClick={openAddVideoAttachmentModal}
              width='11rem'
              mr='2rem'
            />
            <Button
              text={t('remove_video')}
              onClick={() => setVideo(null)}
              variant='secondary'
              width='11rem'
            />
          </Box>
        </>
      )
    } else {
      return (
        <Box sx={styles.row}>
          <Button
            text={t('add_photo')}
            onClick={openAddPhotoAttachmentModal}
            width='11rem'
            mr='2rem'
          />
          <Button
            text={t('add_video')}
            onClick={openAddVideoAttachmentModal}
            variant='secondary'
            width='11rem'
          />
        </Box>
      )
    }
  }

  const renderAttachmentModal = () => {
    if (showAttachmentModal) {
      return (
        <AttachmentModal
          type={attachmentType}
          url={attachmentType === 'photo' ? photo : video}
          onClose={attachmentType === 'photo' ? closePhotoAttachmentModal : closeVideoAttachmentModal}
          onSave={attachmentType === 'photo' ? updatePhoto : updateVideo}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('edit_message')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.langSelectContainer}>
            <LangSelect
              value={lang}
              onChange={setLang}
              status={{
                fi: titleFi && contentFi,
                en: titleEn && contentEn,
                sv: titleSv && contentSv
              }}
            />
          </Box>
          <Input
            label={t('title')}
            value={lang === 'fi' ? titleFi : lang === 'en' ? titleEn : titleSv}
            onChange={lang === 'fi' ? setTitleFi : lang === 'en' ? setTitleEn : setTitleSv}
            mt='1rem'
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            {renderCategorySelect()}
            {renderReceiverSelect()}
          </Box>
          <Input
            label={t('content')}
            value={lang === 'fi' ? contentFi : lang === 'en' ? contentEn : contentSv}
            onChange={lang === 'fi' ? setContentFi : lang === 'en' ? setContentEn : setContentSv}
            mb='1.5rem'
            multiline
          />
        </Box>
        {renderLinkingSection()}
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('attachments')}</Typography>
        <Box sx={styles.formContainer}>
          {renderAttachmentActions()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('send')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Box sx={styles.timesFormContainer}>
            <FormControl>
              <RadioGroup onChange={(event) => setPublishMode(event.target.value)}>
                <FormControlLabel
                  value='now'
                  control={<Radio checked={publishMode === 'now'} />}
                  label={t('immediately')}
                />
                <FormControlLabel
                  value='timed'
                  control={<Radio checked={publishMode === 'timed'} />}
                  label={t('timed')}
                />
              </RadioGroup>
            </FormControl>
            <Collapse in={publishMode !== 'now'}>
              {publishMode !== 'now' && (
                <Box sx={styles.timesContainer}>
                  <Input
                    value={publishedAtDate}
                    onChange={setPublishedAtDate}
                    width='10rem'
                    type='date'
                  />
                  <TimeInput
                    value={publishedAtTime}
                    onChange={setPublishedAtTime}
                    width='5.625rem'
                  />
                </Box>
              )}
            </Collapse>
          </Box>
          <Button
            text={t('save')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={getIsSaveDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
      {renderAttachmentModal()}
    </Box>
  )
}

export default observer(Message)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem',
    pb: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '45rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '24rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.25rem 1.25rem 1.25rem 1.25rem'
  },
  langSelectContainer: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  timesFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    backgroundColor: Colors.light,
    p: '1rem 2rem 2rem 2rem',
    mb: '2rem'
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    mt: '1rem'
  },
  photo: {
    width: '100%',
    height: 'auto',
    maxHeight: '20rem',
    borderRadius: '0.625rem',
    mb: '2rem'
  }
} as const
