import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Input from './Input'
import Button from './Button'
import Select from './Select'
import { Colors } from '../../Utils/theme'

const TableActions = (props: any) => {
  const { t } = useTranslation()

  const renderSearchInput = () => {
    if (props?.updateSearch) {
      return (
        <Input
          placeholder={t('search_by_name')}
          value={props.searchKey}
          onChange={props.updateSearch}
          onAction={props?.clearSearch}
          iconSx={styles.clearIcon}
          sx={styles.searchInput}
          mr='1.5rem'
        />
      )
    }
    return null
  }

  const renderFilterButton = () => {
    if (props?.onFilterClick) {
      return (
        <Button
          text={t('filter')}
          onClick={props.onFilterClick}
          icon='sort'
          sx={styles.actionButton}
          width='14rem'
        />
      )
    }
    return null
  }

  const renderBulkActionSelect = () => {
    if (props?.onBulkActionSelect) {
      return (
        <Select
          placeholder={t('bulk_actions')}
          options={props.bulkActionOptions}
          value={props.bulkAction}
          onChange={props.onBulkActionSelect}
          sx={styles.bulkActionSelect}
          ml='1.5rem'
        />
      )
    }
    return null
  }

  const renderAddButton = () => {
    if (props?.onAddClick) {
      return (
        <Button
          text={props?.addText || t('add_new')}
          onClick={props.onAddClick}
          sx={styles.actionButton}
          width='11rem'
          ml='1.5rem'
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.actions}>
      <Box sx={styles.row}>
        {renderSearchInput()}
        {/* renderFilterButton() */}
      </Box>
      <Box sx={styles.actionsRow}>
        {renderBulkActionSelect()}
        {renderAddButton()}
      </Box>
    </Box>
  )
}

export default observer(TableActions)

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    p: '1.25rem',
    border: `1px solid ${Colors.border}`,
    borderBottom: 0,
    borderTopLeftRadius: '0.625rem',
    borderTopRightRadius: '0.625rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // flexGrow: 1
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  searchInput: {
    width: {
      xxl: '23rem',
      xs: '20rem'
    }
  },
  actionButton: {
    height: '3.125rem',
    padding: {
      xxl: '0rem 2.5rem',
      xs: '0rem 1.875rem'
    }
  },
  clearIcon: {
    top: '0.675rem',
    right: '1rem',
    width: '1.675rem'
  },
  bulkActionSelect: {
    width: {
      xxl: '16rem',
      xl: '15rem',
      xs: '12rem'
    }
  }
} as const
