import { useTranslation } from 'react-i18next'
import ButtonBase from '@mui/material/ButtonBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from './Button'
import DiscountCodeIcon from '../../Assets/Icons/coupon.svg'
import PaymentCodeIcon from '../../Assets/Icons/code.svg'
import SeasonPassIcon from '../../Assets/Icons/card.svg'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  discount_code: DiscountCodeIcon,
  payment_code: PaymentCodeIcon,
  season_pass: SeasonPassIcon
}

export default function ProShopCategory(props: any) {
  const { t } = useTranslation()
  const { type, name, onClick, onActionClick } = props

  const getContainerStyles = () => {
    let containerStyles: any = { ...styles.categoryContainer }

    if (props?.sx) containerStyles = { ...containerStyles, ...props.sx }
    if (props?.mr) containerStyles.mr = props.mr
    if (props?.ml) containerStyles.ml = props.ml

    return containerStyles
  }

  const getIconStyles = () => {
    let iconStyles: any = { ...styles.categoryIcon }
    if (props?.iconSize) {
      iconStyles = { ...iconStyles, width: props?.iconSize, height: props?.iconSize }
    }
    return iconStyles
  }

  return (
    <Box sx={getContainerStyles()}>
      <ButtonBase sx={styles.category} onClick={onClick}>
        <Box
          component='img'
          sx={getIconStyles()}
          src={IconMappings[type]}
          alt={name}
        />
        <Typography variant='h3'>{name}</Typography>
      </ButtonBase>
      <Button
        text={t('create_new')}
        onClick={onActionClick}
        width='100%'
      />
    </Box>
  )
}

const styles = {
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '18rem'
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem',
    mb: '2rem',
    '&:hover': {
      backgroundColor: Colors.cardBackground70
    }
  },
  categoryIcon: {
    width: '7.5rem',
    height: '7.5rem'
  }
}
