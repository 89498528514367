import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import CheckIcon from '../../Assets/Icons/check.svg'
import { Colors } from '../../Utils/theme'

const CustomCheckbox = React.forwardRef((props: any, ref: any) => {
  const renderUnchecked = () => {
    return <Box sx={styles.uncheckedContainer} />
  }

  const renderChecked = () => (
    <Box sx={styles.checkedContainer}>
      <Box component='img' src={CheckIcon} sx={styles.icon} />
    </Box>
  )

  return <Checkbox {...props} checkedIcon={renderChecked()} icon={renderUnchecked()} />
})

export default CustomCheckbox

const styles = {
  uncheckedContainer: {
    height: '1.625rem',
    width: '1.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.3125rem'
  },
  checkedContainer: {
    height: '1.625rem',
    width: '1.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    borderRadius: '.3125rem'
  },
  icon: {
    width: '1rem',
    height: '1rem'
  }
}