import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Unstable_Grid2'
import { CustomSwitch } from './Switch'
import { Button, Input, Select } from '..'
import { validateNewTrackSeason, validateSeasonDates } from '../../Utils/validation'
import { Colors } from '../../Utils/theme'
import TimeInput from './TimeInput'

export default function CalendarModal(props: any) {
  const { t } = useTranslation()

  const { isFreeTrack } = props

  const lastPhase = isFreeTrack ? 6 : 5
  const dayPhase = isFreeTrack ? 5 : 4

  const [phase, setPhase] = useState(1)
  const [season, setSeason] = useState<any>(null)
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [slotSeats, setSlotSeats] = useState(5)
  const [slotDuration, setSlotDuration] = useState(15)
  const [everyNthReservable, setEveryNthReservable] = useState<any>(isFreeTrack ? 6 : null)
  const [dayStartInitialized, setDayStartInitialized] = useState(false)
  const [dayEndInitialized, setDayEndInitialized] = useState(false)

  const [day1WalkIn, setDay1WalkIn] = useState(false)
  const [day2WalkIn, setDay2WalkIn] = useState(false)
  const [day3WalkIn, setDay3WalkIn] = useState(false)
  const [day4WalkIn, setDay4WalkIn] = useState(false)
  const [day5WalkIn, setDay5WalkIn] = useState(false)
  const [day6WalkIn, setDay6WalkIn] = useState(false)
  const [day7WalkIn, setDay7WalkIn] = useState(false)

  const [day1StartTime, setDay1StartTime] = useState('')
  const [day1EndTime, setDay1EndTime] = useState('')
  const [day2StartTime, setDay2StartTime] = useState('')
  const [day2EndTime, setDay2EndTime] = useState('')
  const [day3StartTime, setDay3StartTime] = useState('')
  const [day3EndTime, setDay3EndTime] = useState('')
  const [day4StartTime, setDay4StartTime] = useState('')
  const [day4EndTime, setDay4EndTime] = useState('')
  const [day5StartTime, setDay5StartTime] = useState('')
  const [day5EndTime, setDay5EndTime] = useState('')
  const [day6StartTime, setDay6StartTime] = useState('')
  const [day6EndTime, setDay6EndTime] = useState('')
  const [day7StartTime, setDay7StartTime] = useState('')
  const [day7EndTime, setDay7EndTime] = useState('')

  useEffect(() => {
    const season = props.season

    if (season) {
      setSeason(season.fieldSeasonId)

      const newStartTime = DateTime
        .fromISO(season.startTime)
        .setZone('Europe/Helsinki')
        .toFormat('yyyy-MM-dd')
      const newEndTime = DateTime
        .fromISO(season.endTime)
        .setZone('Europe/Helsinki')
        .toFormat('yyyy-MM-dd')

      setStartTime(newStartTime)
      setEndTime(newEndTime)

      setSlotSeats(season.slotSeats)
      setSlotDuration(season.slotDuration)

      if (isFreeTrack) {
        setEveryNthReservable(season.everyNthReservable)
      }

      setDay1WalkIn((season.day1StartTime && season.day1EndTime) ? false : true)
      setDay1StartTime(season.day1StartTime)
      setDay1EndTime(season.day1EndTime)

      setDay2WalkIn((season.day2StartTime && season.day2EndTime) ? false : true)
      setDay2StartTime(season.day2StartTime)
      setDay2EndTime(season.day2EndTime)

      setDay3WalkIn((season.day3StartTime && season.day3EndTime) ? false : true)
      setDay3StartTime(season.day3StartTime)
      setDay3EndTime(season.day3EndTime)

      setDay4WalkIn((season.day4StartTime && season.day4EndTime) ? false : true)
      setDay4StartTime(season.day4StartTime)
      setDay4EndTime(season.day4EndTime)

      setDay5WalkIn((season.day5StartTime && season.day5EndTime) ? false : true)
      setDay5StartTime(season.day5StartTime)
      setDay5EndTime(season.day5EndTime)

      setDay6WalkIn((season.day6StartTime && season.day6EndTime) ? false : true)
      setDay6StartTime(season.day6StartTime)
      setDay6EndTime(season.day6EndTime)

      setDay7WalkIn((season.day7StartTime && season.day7EndTime) ? false : true)
      setDay7StartTime(season.day7StartTime)
      setDay7EndTime(season.day7EndTime)
    } else if (props?.seasons?.length === 1) {
      updateSeason(props.seasons[0].id)
    }
  }, [props.season])

  const getPhaseTitle = () => {
    if (phase === 1) {
      return t('calendar_settings_1_title')
    } else if (phase === 2) {
      return t('duration_of_slot')
    } else if (phase === 3) {
      return t('single_slot_participant_count')
    } else if (isFreeTrack && phase === 4) {
      return t('free_track_reservable_slots_title')
    } else if (phase === dayPhase) {
      if (isFreeTrack) {
        if (everyNthReservable === 0) {
          return t('walk_in_times')
        }
        return t('free_track_reservable_slots_times_title')
      }
      return t('track_reservations_required')
    } else if (phase === lastPhase) {
      return t('summary')
    }
    return ''
  }

  const getSeasonOptions = () => {
    return (props?.seasons || []).map((season: any) => {
      return {
        value: season.id,
        label: `${season.nameFi} (${DateTime.fromISO(season.startTime).toFormat('dd.MM.yyyy')} - ${DateTime.fromISO(season.endTime).toFormat('dd.MM.yyyy')})`
      }
    })
  }

  const getEveryNthSlotReservableOptions = () => {
    const everyNthOptions = [2, 3, 4, 5, 6, 7, 8, 9].map((value: number) => {
      return {
        value,
        label: t(`every_${value}_slot`)
      }
    })
    return [
      { value: 0, label: t('no_reservable_slots') },
      { value: 1, label: t('every_slot') },
      ...everyNthOptions
    ]
  }

  const updateSeason = (value: any) => {
    setSeason(value)
    const selectedSeason = props?.seasons.find((season: any) => season.id === value)
    const startTime = selectedSeason ? DateTime.fromISO(selectedSeason.startTime).toFormat('yyyy-MM-dd') : ''
    const endTime = selectedSeason ? DateTime.fromISO(selectedSeason.endTime).toFormat('yyyy-MM-dd') : ''
    setStartTime(startTime)
    setEndTime(endTime)
  }

  const handleSetDay1WalkIn = (value: any) => {
    if (value) {
      setDay1StartTime('')
      setDay1EndTime('')
    }
    setDay1WalkIn(value)
  }
  const handleSetDay2WalkIn = (value: any) => {
    if (value) {
      setDay2StartTime('')
      setDay2EndTime('')
    }
    setDay2WalkIn(value)
  }
  const handleSetDay3WalkIn = (value: any) => {
    if (value) {
      setDay3StartTime('')
      setDay3EndTime('')
    }
    setDay3WalkIn(value)
  }
  const handleSetDay4WalkIn = (value: any) => {
    if (value) {
      setDay4StartTime('')
      setDay4EndTime('')
    }
    setDay4WalkIn(value)
  }
  const handleSetDay5WalkIn = (value: any) => {
    if (value) {
      setDay5StartTime('')
      setDay5EndTime('')
    }
    setDay5WalkIn(value)
  }
  const handleSetDay6WalkIn = (value: any) => {
    if (value) {
      setDay6StartTime('')
      setDay6EndTime('')
    }
    setDay6WalkIn(value)
  }
  const handleSetDay7WalkIn = (value: any) => {
    if (value) {
      setDay7StartTime('')
      setDay7EndTime('')
    }
    setDay7WalkIn(value)
  }

  const handleSetDay1StartTime = (value: any) => {
    setDay1StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day1WalkIn && value) setDay1WalkIn(false)
  }

  const handleSetDay2StartTime = (value: any) => {
    setDay2StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day2WalkIn && value) setDay2WalkIn(false)
  }

  const handleSetDay3StartTime = (value: any) => {
    setDay3StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day3WalkIn && value) setDay3WalkIn(false)
  }

  const handleSetDay4StartTime = (value: any) => {
    setDay4StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day4WalkIn && value) setDay4WalkIn(false)
  }

  const handleSetDay5StartTime = (value: any) => {
    setDay5StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day5WalkIn && value) setDay5WalkIn(false)
  }

  const handleSetDay6StartTime = (value: any) => {
    setDay6StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day6WalkIn && value) setDay6WalkIn(false)
  }

  const handleSetDay7StartTime = (value: any) => {
    setDay7StartTime(value)
    /*
    if (!dayStartInitialized) {
      setDayStartInitialized(true)
      if (!day1WalkIn) setDay1StartTime(value)
      if (!day2WalkIn) setDay2StartTime(value)
      if (!day3WalkIn) setDay3StartTime(value)
      if (!day4WalkIn) setDay4StartTime(value)
      if (!day5WalkIn) setDay5StartTime(value)
      if (!day6WalkIn) setDay6StartTime(value)
      if (!day7WalkIn) setDay7StartTime(value)
    }
    */
    if (day7WalkIn && value) setDay7WalkIn(false)
  }

  const handleSetDay1EndTime = (value: any) => {
    setDay1EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day1WalkIn && value) setDay1WalkIn(false)
  }

  const handleSetDay2EndTime = (value: any) => {
    setDay2EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day2WalkIn && value) setDay2WalkIn(false)
  }

  const handleSetDay3EndTime = (value: any) => {
    setDay3EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day3WalkIn && value) setDay3WalkIn(false)
  }

  const handleSetDay4EndTime = (value: any) => {
    setDay4EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day4WalkIn && value) setDay4WalkIn(false)
  }

  const handleSetDay5EndTime = (value: any) => {
    setDay5EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day5WalkIn && value) setDay5WalkIn(false)
  }

  const handleSetDay6EndTime = (value: any) => {
    setDay6EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day6WalkIn && value) setDay6WalkIn(false)
  }

  const handleSetDay7EndTime = (value: any) => {
    setDay7EndTime(value)
    /*
    if (!dayEndInitialized) {
      setDayEndInitialized(true)
      if (!day1WalkIn) setDay1EndTime(value)
      if (!day2WalkIn) setDay2EndTime(value)
      if (!day3WalkIn) setDay3EndTime(value)
      if (!day4WalkIn) setDay4EndTime(value)
      if (!day5WalkIn) setDay5EndTime(value)
      if (!day6WalkIn) setDay6EndTime(value)
      if (!day7WalkIn) setDay7EndTime(value)
    }
    */
    if (day7WalkIn && value) setDay7WalkIn(false)
  }

  const next = () => {
    if (phase < lastPhase) {
      setPhase(phase + 1)
    }
  }
  const back = () => {
    if (phase > 1) {
      setPhase(phase - 1)
    }
  }

  const onCreate = () => {
    const startParts = startTime.split('-')
    const endParts = endTime.split('-')

    if (startParts.length !== 3 || endParts.length !== 3) {
      return null
    }

    const newStartTime = DateTime.fromObject({
      year: parseInt(startParts[0], 10),
      month: parseInt(startParts[1], 10),
      day: parseInt(startParts[2], 10),
    }, {
      zone: 'Europe/Helsinki' // TODO: get zone from track
    }).startOf('day').toUTC().toJSDate()

    const newEndTime = DateTime.fromObject({
      year: parseInt(endParts[0], 10),
      month: parseInt(endParts[1], 10),
      day: parseInt(endParts[2], 10),
    }, {
      zone: 'Europe/Helsinki' // TODO: get zone from track
    }).endOf('day').startOf('second').toUTC().toJSDate()

    const newSeason = {
      fieldSeasonId: season,
      startTime: newStartTime,
      endTime: newEndTime,
      slotSeats,
      slotDuration,
      everyNthReservable: isFreeTrack ? everyNthReservable : null,
      day1StartTime: day1StartTime || '',
      day1EndTime: day1EndTime || '',
      day2StartTime: day2StartTime || '',
      day2EndTime: day2EndTime || '',
      day3StartTime: day3StartTime || '',
      day3EndTime: day3EndTime || '',
      day4StartTime: day4StartTime || '',
      day4EndTime: day4EndTime || '',
      day5StartTime: day5StartTime || '',
      day5EndTime: day5EndTime || '',
      day6StartTime: day6StartTime || '',
      day6EndTime: day6EndTime || '',
      day7StartTime: day7StartTime || '',
      day7EndTime: day7EndTime || ''
    }
    props.onCreate(newSeason)
  }

  const getIsCreateDisabled = () => {
    if (phase === 1) {
      return !season || !validateSeasonDates({ startTime, endTime })
    } else if (phase === 2) {
      return !slotDuration
    } else if (phase === 3) {
      return !slotSeats
    } else if (isFreeTrack && phase === 4) {
      return !everyNthReservable && everyNthReservable !== 0
    }
    const isValidNewSeason = validateNewTrackSeason({
      startTime,
      endTime,
      slotSeats,
      slotDuration,
      day1WalkIn,
      day2WalkIn,
      day3WalkIn,
      day4WalkIn,
      day5WalkIn,
      day6WalkIn,
      day7WalkIn,
      day1StartTime,
      day1EndTime,
      day2StartTime,
      day2EndTime,
      day3StartTime,
      day3EndTime,
      day4StartTime,
      day4EndTime,
      day5StartTime,
      day5EndTime,
      day6StartTime,
      day6EndTime,
      day7StartTime,
      day7EndTime
    })
    return !isValidNewSeason
  }

  const getSlotDurationOptions = () => {
    return [10, 15, 20, 30, 60].map((value: number) => {
      return {
        value,
        label: `${value} ${t('num_minutes')}`
      }
    })
  }

  const getSlotSeatsOptions = () => {
    return [1, 2, 3, 4, 5].map((value: number) => {
      return {
        value,
        label: `${value} ${t('participants_per_slot')}`
      }
    })
  }

  const renderDayTime = (dayNum: number, dayWalkIn: boolean, setDayWalkIn: any, dayStartTime: any, setDayStartTime: any, dayEndTime: any, setDayEndTime: any) => {
    return (
      <Box sx={styles.dayRow}>
        <Typography sx={styles.day}>{t(`weekday_${dayNum}`)}</Typography>
        <Box sx={styles.switchContainer}>
          <CustomSwitch
            checked={!dayWalkIn}
            onChange={() => setDayWalkIn(!dayWalkIn)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant='body1' color={Colors.text80} ml='0.5rem'>
            {
              dayWalkIn && isFreeTrack && everyNthReservable === 0 ?
                t('not_in_use') :
                dayWalkIn ?
                t('walk_in') :
                isFreeTrack ?
                t('during_time_period') :
                t('reservation_required')
            }
          </Typography>
        </Box>
        <Fade in={!dayWalkIn}>
          <Box sx={styles.timeRow}>
            <Typography variant='body1' fontWeight='700'>{t('at')}</Typography>
            <TimeInput
              value={dayStartTime}
              onChange={setDayStartTime}
              ml='1rem'
              mr='1rem'
              width='5.625rem'
            />
            <Typography variant='body1' fontWeight='700'>{`- ${t('at')}`}</Typography>
            <TimeInput
              value={dayEndTime}
              onChange={setDayEndTime}
              ml='1rem'
              width='5.625rem'
            />
          </Box>
        </Fade>
      </Box>
    )
  }

  const renderDayTimes = () => {
    return (
      <>
        {renderDayTime(1, day1WalkIn, handleSetDay1WalkIn, day1StartTime, handleSetDay1StartTime, day1EndTime, handleSetDay1EndTime)}
        {renderDayTime(2, day2WalkIn, handleSetDay2WalkIn, day2StartTime, handleSetDay2StartTime, day2EndTime, handleSetDay2EndTime)}
        {renderDayTime(3, day3WalkIn, handleSetDay3WalkIn, day3StartTime, handleSetDay3StartTime, day3EndTime, handleSetDay3EndTime)}
        {renderDayTime(4, day4WalkIn, handleSetDay4WalkIn, day4StartTime, handleSetDay4StartTime, day4EndTime, handleSetDay4EndTime)}
        {renderDayTime(5, day5WalkIn, handleSetDay5WalkIn, day5StartTime, handleSetDay5StartTime, day5EndTime, handleSetDay5EndTime)}
        {renderDayTime(6, day6WalkIn, handleSetDay6WalkIn, day6StartTime, handleSetDay6StartTime, day6EndTime, handleSetDay6EndTime)}
        {renderDayTime(7, day7WalkIn, handleSetDay7WalkIn, day7StartTime, handleSetDay7StartTime, day7EndTime, handleSetDay7EndTime)}
      </>
    )
  }

  const renderEveryNthReservableSummary = () => {
    if (isFreeTrack && (everyNthReservable || everyNthReservable === 0)) {
      return (
        <>
          <Typography variant='h5' mb='0.5rem'>
            {t('free_track_reservable_slots_title')}
          </Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
            {t(`every_${everyNthReservable}_slot`)}
          </Typography>
        </>
      )
    }
    return null
  }

  const renderPhaseContent = () => {
    if (phase === 1) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('calendar_settings_1_info')}
          </Typography>
          <Select
            options={getSeasonOptions()}
            value={season}
            onChange={updateSeason}
            disabled={!!props.season}
            mb='1.5rem'
          />
      </>
      )
    } else if (phase === 2) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('calendar_settings_2_info')}
          </Typography>
          <Select
            options={getSlotDurationOptions()}
            value={slotDuration}
            onChange={setSlotDuration}
            disabled={!!props.season && !!props?.hasStarted}
            maxWidth='14rem'
            mb='1.5rem'
          />
        </>
      )
    } else if (phase === 3) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('calendar_settings_3_info')}
          </Typography>
          <Select
            options={getSlotSeatsOptions()}
            value={slotSeats}
            onChange={setSlotSeats}
            maxWidth='18rem'
            mb='1.5rem'
          />
        </>
      )
    } else if (isFreeTrack && phase === 4) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('free_track_reservable_slots_info')}
          </Typography>
          <Select
            options={getEveryNthSlotReservableOptions()}
            value={everyNthReservable}
            onChange={setEveryNthReservable}
            maxWidth='18rem'
            mb='1.5rem'
          />
        </>
      )
    } else if (phase === dayPhase) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {
              isFreeTrack && everyNthReservable === 0 ?
              t('walk_in_times_info') :
              isFreeTrack ?
              t('free_track_reservable_slots_times_info') :
              t('track_reservations_required_guide')
            }
          </Typography>
          {renderDayTimes()}
        </>
      )
    } else if (phase === lastPhase) {
      const noReservations = everyNthReservable === 0
      return (
        <>
          <Typography variant='h5' mb='0.5rem'>{t('season_length')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
            {DateTime.fromISO(startTime).toFormat('dd.MM.yyyy')} - {DateTime.fromISO(endTime).toFormat('dd.MM.yyyy')}
          </Typography>
          <Typography variant='h5' mb='0.5rem'>{t('duration_of_slot')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
            {slotDuration} {t('num_minutes')}
          </Typography>
          <Typography variant='h5' mb='0.5rem'>{t('single_slot_participant_count')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
            {slotSeats} {t('participants_per_slot')}
          </Typography>
          {renderEveryNthReservableSummary()}
          <Typography variant='h5' mb='1rem'>
            {
              isFreeTrack && noReservations ?
                t('walk_in_times') :
                isFreeTrack ?
                t('during_time_period') :
                t('track_reservations_required')
            }
          </Typography>
            <Grid container columns={12} spacing={4}>
              <Grid xs={6}>
                <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='1.5rem'>{t('on_weekday_1')}</Box>
                  {day1WalkIn && noReservations ? t('not_in_use') : day1WalkIn ? t('walk_in') : `${day1StartTime} - ${day1EndTime}`}<br />
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='4.1rem'>{t('on_weekday_2')}</Box>
                  {day2WalkIn && noReservations ? t('not_in_use') : day2WalkIn ? t('walk_in') : `${day2StartTime} - ${day2EndTime}`}<br />
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='1.2rem'>{t('on_weekday_3')}</Box>
                  {day3WalkIn && noReservations ? t('not_in_use') : day3WalkIn ? t('walk_in') : `${day3StartTime} - ${day3EndTime}`}<br />
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='3.625rem'>{t('on_weekday_4')}</Box>
                  {day4WalkIn && noReservations ? t('not_in_use') : day4WalkIn ? t('walk_in') : `${day4StartTime} - ${day4EndTime}`}<br />
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='2.55rem'>{t('on_weekday_5')}</Box>
                  {day5WalkIn && noReservations ? t('not_in_use') : day5WalkIn ? t('walk_in') : `${day5StartTime} - ${day5EndTime}`}<br />
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant='body1' color={Colors.text60} mb='1.25rem'>
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='2.3rem'>{t('on_weekday_6')}</Box>
                  {day6WalkIn && noReservations ? t('not_in_use') : day6WalkIn ? t('walk_in') : `${day6StartTime} - ${day6EndTime}`}<br />
                  <Box component='span' fontWeight='600' color={Colors.text80} mr='1.5rem'>{t('on_weekday_7')}</Box>
                  {day7WalkIn && noReservations ? t('not_in_use') : day7WalkIn ? t('walk_in') : `${day7StartTime} - ${day7EndTime}`}<br />
                </Typography>
            </Grid>
          </Grid>
        </>
      )
    }
    return null
  }

  return (
    <Dialog
      sx={phase === dayPhase ? styles.dayDialog : styles.dialog}
      onClose={props.onClose}
      open
    >
      <DialogTitle variant='h2' sx={styles.title}>
        {props?.season ? t('reservation_calendar_settings') : t('reservation_calendar_settings')}
      </DialogTitle>
      <Typography variant='body1' color={Colors.text60} textAlign='center' mb='1.5rem'>
        {t('calendar_will_be_created_automatically_info')}
      </Typography>
      <DialogContent sx={styles.content}>
        <Box sx={styles.phaseRow}>
          <Chip
            label={`${phase}/${lastPhase}`}
            variant='outlined'
            size='small'
            sx={styles.chip}
          />
          <Typography variant='h4' ml='1rem'>{getPhaseTitle()}</Typography>
        </Box>
        {renderPhaseContent()}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={phase === 1 ? t('cancel') : t('back')}
          onClick={phase === 1 ? props.onClose : back}
          mr='2rem'
          width='12rem'
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={phase === lastPhase ? props?.season ? t('save') : t('create_calendar') : t('next')}
          onClick={phase === lastPhase ? onCreate : next}
          disabled={getIsCreateDisabled()}
          width='12rem'
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '40rem',
      width: '40rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  dayDialog: {
    '.MuiDialog-paper': {
      minWidth: '47rem',
      width: '47rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  title: {
    color: Colors.heading,
    paddingLeft: '1rem',
    alignSelf: 'center',
    textAlign: 'center',
    mb: '0.25rem'
  },
  phaseRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.25rem'
  },
  chip: {
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.white,
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.625rem',
    border: 0,
    padding: '0rem 0.375rem'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // minHeight: '15.5rem',
    maxHeight: '70vh',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    p: '2rem 2rem 0.5rem 2rem',
    overflowX: 'hidden'
  },
  outlinedButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  dayRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minWidth: '41rem',
    minHeight: '3.125rem',
    mb: '0.75rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mt: '1.5rem',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  day: {
    width: '7rem',
    fontWeight: 700
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '11.5rem'
  },
  timeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1rem',
    fontWeight: 900,
    color: Colors.text60
  }
} as const
