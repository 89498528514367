import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import FeedbackStars from '../../../Components/Common/FeedbackStars'
import SquareButton from '../../../Components/Common/SquareButton'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import MessageIcon from '../../../Assets/Icons/message.svg'
import DeleteIcon from '../../../Assets/Icons/trash.svg'
import { Colors } from '../../../Utils/theme'

const TrackFeedbacks = (props: any) => {
  const { sessionStore, feedbackStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, totalFeedbacks } = feedbackStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)
  const openDeleteFeedbackConfirmation = () => setShowDeleteConfirmation(true)
  const closeDeleteFeedbackConfirmation = () => setShowDeleteConfirmation(false)

  useEffect(() => {
    if (['admin', 'superadmin'].includes(sessionStore.user.role)) {
      feedbackStore.getAllTrackFeedbacks()
    } else {
      const organizationId = sessionStore.user.organizationId
      if (organizationId) {
        feedbackStore.getOrganizationTrackFeedbacks(organizationId)
      }
    }
  }, [])

  const toFeedback = (trackId: number, trackFeedbackId: number) => {
    navigate(`/tracks/${trackId}/track-feedbacks/${trackFeedbackId}`)
  }
  const deleteFeedback = () => {
    closeDeleteFeedbackConfirmation()
    // feedbackStore.deleteTrackFeedback()
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'feedback',
      headerName: t('feedback'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.feedback}
          onClick={() => toFeedback(params.row.trackId, params.row.id)}
          variant='text'
        />
      )
    },
    {
      field: 'rating',
      headerName: t('rating'),
      flex: 1,
      maxWidth: 230,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => <FeedbackStars value={params.row.rating} />
    },
    {
      field: 'track',
      headerName: t('track'),
      flex: 1,
      maxWidth: 340,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row?.track?.nameFi || params.row?.track?.nameEn || params.row?.track?.nameSv}`
    },
    {
      field: 'createdAt',
      headerName: t('sent_at'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          const datetime = DateTime.fromISO(params.value)
          return `${datetime.toFormat('dd.MM.yyyy')} ${t('at')} ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'responses',
      headerName: t('responded'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Chip
            label={params.row?.responses?.length ? t('yes') : t('no')}
            variant='outlined'
            size='small'
            sx={styles.chip}
          />
        )
      }
    },
    /*
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box sx={styles.actions}>
            <SquareButton
              icon={DeleteIcon}
              onClick={openDeleteFeedbackConfirmation}
              grey
            />
          </Box>
        )
      }
    }
    */
  ], [language])

  const rows = useMemo(() => {
    let feedbacks = feedbackStore.trackFeedbacks
    if (searchKey) {
      feedbacks = feedbacks.filter((feedback: any) => feedback?.feedback?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return feedbacks
  }, [feedbackStore.trackFeedbacks, searchKey, filters])

  const renderConfirmationDialog = () => {
    if (showDeleteConfirmation) {
      return (
        <ConfirmationDialog
          title={t('delete_feedback')}
          content={t('delete_feedback_confirmation')}
          onClose={closeDeleteFeedbackConfirmation}
          onAction={deleteFeedback}
          actionText={t('delete')}
          loading={loading}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
      />
      <Table rows={rows} columns={columns} />
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(TrackFeedbacks)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    /*
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    */
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem',
    // fontWeight: 700,
    // color: '#0F0F22',
    // border: '0.125rem solid #0F0F22',
    // backgroundColor: 'transparent',
    '&:hover': {
      // backgroundColor: 'transparent !important'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
