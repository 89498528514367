import { Routes, Route, Navigate } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Dashboard from './Dashboard/Dashboard'
import Users from './Users/Users'
import User from './Users/User'
import InviteUser from './Users/InviteUser'
import Organizations from './Organizations/Organizations'
import CreateOrganization from './Organizations/CreateOrganization'
import Organization from './Organizations/Organization'
import Fields from './Fields/Fields'
import Field from './Fields/Field'
import CreateField from './Fields/CreateField'
import CreateTrack from './Fields/CreateTrack'
import Track from './Fields/Track'
import Profile from './Profile/Profile'
import Calendar from './Calendar/Calendar'
import TrackFeedbacks from './Feedbacks/TrackFeedbacks'
import TrackFeedback from './Feedbacks/TrackFeedback'
import Messages from './Messages/Messages'
import Message from './Messages/Message'
import CreateMessage from './Messages/CreateMessage'
import Notifications from './Notifications/Notifications'
import Notification from './Notifications/Notification'
import CreateNotification from './Notifications/CreateNotification'
import ProShop from './ProShop/ProShop'
import SeasonPasses from './ProShop/SeasonPasses'
import PaymentCodes from './ProShop/PaymentCodes'
import DiscountCodes from './ProShop/DiscountCodes'
// import Settings from './Settings/Settings'
import Reports from './Reports/Reports'

export default function Authenticated() {
  return (
    <AppLayout>
      <Routes>
        {/* <Route path='/dash' element={<Dashboard />} /> */}
        <Route path='/users' element={<Users />} />
        <Route path='/users/invite' element={<InviteUser />} />
        <Route path='/users/admin/:userId' element={<User />} />
        <Route path='/organizations/:organizationId/users/:userId' element={<User />} />
        <Route path='/messages' element={<Messages />} />
        <Route path='/messages/create' element={<CreateMessage />} />
        <Route path='/messages/:messageId' element={<Message />} />
        <Route path='/track-notifications' element={<Notifications />} />
        <Route path='/track-notifications/create' element={<CreateNotification />} />
        <Route path='/organizations' element={<Organizations />} />
        <Route path='/organizations/create' element={<CreateOrganization />} />
        <Route path='organizations/:organizationId' element={<Organization />} />
        <Route path='/fields' element={<Fields />} />
        <Route path='/organizations/:organizationId/fields' element={<Fields />} />
        <Route path='/fields/create' element={<CreateField />} />
        <Route path='/organizations/:organizationId/fields/create' element={<CreateField />} />
        <Route path='/organizations/:organizationId/fields/:fieldId' element={<Field />} />
        <Route path='/organizations/:organizationId/fields/:fieldId/tracks/create' element={<CreateTrack />} />
        <Route path='/organizations/:organizationId/fields/:fieldId/tracks/:trackId' element={<Track />} />
        <Route path='/organizations/:organizationId/fields/:fieldId/tracks/:trackId/calendar' element={<Calendar />} />
        <Route path='/tracks/:trackId/track-feedbacks/:trackFeedbackId' element={<TrackFeedback />} />
        <Route path='/tracks/:trackId/messages/:messageId' element={<Message />} />
        <Route path='/tracks/:trackId/notifications/:notificationId' element={<Notification />} />
        <Route path='/proshop' element={<ProShop />} />
        <Route path='/proshop/season-passes' element={<SeasonPasses />} />
        <Route path='/proshop/payment-codes' element={<PaymentCodes />} />
        <Route path='/proshop/discount-codes' element={<DiscountCodes />} />
        <Route path='/track-feedbacks' element={<TrackFeedbacks />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/profile' element={<Profile />} />
        {/* <Route path='/settings' element={<Settings />} /> */}
        <Route path='*' element={<Navigate to ='/fields' />}/>
        {/* <Route path='*' element={<Navigate to ='/dash' />}/> */}
      </Routes>
    </AppLayout>
  )
}
