import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Lottie from 'lottie-react'
import constructionAnimation from '../../Assets/Animations/construction.json'
import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import ButtonBase from '@mui/material/ButtonBase'
import Sidebar from './Sidebar'
import Avatar from '@mui/material/Avatar'
import Slide from '@mui/material/Slide'
import { Button } from '../../Components'
import Logo from '../../Assets/Icons/logo-full.png'
import logo from '../../Assets/Icons/logo.svg'
import { Menu } from '@emotion-icons/material-rounded'
import { Typography } from '@mui/material'
import { AvailablePrimaryRoutes, AvailableSecondaryRoutes } from '../../Utils/constants'
import styled from '@emotion/styled'
import { Colors } from '../../Utils/theme'

const MenuIcon = styled(Menu)`
  color: ${Colors.heading};
  font-weight: 700;
`

function AppLayout(props: any) {
  const { t } = useTranslation()

  const currentLocation = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const scrollTrigger = useScrollTrigger({ threshold: 25 })

  const { sessionStore }: any = useStore()
  const { user, language } = sessionStore

  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  const toProfile = () => navigate('/profile')
  const goBack = () => navigate(-1)

  const ScreenTitle = useMemo(() => {
    if (sessionStore.user) {
      if (currentLocation?.pathname) {
        const routes = [
          ...AvailablePrimaryRoutes(sessionStore.user?.role),
          ...(AvailablePrimaryRoutes(sessionStore.user?.role).map((item: any) => item?.children ?? []).flat()),
          ...AvailableSecondaryRoutes(sessionStore.user?.role)
        ].flat()
        const title = routes.find((item: any) => item.url === currentLocation.pathname)?.title || ''
        if (title) {
          return t(title)
        }
      }
    }
    if (currentLocation.pathname === '/profile') {
      return t('profile')
    }
    return ''
  }, [currentLocation, user, language])

  const getPrimaryNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      return AvailablePrimaryRoutes(role)
    }
    return []
  }, [sessionStore.user])

  const getSecondaryNavigationItems = useCallback(() => {
    if (sessionStore.user) {
      const role = sessionStore.user?.role ?? null
      return AvailableSecondaryRoutes(role)
    }
    return []
  }, [sessionStore.user])

  const renderScreenTitleOrAction = () => {
    if (!lgUp) {
      return null
      // return <Box component='img' src={logo} alt='logo' sx={styles.logo} />
    }

    if (currentLocation.pathname.endsWith('/calendar')) {
      return <Typography variant='h1'>{t('reservation_calendar')}</Typography>
    }

    if (!ScreenTitle && currentLocation.pathname !== '/profile') {
      return (
        <Button
          text={t('back_to_list')}
          onClick={goBack}
          icon='back'
          iconSx={styles.backButtonIcon}
        />
      )
    }
    return <Typography variant='h1'>{ScreenTitle}</Typography>
  }

  const renderProfile = () => {
    if (lgUp) {
      return (
        <ButtonBase sx={styles.profileButton} onClick={toProfile}>
          <Avatar sx={styles.avatar} src={user?.photo} alt='photo' />
          <Box sx={styles.profileColumn}>
            <Typography sx={styles.name} variant='body1'>{user?.firstName} {user?.lastName}</Typography>
            <Typography sx={styles.role} variant='body2'>{t(user?.role)}</Typography>
          </Box>
        </ButtonBase>
      )
    }

    return null
    /*
    return (
      <ButtonBase sx={styles.button} onClick={toProfile}>
        <Avatar sx={styles.avatar} src={user?.photo} alt='photo' />
      </ButtonBase>
    )
    */
  }

  /*
  const renderMenu = () => {
    if (!lgUp) {
      return (
        <ButtonBase sx={styles.menuButton} onClick={toggleDrawer}>
          <MenuIcon size='2.5rem' />
        </ButtonBase>
      )
    }
    return null
  }
  */

  if (!user) {
    return null
  }

  if (!lgUp) {
    return (
      <Box sx={styles.underConstruction}>
        <Box component='img' src={Logo} sx={styles.underConstructionLogo} alt='logo' />
        <Lottie animationData={constructionAnimation} style={styles.underConstructionAnimation} />
      </Box>
    )
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.sidebarContainer}>
        <Sidebar
          open={open}
          toggleDrawer={toggleDrawer}
          primaryNavigationItems={getPrimaryNavigationItems()}
          secondaryNavigationItems={getSecondaryNavigationItems()}
          user={sessionStore.user}
          permanentDrawer={lgUp}
        />
      </Box>
      <Slide appear={false} direction='down' in={!scrollTrigger}>
        <AppBar sx={styles.appBar} elevation={0}>
          <Toolbar variant='dense' sx={styles.toolbar} disableGutters>
            {renderScreenTitleOrAction()}
            <Box sx={styles.actions}>
              {renderProfile()}
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component='main' sx={styles.content}>
        {props.children}
      </Box>
    </Box>
  )
}

export default observer(AppLayout)

const styles = {
  underConstruction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    backgroundColor: Colors.brandBlue
  },
  underConstructionLogo: {
    width: {
      md: '35vw',
      sm: '50vw',
      xs: '65vw'
    },
    height: 'auto',
    margin: {
      xs: '3rem 0 3rem 0'
    }
  },
  underConstructionAnimation: {
    width: 'auto',
    height: '50vh'
  },
  root: {
    //display: 'flex',
    // flexDirection: 'column',
    height: '100vh',
    backgroundColor: Colors.white
  },
  sidebarContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 999
  },
  appBar: {
    backgroundColor: Colors.white,
    zIndex: 997,
    alignItems: 'flex-end',
    // borderBottom: `1px solid ${Colors.border}`,
    height: {
      lg: '6rem',
      xs: '4.5rem'
    },
    width: {
      xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lg: 'calc(100% - 18rem)',
      xs: '100%'
    },
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lg: '18rem',
      xs: 0
    },
    pl: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem'
    },
    pr: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem'
    }
  },
  contentContainer: {
    flexDirection: 'column'
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    width: '5rem',
    height: 'auto'
  },
  actions: {
  },
  profileButton: {
    borderRadius: '0.625rem',
    padding: '0.5rem',
    ':hover': {
      backgroundColor: Colors.black10
    }
  },
  button: {
    borderRadius: '50%',
    mr: {
      sm: '2rem',
      xs: '1.5rem'
    }
  },
  avatar: {
    width: '3rem',
    height: '3rem'
  },
  profileColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ml: '1rem'
  },
  name: {
    color: Colors.navy,
    fontWeight: 700
  },
  role: {
    color: Colors.text80,
    fontWeight: 400
  },
  menuButton: {
    height: '3rem',
    width: '3rem',
    backgroundColor: Colors.green,
    borderRadius: '0.625rem'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: {
      xxxl: 'calc(100% - 20rem)',
      // lg: 'calc(100% - 20rem)',
      lg: 'calc(100% - 18rem)',
      xs: '100%'
    },
    ml: {
      xxxl: '20rem',
      // lg: 'calc(100% - 20rem)',
      lg: '18rem',
      xs: 0
    },
    pt: {
      xs: '6.5rem'
    },
    pl: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem'
    },
    pr: {
      xxxl: '4rem',
      lg: '3rem',
      xs: '1rem'
    }
  },
  backButtonIcon: {
    width: '1rem',
    height: '1rem'
  }
} as const
