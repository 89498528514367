import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Collapse from '@mui/material/Collapse'
import Select from './Select'
import Button from './Button'
import Input from './Input'
import DiscountCodeIcon from '../../Assets/Icons/coupon.svg'
import { Colors } from '../../Utils/theme'

export default function CreateDiscountCodeModal(props: any) {
  const { onClose, loading, fields } = props
  const { t } = useTranslation()

  const [code, setCode] = useState('')
  const [field, setField] = useState('')
  const [hasExpirationDate, setHasExpirationDate] = useState(false)
  const [validUntil, setValidUntil] = useState('')
  const [discountPercent, setDiscountPercent] = useState('')

  const clearCode = () => setCode('')

  const getIsDisabled = () => {
    if (!field) {
      return true
    }
    const targetField = fields.find((f: any) => f.id === field)
    return loading ||
      !targetField?.organizationId ||
      !targetField?.id ||
      !code ||
      (hasExpirationDate && !validUntil) ||
      !discountPercent
  }

  const getFieldOptions = () => {
    return fields.map((field: any) => ({ label: field.nameFi, value: field.id }))
  }

  const getDiscountPercentageOptions = () => {
    // Options from 1% to 70%
    const options = []
    for (let i = 1; i <= 70; i++) {
      options.push({ label: `${i}%`, value: i })
    }
    return options
  }

  const getHasExpirationDateOptions = () => {
    return [
      { label: t('yes'), value: true },
      { label: t('no'), value: false }
    ]
  }

  const createDiscountCode = () => {
    const targetField = fields.find((f: any) => f.id === field)
    if (targetField) {
      props.onCreate(
        targetField.organizationId,
        targetField.id,
        {
          code,
          discountPercent,
          validUntil: hasExpirationDate ? validUntil : null
        }
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Box sx={styles.titleRow}>
        <Box component='img' sx={styles.icon} src={DiscountCodeIcon} alt='icon' />
        <DialogTitle variant='h2' sx={styles.title}>{t('create_discount_code')}</DialogTitle>
      </Box>
      <DialogContent sx={styles.content}>
        <Box sx={styles.row}>
          <Input
            label={t('code')}
            value={code}
            onChange={setCode}
            onAction={clearCode}
            iconSx={styles.clearIcon}
            mr='2rem'
          />
          <Select
            label={t('discount_percentage')}
            options={getDiscountPercentageOptions()}
            value={discountPercent}
            onChange={setDiscountPercent}
            maxWidth='11rem'
          />
        </Box>
        <Select
          label={t('field')}
          options={getFieldOptions()}
          value={field}
          onChange={setField}
          mb='1.5rem'
        />
        <Box sx={styles.row}>
          <Select
            label={t('has_expiration_date')}
            options={getHasExpirationDateOptions()}
            value={hasExpirationDate}
            onChange={setHasExpirationDate}
            maxWidth='12rem'
            mr='2rem'
          />
          <Collapse in={hasExpirationDate}>
            <Input
              label={t('valid_until')}
              value={validUntil}
              onChange={setValidUntil}
              disabled={!hasExpirationDate}
              type='date'
            />
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('cancel')}
          onClick={onClose}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={createDiscountCode}
          loading={loading}
          disabled={getIsDisabled()}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '3.25rem'
  },
  title: {
    color: Colors.heading
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  clearIcon: {
    position: 'absolute',
    top: '2.325rem',
    right: '1rem',
    cursor: 'pointer',
    width: '1.75rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  }
} as const
