import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import SquareButton from '../../../Components/Common/SquareButton'
import { Button } from '../../../Components'
import CreateDiscountCodeModal from '../../../Components/Common/CreateDiscountCodeModal'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import CopyIcon from '../../../Assets/Icons/copy.svg'
import DeleteIcon from '../../../Assets/Icons/trash.svg'
import { Colors } from '../../../Utils/theme'
import { showMessage } from '../../../Utils/message'

const DiscountCodes = (props: any) => {
  const { sessionStore, fieldStore, proShopStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, totalDiscountCodes } = proShopStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [showCreateDiscountCodeModal, setShowCreateDiscountCodeModal] = useState(false)
  const [discountCode, setDiscountCode] = useState<any>(null)
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)
  const openCreateDiscountCodeModal = () => setShowCreateDiscountCodeModal(true)
  const closeCreateDiscountCodeModal = () => setShowCreateDiscountCodeModal(false)
  const openDiscountCode = (code: any) => setDiscountCode(code)
  const closeDiscountCode = () => setDiscountCode(null)

  const openArchiveConfirmation = (discountCode: any) => {
    setShowArchiveConfirmation(true)
    setDiscountCode(discountCode)
  }
  const closeArchiveConfirmation = () => {
    setDiscountCode(null)
    setShowArchiveConfirmation(false)
  }

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
      proShopStore.getDiscountCodes()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
      proShopStore.getOrganizationDiscountCodes(sessionStore.user?.organizationId)
    }
    proShopStore.getDiscountCodes()
  }, [])

  const copyToClipboard = (code: string) => () => {
    navigator.clipboard.writeText(code)
    showMessage(t('copied_to_clipboard'))
  }

  const toCreator = (user: any) => {
    if (sessionStore.user?.role === 'admin') {
      navigate(`/users/admin/${user.id}`)
    } else {
      navigate(`/organization/${user.organizationId}/users/${user.id}`)
    }
  }

  const toField = (discountCode: any) => {
    navigate(`/organizations/${discountCode.organizationId}/fields/${discountCode.fieldId}`)
  }

  const createDiscountCode = (organizationId: number, fieldId: number, data: any) => {
    proShopStore.createDiscountCode(
      organizationId,
      fieldId,
      data,
      closeCreateDiscountCodeModal
    )
  }

  const archiveDiscountCode = () => {
    if (discountCode) {
      const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
      if (isAdmin) {
        proShopStore.archiveDiscountCode(
          discountCode.field.organizationId,
          discountCode.fieldId,
          discountCode.id,
          closeArchiveConfirmation
        )
      } else {
        proShopStore.archiveDiscountCode(
          discountCode.field.organizationId,
          discountCode.fieldId,
          discountCode.id,
          closeArchiveConfirmation
        )
      }
    }
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'code',
      headerName: t('code'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.code}
            onClick={copyToClipboard(params.row?.code)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'status',
      headerName: t('state'),
      flex: 1,
      minWidth: 150,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        let text = params?.row?.archivedAt ?
          t('archived') :
          params.row?.validUntil && DateTime.fromISO(params.row.validUntil) < DateTime.now() ?
          t('expired') :
          t('active')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'discountPercent',
      headerName: t('discount'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params?.value ? `${params.value}%` : '-'
      }
    },
    {
      field: 'validUntil',
      headerName: t('valid_until'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        if (params.row?.validUntil) {
          const date = DateTime.fromISO(params.row.validUntil)
          return `${date.toFormat('dd.MM.yyyy')} ${t('at')} ${date.toFormat('HH.mm')}`
        }
        return t('for_now')
      }
    },
    {
      field: 'fieldId',
      headerName: t('field'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.field?.nameFi || ''}
            onClick={() => toField(params.row)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 250,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box sx={styles.actions}>
            <SquareButton
              icon={CopyIcon}
              onClick={copyToClipboard(params?.row?.code)}
              mr='1rem'
              blue
            />
            <SquareButton
              icon={DeleteIcon}
              onClick={() => openArchiveConfirmation(params.row)}
              grey
            />
          </Box>
        )
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let discountCodes = proShopStore.discountCodes
    if (searchKey) {
      const search = searchKey.toLowerCase()
      discountCodes = discountCodes.filter((discountCode: any) => discountCode?.code?.toLowerCase().includes(search))
    }
    return discountCodes
  }, [proShopStore.discountCodes, searchKey, filters])

  const renderCreateDiscountCodeModal = () => {
    if (showCreateDiscountCodeModal) {
      return (
        <CreateDiscountCodeModal
          onClose={closeCreateDiscountCodeModal}
          onCreate={createDiscountCode}
          fields={fieldStore.fields}
        />
      )
    }
    return null
  }

  const renderArchiveConfirmation = () => {
    if (showArchiveConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_discount_code')}
          content={t('archive_discount_code_confirmation')}
          onClose={closeArchiveConfirmation}
          onAction={archiveDiscountCode}
          actionText={t('archive')}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={openCreateDiscountCodeModal}
        addText={t('create_new')}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderCreateDiscountCodeModal()}
      {renderArchiveConfirmation()}
    </Box>
  )
}

export default observer(DiscountCodes)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
