import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'

const InviteUser = (props: any) => {
  const { sessionStore, organizationStore }: any = useStore()
  const { user } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [organization, setOrganization] = useState('')
  const [role, setRole] = useState('')

  useEffect(() => {
    if (['superadmin', 'admin'].includes(user?.role)) {
      organizationStore.getOrganizations()
    } else {
      setRole('field_admin')
      setOrganization(user.organizationId)
    }
  }, [])

  const getOrganizationOptions = () => {
    return organizationStore.organizations.map((item: any) => {
      return {
        label: item.name,
        value: item.id
      }
    })
  }

  const getRoleOptions = () => {
    if (['superadmin', 'admin'].includes(user?.role)) {
      return [
        { value: 'admin', label: t('admin') },
        { value: 'field_admin', label: t('field_admin') }
      ]
    }
    return [
      { value: 'field_admin', label: t('field_admin') }
    ]
  }

  const create = () => {
    const callback = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setOrganization('')
      if (['superadmin', 'admin'].includes(user?.role)) {
        setRole('')
      } else {
        setRole('field_admin')
      }
    }
    const newInvite = {
      firstName,
      lastName,
      email,
      phone,
      role
    }
    if (role === 'field_admin') {
      organizationStore.createOrganizationUserInvite(organization, newInvite, callback)
    }
    if (role === 'admin') {
      organizationStore.createAdminUserInvite(newInvite, callback)
    }
  }
  const cancel = () => navigate(-1)

  const isSendDisabled = () => {
    if (!email || !role) return true
    if (!email.includes('@')) return true
    if (role === 'field_admin' && !organization) return true

    return false
  }

  const renderOrganizationSelect = () => {
    if (['superadmin', 'admin'].includes(user?.role) && role === 'field_admin') {
      return (
        <Select
          label={t('organization')}
          options={getOrganizationOptions()}
          value={organization}
          onChange={setOrganization}
          maxWidth='20rem'
          mb='1.5rem'
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('user_information')}</Typography>
         <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Input label={t('email')} value={email} onChange={setEmail} mb='1.5rem' />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            maxWidth='20rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Select
            label={t('user_role')}
            options={getRoleOptions()}
            value={role}
            onChange={setRole}
            disabled={user?.role === 'field_admin'}
            maxWidth='20rem'
            mb='1.5rem'
          />
          {renderOrganizationSelect()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('send_invite')} onClick={create} width='100%' mb='1rem' disabled={isSendDisabled()} />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(InviteUser)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    // height: '100vh',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
