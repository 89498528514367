import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SlotStore {
  rootStore

  trackSeasons: any = []
  trackSeason: any = null
  calendar: any = null
  trackExceptionSlot: any = null
  trackSystemReservationSlot: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTrackSeasons = (trackSeasons: any) => { this.trackSeasons = trackSeasons }
  setTrackSeason = (trackSeason: any) => { this.trackSeason = trackSeason }
  setCalendar = (calendar: any) => { this.calendar = calendar }
  setTrackExceptionSlot = (trackExceptionSlot: any) => { this.trackExceptionSlot = trackExceptionSlot }
  setTrackSystemReservationSlot = (trackSystemReservationSlot: any) => { this.trackSystemReservationSlot = trackSystemReservationSlot }
  setLoading = (loading: boolean) => { this.loading = loading }

  async createOrganizationTrackSeason(organizationId: number, trackId: number, season: any, callback: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createOrganizationTrackSeason(organizationId, trackId, season)
      if (response.ok) {
        this.getOrganizationTrackSeasons(organizationId, trackId)
        showMessage(i18n.t('season_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateOrganizationTrackSeason(organizationId: number, trackId: number, seasonId: number, season: any, callback: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateOrganizationTrackSeason(organizationId, trackId, seasonId, season)
      if (response.ok) {
        this.getOrganizationTrackSeasons(organizationId, trackId)
        showMessage(i18n.t('info_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('error'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getOrganizationTrackSeasons(organizationId: number, trackId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getOrganizationTrackSeasons(organizationId, trackId)
      if (response.ok) {
        this.setTrackSeasons(response.data?.items || [])
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getOrganizationTrackSeason(organizationId: number, trackId: number, seasonId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getOrganizationTrackSeason(organizationId, trackId, seasonId)
      if (response.ok) {
        this.setTrackSeason(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async deleteOrganizationTrackSeason(organizationId: number, trackId: number, seasonId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.deleteOrganizationTrackSeason(organizationId, trackId, seasonId)
      if (response.ok) {
        showMessage(i18n.t('season_deleted'))
        this.getOrganizationTrackSeasons(organizationId, trackId)
      } else {
        showMessage(i18n.t('error'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getOrganizationTrackCalendar(organizationId: number, trackId: number, startTime: string, endTime: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getOrganizationTrackCalendar(organizationId, trackId, startTime, endTime)
      if (response.ok) {
        this.setCalendar(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createOrganizationTrackExceptionSlot(organizationId: number, trackId: number, exceptionSlot: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createOrganizationTrackExceptionSlot(organizationId, trackId, exceptionSlot)
      if (response.ok) {
        const slot = response.data || null
        this.setTrackExceptionSlot(response.data)
        if (slot?.type) {
          if (slot.type === 'system_reservation') {
            showMessage(i18n.t('system_reservation_created'))
          } else {
            showMessage(i18n.t('exception_slot_created'))
          }
        }
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateOrganizationTrackExceptionSlot(organizationId: number, trackId: number, exceptionSlotId: number, exceptionSlot: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateOrganizationTrackExceptionSlot(organizationId, trackId, exceptionSlotId, exceptionSlot)
      if (response.ok) {
        this.setTrackExceptionSlot(response.data)
        showMessage(i18n.t('info_updated'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async deleteOrganizationTrackExceptionSlot(organizationId: number, trackId: number, exceptionSlotId: number, callback?: any) {
    this.setLoading(true)
    try {
      const response = await Api.deleteOrganizationTrackExceptionSlot(organizationId, trackId, exceptionSlotId)
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
