import { DateTime } from "luxon"

const isValidTime = (time: any) => {
  if (!time) return false
  if (time.length !== 5) return false
  const parts = time.split(':')
  if (parts.length !== 2 || parts[0].length !== 2) return false
  const hh = parseInt(parts[0], 10)
  const mm = parseInt(parts[1], 10)
  if (hh < 0 || hh > 23) return false
  if (mm < 0 || mm > 59) return false
  return true
}

const isValidDate = (date: any) => {
  if (!date) return false
  if (date.length !== 10) return false
  const parts = date.split('-')
  if (parts.length !== 3) return false

  const year = parts[0]
  const month = parts[1]
  const day = parts[2]

  if (year.length !== 4) return false
  if (month.length !== 2) return false
  if (day.length !== 2) return false

  return true
}

export const validateSeasonDates = (trackSeason: any) => {
  const { startTime, endTime } = trackSeason
  if (!startTime || !endTime) {
    return false
  }

  // Validate start time and end time
  const startTimeParts = startTime.split('-')
  const endTimeParts = endTime.split('-')
  const startMoment = DateTime.fromObject({
    year: parseInt(startTimeParts[0], 10),
    month: parseInt(startTimeParts[1], 10),
    day: parseInt(startTimeParts[2], 10)
  }).valueOf()
  const endMoment = DateTime.fromObject({
    year: parseInt(endTimeParts[0], 10),
    month: parseInt(endTimeParts[1], 10),
    day: parseInt(endTimeParts[2], 10)
  }).valueOf()
  // Start cannot be after end
  if (startMoment.valueOf() > endMoment.valueOf()) {
    return false
  }
  
  // All seems to be ok
  return true
}

export const validateNewTrackSeason = (trackSeason: any) => {
  const {
    startTime,
    endTime,
    slotSeats,
    slotDuration,
    day1WalkIn,
    day2WalkIn,
    day3WalkIn,
    day4WalkIn,
    day5WalkIn,
    day6WalkIn,
    day7WalkIn,
    day1StartTime,
    day1EndTime,
    day2StartTime,
    day2EndTime,
    day3StartTime,
    day3EndTime,
    day4StartTime,
    day4EndTime,
    day5StartTime,
    day5EndTime,
    day6StartTime,
    day6EndTime,
    day7StartTime,
    day7EndTime
  } = trackSeason

  // Check for empty values
  if (!startTime || !endTime || !slotSeats || !slotDuration) return false

  // Check for illegal times
  if (!day1WalkIn && (!isValidTime(day1StartTime) || !isValidTime(day1EndTime))) return false
  if (!day2WalkIn && (!isValidTime(day2StartTime) || !isValidTime(day2EndTime))) return false
  if (!day3WalkIn && (!isValidTime(day3StartTime) || !isValidTime(day3EndTime))) return false
  if (!day4WalkIn && (!isValidTime(day4StartTime) || !isValidTime(day4EndTime))) return false
  if (!day5WalkIn && (!isValidTime(day5StartTime) || !isValidTime(day5EndTime))) return false
  if (!day6WalkIn && (!isValidTime(day6StartTime) || !isValidTime(day6EndTime))) return false
  if (!day7WalkIn && (!isValidTime(day7StartTime) || !isValidTime(day7EndTime))) return false

  if (day1WalkIn && (day1StartTime || day1EndTime)) return false
  if (day2WalkIn && (day2StartTime || day2EndTime)) return false
  if (day3WalkIn && (day3StartTime || day3EndTime)) return false
  if (day4WalkIn && (day4StartTime || day4EndTime)) return false
  if (day5WalkIn && (day5StartTime || day5EndTime)) return false
  if (day6WalkIn && (day6StartTime || day6EndTime)) return false
  if (day7WalkIn && (day7StartTime || day7EndTime)) return false

  if (!isValidDate(startTime)) return false
  if (!isValidDate(endTime)) return false

  // Validate start time and end time
  const startTimeParts = startTime.split('-')
  const endTimeParts = endTime.split('-')
  const startMoment = DateTime.fromObject({
    year: parseInt(startTimeParts[0], 10),
    month: parseInt(startTimeParts[1], 10),
    day: parseInt(startTimeParts[2], 10)
  }).valueOf()
  const endMoment = DateTime.fromObject({
    year: parseInt(endTimeParts[0], 10),
    month: parseInt(endTimeParts[1], 10),
    day: parseInt(endTimeParts[2], 10)
  }).valueOf()
  // Start cannot be after end
  if (startMoment.valueOf() > endMoment.valueOf()) return false

  // Not every day can be walk-in
  if (day1WalkIn && day2WalkIn && day3WalkIn && day4WalkIn && day5WalkIn && day6WalkIn && day7WalkIn) {
    return false
  }

  // All seems to be ok
  return true
}


export const validateExceptionSlotInput = (exceptionSlot: any) => {
  const {
    startTime,
    endTime,
    startDate,
    endDate,
    descriptionFi,
    descriptionEn,
    descriptionSv
  } = exceptionSlot

  if (!startTime || !endTime || !startDate || !endDate) return false
  if (!descriptionFi && !descriptionEn && !descriptionSv) return false

  try {
    const startMoment = DateTime.fromFormat(`${startDate} ${startTime}`, `yyyy-MM-dd HH:mm`).valueOf()
    const endMoment = DateTime.fromFormat(`${endDate} ${endTime}`, `yyyy-MM-dd HH:mm`).valueOf()

    if (!startMoment || !endMoment) return false

    if (startMoment === endMoment) return false
    if (startMoment > endMoment) return false

    // All seems to be ok
    return true
  } catch (e) {
    console.log(e)
  }
  // Error thrown
  return false
}

export const validateMessageInput = (message: any) => {
  const {
    type,
    titleFi,
    titleEn,
    titleSv,
    contentFi,
    contentEn,
    contentSv,
    htmlFi,
    htmlEn,
    htmlSv,
    photo,
    video,
    publishMode,
    publishedAtTime,
    publishedAtDate
  } = message

  if (!type) {
    return false
  }

  const fiOk = titleFi && contentFi
  const enOk = titleEn && contentEn
  const svOk = titleSv && contentSv

  if (!fiOk && !enOk && !svOk) {
    return false
  }
  if (!publishMode) {
    return false
  }

  if (publishMode === 'timed') {
    // Validate date and time
    if (!publishedAtTime || !publishedAtDate) return false
    if (publishedAtDate.length !== 10) return false
    if (publishedAtTime.length !== 5) return false
    if (publishedAtDate.split('-').length !== 3) return false
    if (publishedAtTime.split(':').length !== 2) return false
  }

  // All seems to be ok
  return true
}

export const validateNotificationInput = (notification: any) => {
  const {
    titleFi,
    titleEn,
    titleSv,
    contentFi,
    contentEn,
    contentSv,
    htmlFi,
    htmlEn,
    htmlSv,
    startDate,
    startTime,
    endDate,
    endTime,
  } = notification

  const fiOk = titleFi && contentFi
  const enOk = titleEn && contentEn
  const svOk = titleSv && contentSv
  if (!fiOk && !enOk && !svOk) {
    return false
  }

  // Validate date and time
  if (!startTime || !startDate) return false
  if (startDate.length !== 10) return false
  if (startTime.length !== 5) return false
  if (startDate.split('-').length !== 3) return false
  if (startTime.split(':').length !== 2) return false
  if (!endTime || !endDate) return false
  if (endDate.length !== 10) return false
  if (endTime.length !== 5) return false
  if (endDate.split('-').length !== 3) return false
  if (endTime.split(':').length !== 2) return false

  // All seems to be ok
  return true
}
