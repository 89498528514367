import { useState, useMemo, useCallback } from 'react'
import { t } from 'i18next'
import { Colors } from '../../Utils/theme'
import { DateTime } from 'luxon'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import CalendarPlusButton from './CalendarPlusButton'
import Button from '../Common/Button'
import SlotUserIcon from '../../Assets/Icons/slot-user.svg'

export default function ReservationCalendar(props: any) {
  const { slots, timezone } = props

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const hidePopup = () => setAnchor(null)

  const uniqueStartTimes = useMemo(() => {
    if (slots?.length) {
      const startTimes: string[] = []
      for (const slot of slots) {
        const startTimeFormatted = DateTime
          .fromISO(slot.startTime)
          .setZone(timezone)
          .toFormat('HH.mm')
        const exists = startTimes.find(val => val === startTimeFormatted)
        if (!exists) {
          startTimes.push(startTimeFormatted)
        }
      }
      return startTimes.sort()
    }
    return []
  }, [slots])

  const slotsByDay = useMemo(() => {
    if (slots?.length) {
      // Compose unique days
      let cursor: any = DateTime.fromJSDate(props.startTime).valueOf()
      const endValue = DateTime.fromJSDate(props.endTime).valueOf()

      let index = 0
      const dailySlots: any = []

      while (cursor < endValue) {
        dailySlots.push({
          weekday: DateTime.fromMillis(cursor).setZone(timezone).weekday,
          startMoment: DateTime.fromMillis(cursor).setZone(timezone).startOf('day'),
          endMoment: DateTime.fromMillis(cursor).setZone(timezone).endOf('day'),
          items: []
        })
        index++
        cursor = DateTime.fromJSDate(props.startTime).plus({ days: index }).valueOf()
      }

      for (const slot of slots) {
        const weekday = DateTime.fromISO(slot.startTime).setZone(timezone).weekday
        // Add slot
        dailySlots[weekday - 1].items.push(slot)
      }

      return dailySlots
    }
    return []
  }, [slots])

  const weeklySlots = useMemo(() => {
    if (slots?.length) {
      const reservedSlots = slots?.filter((slot: any) => slot?.trackSlot || slot?.exception?.type === 'system_reservation')
      if (reservedSlots?.length) {
        return reservedSlots.reduce((acc: number, slot: any) => {
          return acc + 1
        }, 0)
      }
    }
    return 0
  }, [slots])

  const weeklySeats = useMemo(() => {
    if (slots?.length) {
      const reservedSlots = slots?.filter((slot: any) => slot?.trackSlot || slot?.exception?.type === 'system_reservation')
      if (reservedSlots?.length) {
        return reservedSlots.reduce((acc: number, slot: any) => {
          return acc + (slot?.trackSlot?.playerCount || slot?.exception?.playerCount)
        }, 0)
      }
    }
    return 0
  }, [slots])

  const DailyCount = useCallback((dayIndex: number) => {
    if (slotsByDay?.length) {
      const slots = slotsByDay[dayIndex]?.items
      const reservedSlots = slots?.filter((slot: any) => slot?.trackSlot || slot?.exception?.type === 'system_reservation')
      if (reservedSlots?.length) {
        return reservedSlots.reduce((acc: number, slot: any) => {
          return acc + (slot?.trackSlot?.playerCount || slot?.exception?.playerCount)
        }, 0)
      }
    }
    return 0
  }, [slotsByDay])

  const openSummary = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget)
  }

  const renderSummary = () => {
    return (
      <BasePopup
        id={!!anchor ? 'summary-popup' : undefined}
        open={!!anchor}
        anchor={anchor}
      >
        <Box sx={styles.popup}>
          <Box sx={styles.infoRow}>
            <Typography sx={styles.label}>{t('total_slots')}</Typography>
            <Typography sx={styles.value}>{weeklySlots}</Typography>
          </Box>
          <Box sx={styles.infoRow}>
            <Typography sx={styles.label}>{t('total_players')}</Typography>
            <Typography sx={styles.value}>{weeklySeats}</Typography>
          </Box>
          <Box sx={styles.hr} />
          <Button
            sx={styles.button}
            text={t('close')}
            onClick={hidePopup}
          />
        </Box>
      </BasePopup>
    )
  }

  const renderClickAwayListener = () => {
    if (anchor) {
      return <ClickAwayListener onClickAway={hidePopup}><Box /></ClickAwayListener>
    }
    return null
  }

  const renderExceptionSlot = (slot: any, rowSpan: any = 1) => {
    return (
      <td
        style={slot?.exception?.type === 'system_reservation' ? styles.reservationSlot : styles.exceptionSlot}
        rowSpan={(rowSpan > 1) ? rowSpan : undefined}
        onClick={() => props.openException(slot)}
      >
        {slot?.exception?.descriptionFi}
      </td>
    )
  }

  const renderEmptySlot = (slot: any) => {
    return (
      <td style={styles.emptySlot}>
        <Box sx={styles.emptyContainer}>
          <CalendarPlusButton onClick={() => props.openAction(slot)} dark />
        </Box>
      </td>
    )
  }

  const renderReservedSlot = (slot: any) => {
    const playerCount = slot.trackSlot.playerCount

    return (
      <td style={styles.reservedSlot} onClick={() => props.openSlot(slot)}>
        <Box sx={styles.reservedContainer}>
          <Box component='img' src={SlotUserIcon} />
          {playerCount}/{slot.slotSeats}
        </Box>
      </td>
    )
  }

  const renderWalkInSlot = (slot: any) => {
    return (
      <td style={styles.walkInSlot} onClick={() => props.openAction(slot)}>
        <Box sx={styles.walkInContainer}>
          {t('walk_in')}
        </Box>
      </td>
    )
  }

  const renderSlot = (slot: any) => {
    if (slot.trackSlot) {
      return renderReservedSlot(slot)
    }
    if (slot?.isWalkIn) {
      return renderWalkInSlot(slot)
    }
    return renderEmptySlot(slot)
  }

  const renderFreeSlot = () => {
    return <td style={styles.freeSlot} />
  }

  const renderSummaryHeader = () => {
    return (
      <td style={styles.summaryHeaderCol}>
        <ButtonBase
          sx={styles.summaryButton}
          onClick={openSummary}
          aria-describedby={!!anchor ? 'summary-popup' : undefined}
        >
          <Box sx={styles.summaryTitle}>{t('weekly_visitors')}</Box>
          <Box sx={styles.summaryText}>{weeklySeats || 0}</Box>
        </ButtonBase>
      </td>
    )
  }

  const renderDaysHeader = (slotsByDay: any) => {
    const days = slotsByDay.map((item: any) => {
      let hasSlotForDay = false
      if (!hasSlotForDay && item?.items?.some((slot: any) => slot?.trackSlot || slot?.isWalkIn || slot?.exception)) {
        hasSlotForDay = true
      }
      let exceptionPreset = null
      if (!hasSlotForDay) {
        if (item.items?.length) {
          exceptionPreset = {
            startTime: item.items[0].startTime,
            endTime: item.items[item.items.length - 1].endTime
          }
        }
      }

      return {
        weekday: item.startMoment.weekday,
        day: item.startMoment.day,
        exceptionPreset,
        hasSlotForDay
      }
    })

    return days.map((item: any) => {
      return (
        <td style={styles.dayHeaderCol}>
          <Box sx={styles.weekday}>
            {t(`weekday_short_${item.weekday}`).toUpperCase()}
            <Box component='span' sx={styles.count}> ({DailyCount(item?.weekday - 1)})</Box>
          </Box>
          <Box sx={styles.dayNum}>{item.day}</Box>
          <Box sx={styles.dayAddButtonContainer}>
            {
              !item?.hasSlotForDay && item?.exceptionPreset ? (
                <CalendarPlusButton onClick={() => props.openAction(item.exceptionPreset)} />
              ) : null
            }
          </Box>
        </td>
      )
    })
  }

  const renderSlotDays = (uniqueStartTimes: string[], slotsByDay: any) => {
    const handledExceptions: any = {}
    return (
      <table style={styles.table}>
        <tr>
          {renderSummaryHeader()}
          {renderDaysHeader(slotsByDay)}
        </tr>
        {uniqueStartTimes.map(startTime => {
          return (
            <tr>
              <td style={styles.timeCol}>{startTime}</td>
              {slotsByDay.map((daySlots: any, dayIndex: number) => {
                const match = daySlots.items.find((slot: any) => {
                  if (DateTime.fromISO(slot.startTime).setZone(timezone).toFormat('HH.mm') === startTime) {
                    return true
                  }
                  return false
                })

                if (match) {
                  let rowSpan = 1
                  if (match.exception) {
                    if (!handledExceptions[`${dayIndex}_${match.exception.id}`]) {
                      handledExceptions[`${dayIndex}_${match.exception.id}`] = true
                      const rowSpan = daySlots.items.filter(((item: any) => item?.exception?.id === match.exception.id)).length
                      return renderExceptionSlot(match, rowSpan)
                    } else {
                      return null
                    }
                  }

                  return renderSlot(match)
                }
                return renderFreeSlot()
              })}
            </tr>
          )
        })}
        {renderSummary()}
        {renderClickAwayListener()}
      </table>
    )
  }

  const renderCalendar = () => {
    if (uniqueStartTimes && slotsByDay) {
      return renderSlotDays(uniqueStartTimes, slotsByDay)
    }

    return null
  }

  if (!props?.startTime || !props?.endTime || !slots?.length) {
    return null
  }

  return renderCalendar()
}

const styles = {
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    width: '100%'
  },
  summaryHeaderCol: {
    color: Colors.heading,
    position: 'relative',
    border: '1px solid #ccc'
  },
  summaryButton: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.65rem 2rem',
    ':hover': {
      background: Colors.black05
    }
  },
  summaryTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.heading,
    textAlign: 'center',
    mb: '0.375rem'
  },
  summaryText: {
    fontSize: '1.5rem',
    fontWeight: 400,
    textAlign: 'center'
  },
  dayHeaderCol: {
    backgroundColor: Colors.brandBlue,
    color: Colors.white,
    padding: '.5rem 2rem',
    position: 'relative',
    border: '1px solid #ccc'
  },
  dayAddButtonContainer: {
    position: 'absolute',
    top: 0,
    right: '1rem',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  weekday: {
    textAlign: 'center',
  },
  count: {
    fontSize: '0.875rem',
  },
  dayNum: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '1.5rem'
  },
  timeCol: {
    border: '1px solid #ccc',
    padding: '.5rem 1rem',
    textAlign: 'center'
  },
  freeSlot: {
    border: '1px solid #ccc',
    backgroundColor: Colors.light,
    padding: '.5rem 1rem',
    textAlign: 'center'
  },
  walkInSlot: {
    border: '1px solid #ccc',
    backgroundColor: Colors.light,
    textAlign: 'center',
    cursor: 'pointer'
  },
  reservedSlot: {
    border: '1px solid #ccc',
    backgroundColor: Colors.brandPrimary,
    color: Colors.white,
    padding: '.5rem 1rem',
    cursor: 'pointer'
  },
  walkInContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem 1rem',
    ':hover': {
      background: Colors.lightDarker
    }
  },
  reservedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.5rem'
  },
  emptySlot: {
    border: '1px solid #ccc'
  },
  exceptionSlot: {
    border: '1px solid #ccc',
    cursor: 'pointer',
    background: `repeating-linear-gradient(
      45deg,
      #48E7E8,
      #48E7E8 10px,
      #4AEFEF 10px,
      #4AEFEF 20px
    )`,
    textAlign: 'center',
    wordBreak: 'break-word'
  },
  reservationSlot: {
    border: '1px solid #ccc',
    cursor: 'pointer',
    background: `repeating-linear-gradient(
      45deg,
      #F5F6F7,
      #F5F6F7 10px,
      #FFFFFF 10px,
      #FFFFFF 20px
    )`,
    textAlign: 'center',
    wordBreak: 'break-word'
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0'
  },
  popup: {
    width: 'max-content',
    padding: '0.75rem 1rem',
    margin: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)'
  },
  button: {
    width: '11rem',
    height: '2.25rem',
    fontSize: '1rem',
    mt: '1rem',
    borderRadius: '0.5rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.125rem'
  },
  label: {
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '1rem'
  },
  value: {
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 400
  },
  hr: {
    borderBottom: `1px solid ${Colors.border}`,
    marginTop: '0.875rem'
  }
} as const
