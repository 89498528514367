import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Table from '../../../Components/Common/Table'
import Button from '../../../Components/Common/Button'
import ReceiptModal from '../../../Components/Common/ReceiptModal'
import ReportSummaryModal from '../../../Components/Common/ReportSummaryModal'
import { Input, Select } from '../../../Components'
import {
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
  useGridApiRef,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { formatFromCents } from '../../../Utils/payment'
import { Colors } from '../../../Utils/theme'

const Reports = (props: any) => {
  const { sessionStore, fieldStore, reportStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading } = reportStore

  const gridApiRef = useGridApiRef()

  // Report params
  const [field, setField] = useState('')
  const [track, setTrack] = useState('')
  const [type, setType] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [userSearchKey, setUserSearchKey] = useState('')
  const [paymentIdSearchKey, setPaymentIdSearchKey] = useState('')

  // For receipt
  const [row, setRow] = useState<any>(null)

  // For summary
  const [showSummary, setShowSummary] = useState(false)

  const clearUserSearchKey = () => setUserSearchKey('')
  const clearPaymentIdSearchKey = () => setPaymentIdSearchKey('')
  const clearSelections = () => {
    setField('')
    setTrack('')
    setType('')
    setStartTime('')
    setEndTime('')
    setUserSearchKey('')
    setPaymentIdSearchKey('')
  }

  const openSummary = () => setShowSummary(true)
  const closeSummary = () => setShowSummary(false)

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
    }
  }, [])

  const getFieldOptions = () => {
    if (fieldStore.fields?.length) {
      return fieldStore.fields.map((field: any) => ({
        value: field.id,
        label: field.nameFi
      }))
    }
    return []
  }
  const getTrackOptions = () => {
    if (field) {
      const selectedField = fieldStore.fields.find((item: any) => item.id === field)
      return selectedField.tracks.map((track: any) => ({
        value: track.id,
        label: track.nameFi
      }))
    }
    return []
  }

  const getEventTypeOptions = () => {
    return [
      { value: 'track_slot', label: t('track_slot_payment') },
      { value: 'system_reservation', label: t('system_track_slot_payment') },
      { value: 'payment_code', label: t('payment_code_purchase') },
      { value: 'admin_payment_code', label: t('admin_payment_code_purchase') },
      { value: 'season_pass', label: t('season_pass_purchase') },
      { value: 'admin_season_pass', label: t('admin_season_pass_purchase') },
      { value: 'product', label: t('product') },
      { value: 'support_payment', label: t('support_payment') }
    ]
  }

  const openReceipt = (item: any) => setRow(item)
  const closeReceipt = () => setRow(null)
  const openStripeReceipt = (item: any) => {
    if (item?.receiptUrl) {
      window.open(item.receiptUrl, '_blank')
    }
  }

  const createReport = () => {
    const params: any = {}
    if (track) params.trackId = track
    if (type) params.type = type
    if (startTime) params.startTime = startTime
    if (endTime) params.endTime = endTime
    reportStore.getReport(
      fieldStore.fields.find((item: any) => item.id === field)?.organizationId,
      field,
      params
    )
  }
  
  const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef)

  const downloadReport = () => {
    const fieldName = (getFieldOptions().find((item: any) => item.value === field)?.label).replaceAll(' ', '-') || ''
     gridApiRef.current.exportDataAsCsv({
      getRowsToExport: getFilteredRows,
      allColumns: true,
      fields: [
        'createdAt',
        'externalPaymentId',
        'orderType',
        'productQuantity',
        'paymentMethod',
        'mobileUser',
        sessionStore?.user?.role === 'admin' ? 'priceTotal' : 'priceProduct'
      ],
      fileName: `${fieldName}-${DateTime.now().toFormat('dd-MM-yyyy-HH-mm')}`,
      utf8WithBom: true
     })
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'createdAt',
      headerName: t('event_time'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        const dayPart = DateTime.fromISO(params.value).toFormat('dd.MM.yyyy')
        const timePart = DateTime.fromISO(params.value).toFormat('HH.mm')
        return `${dayPart} ${t('at')} ${timePart}`
      }
    },
    {
      field: 'externalPaymentId',
      headerName: t('payment_id'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => params?.value ? `${params.value.slice(0, 6)}...${params.value.slice(-6)}`  : '-'
    },
    {
      field: 'orderType',
      headerName: t('type'),
      flex: 1,
      maxWidth: 240,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params?.value ? t(params.value) : '-'
    },
    {
      field: 'productQuantity',
      headerName: t('amount'),
      headerClassName: 'pdg-table--header',
      flex: 1,
      maxWidth: 120,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.row?.orderType?.includes('admin_')) {
          return 1
        }
        return params.row.productQuantity
      }
    },
    {
      field: 'paymentMethod',
      headerName: t('payment_method'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.row?.orderType?.includes('admin_')) {
          return t('created_by_admin')
        }
        return params.row?.paymentMethod ? t(params.row.paymentMethod) : '-'
      }
    },
    {
      field: 'mobileUser',
      headerName: t('purchased_by'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.row?.orderType?.includes('admin_')) {
          if (params?.row?.orderType === 'admin_payment_code') {
            return `${params.row?.savedByMobileUser?.firstName} ${params.row?.savedByMobileUser?.lastName}`.trim() || '-'
          } else {
            return `${params.row?.holderMobileUser?.firstName} ${params.row?.holderMobileUser?.lastName}`.trim() || '-'
          }
        }
        if (!params?.row?.mobileUser) {
          return '-'
        }
        return `${params.row?.mobileUser?.firstName} ${params.row?.mobileUser?.lastName}`.trim() || '-'
      }
    },
    sessionStore?.user?.role === 'admin' ? {
      field: 'priceTotal',
      headerName: t('sum'),
      flex: 1,
      maxWidth: 140,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => formatFromCents(params.value)
    } : {
      field: 'priceProduct',
      headerName: t('sum'),
      flex: 1,
      maxWidth: 140,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.priceProduct || params.row.priceProduct === 0) {
          return `${((params.row.priceProduct * params.row.productQuantity) / 100).toFixed(2).replace('.', ',')} €`
        }
        return '-'
      }
    },
    {
      field: 'receipts',
      headerName: t('receipt'),
      flex: 1,
      minWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      exportable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={styles.actions}>
            {params?.row?.orderType?.includes('admin_') ? null : (
              <ButtonBase onClick={() => openReceipt(params.row)}>
                <Chip label={t('receipt')} variant='outlined' size='small' sx={styles.chip} />
              </ButtonBase>
            )}
            {
              params?.row?.receiptUrl && sessionStore?.user?.role === 'admin' ? (
                <ButtonBase sx={styles.stripeButton} onClick={() => openStripeReceipt(params.row)}>
                  <Chip label={t('stripe_receipt')} variant='outlined' size='small' sx={styles.chip} />
                </ButtonBase>
              ) : null
            }
          </Box>
        )
      }
    }
  ], [language])

  const getRows = () => {
    let rows = reportStore.rows
    if (userSearchKey) {
      const searchKey = userSearchKey.toLowerCase()
      rows = rows.filter((item: any) => {
        let fullName
        if (item.orderType.includes('admin_')) {
          if (item.orderType === 'admin_payment_code') {
            fullName = `${item.savedByMobileUser?.firstName} ${item.savedByMobileUser?.lastName}`.toLowerCase()
          } else {
            fullName = `${item.holderMobileUser?.firstName} ${item.holderMobileUser?.lastName}`.toLowerCase()
          }
        } else {
          fullName = `${item.mobileUser?.firstName} ${item.mobileUser?.lastName}`.toLowerCase()
        }
        return fullName?.includes(searchKey)
      })
    }
    if (paymentIdSearchKey) {
      const searchKey = paymentIdSearchKey.toLowerCase()
      rows = rows.filter((item: any) => {
        return item?.externalPaymentId?.toLowerCase()?.includes(searchKey)
      })
    }
    return rows
  }

  const getSummary = () => {
    return {
      appReservedSlots: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'track_slot') {
          return acc + item.productQuantity
        }
        return acc
      }, 0),
      systemReservedSlots: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'system_reservation') {
          return acc + item?.productQuantity
        }
        return acc
      }, 0),
      appPaymentCodes: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'payment_code') {
          return acc + item.productQuantity
        }
        return acc
      }, 0),
      createdAdminPaymentCodes: reportStore.rows
        ?.find((item: any) => item.orderType === 'admin_payment_code')
        ?.totalCreated || 0,
      activatedAdminPaymentCodes: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'admin_payment_code') {
          return acc + 1
        }
        return acc
      }, 0),
      appSeasonPasses: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'season_pass') {
          return acc + item.productQuantity
        }
        return acc
      }, 0),
      createdAdminSeasonPasses: reportStore.rows
        ?.find((item: any) => item.orderType === 'admin_season_pass')
        ?.totalCreated || 0,
      activatedAdminSeasonPasses: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'admin_season_pass') {
          return acc + 1
        }
        return acc
      }, 0),
      totalProducts: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'product') {
          return acc + item.productQuantity
        }
        return acc
      }, 0),
      totalSupportPayments: reportStore.rows.reduce((acc: any, item: any) => {
        if (item.orderType === 'support_payment') {
          return acc + item.productQuantity
        }
        return acc
      }, 0)
    }
  }

  const renderReportRows = () => {
    if (reportStore?.rows?.length) {
      return (
        <Table
          rows={getRows()}
          columns={columns}
          sx={{ maxHeight: null }}
          apiRef={gridApiRef}
          columnVisibilityModel={{
            // createdAt: false
          }}
        />
      )
    }
    return null
  }

  const renderReceiptModal = () => {
    if (row) {
      return (
        <ReceiptModal
          item={row}
          onClose={closeReceipt}
          field={getFieldOptions().find((item: any) => item.value === field)?.label}
          tracks={getTrackOptions()}
          isAdmin={sessionStore.user?.role === 'admin'}
        />
      )
    }
    return null
  }

  const renderSummaryModal = () => {
    if (showSummary) {
      return (
        <ReportSummaryModal
          summary={getSummary()}
          onClose={closeSummary}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.reportForm}>
        <Box sx={styles.row}>
          <Select
            label={t('select_field')}
            options={getFieldOptions()}
            value={field}
            onChange={setField}
            maxWidth='24.625rem'
            mr='2rem'
          />
          <Select
            label={t('select_track')}
            options={getTrackOptions()}
            value={track}
            onChange={setTrack}
            disabled={!field}
            mr='2rem'
          />
          <Select
            label={t('select_event_type')}
            options={getEventTypeOptions()}
            value={type}
            onChange={setType}
          />
        </Box>
        <Box sx={{ ...styles.row, mb: '1rem' }}>
          <Box sx={styles.dateRow}>
            <Input
              label={t('from_date')}
              value={startTime}
              onChange={setStartTime}
              maxWidth='11rem'
              mr='1rem'
              type='date'
            />
            <Box sx={styles.separator}>-</Box>
            <Input
              label={t('until_date')}
              value={endTime}
              onChange={setEndTime}
              type='date'
              maxWidth='11rem'
              ml='1rem'
            />
          </Box>
          <Input
            label={t('search_by_user_name')}
            value={userSearchKey}
            onChange={setUserSearchKey}
            onAction={clearUserSearchKey}
            iconSx={styles.clearIcon}
            mr='2rem'
          />
          <Input
            label={t('search_by_payment_id')}
            value={paymentIdSearchKey}
            onChange={setPaymentIdSearchKey}
            onAction={clearPaymentIdSearchKey}
            iconSx={styles.clearIcon}
          />
        </Box>
        <Box sx={styles.row}>
          <Typography variant='body1' fontSize='1.125rem' mr='1.5rem'>
            {t('your_search_found')} {reportStore.rows?.length} {t('event_count')}
          </Typography>
          <ButtonBase onClick={clearSelections} sx={styles.clearSelectionsButton}>
            <Chip label={t('clear_selections')} variant='outlined' size='small' sx={styles.chip} />
          </ButtonBase>
          {reportStore?.rows?.length ? (
            <ButtonBase onClick={openSummary}>
              <Chip label={t('view_summary')} variant='outlined' size='small' sx={styles.outlinedChip} />
            </ButtonBase>
          ) : null}
          <Box sx={styles.actionsRow}>
            <Fade in={!!reportStore?.rows?.length}>
              <Button
                text={t('download')}
                icon='excel'
                onClick={downloadReport}
                disabled={!reportStore?.rows?.length}
                variant='secondary'
                width='11rem'
                ml='2rem'
              />
            </Fade>
            <Button
              text={t('create_report')}
              onClick={createReport}
              disabled={!field}
              width='11rem'
              ml='2rem'
            />
          </Box>
        </Box>        
      </Box>
      {renderReportRows()}
      {renderReceiptModal()}
      {renderSummaryModal()}
    </Box>
  )
}

export default observer(Reports)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    pb: '10rem'
  },
  reportForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem',
    mb: '2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '3.125rem',
    mr: '2rem',
    mb: '1.5rem'
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1rem',
    fontWeight: 900,
    color: Colors.text60
  },
  clearIcon: {
    top: '2.375rem',
    right: '1rem',
    width: '1.675rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.white,
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.625rem',
    border: 0,
    padding: '0rem 0.375rem',
    textDecoration: 'underline',
    ':hover': {
      backgroundColor: Colors.brandPrimaryDarker,
      textDecoration: 'none'
    }
  },
  outlinedChip: {
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.brandPrimary,
    backgroundColor: Colors.transparent,
    borderRadius: '0.625rem',
    border: 0,
    padding: '0rem 0.375rem',
    textDecoration: 'underline',
    ':hover': {
      backgroundColor: Colors.black05,
      textDecoration: 'none'
    }
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  stripeButton: {
    marginLeft: '0.75rem'
  },
  clearSelectionsButton: {
    mr: '1rem'
  }
} as const
