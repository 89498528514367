import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from './Select'
import Button from './Button'
import Input from './Input'
import SeasonPassCodeIcon from '../../Assets/Icons/card.svg'
import { Colors } from '../../Utils/theme'

export default function AttachmentModal(props: any) {
  const { onClose, loading, type } = props
  const { t } = useTranslation()

  const [url, setUrl] = useState('')

  useEffect(() => {
    if (props?.url) {
      setUrl(props.url)
    }
  }, [])

  const save = () => props?.onSave(url)

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <DialogTitle variant='h2' sx={styles.title}>{t(`add_${type}`)}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Input label={t(`${type}_url`)} value={url} onChange={setUrl} />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('cancel')}
          onClick={onClose}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={save}
          loading={loading}
          disabled={loading || !url}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '2.5rem'
  },
  title: {
    color: Colors.heading
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0,
    marginTop: '0.5rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
