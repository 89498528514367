import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import CloseIcon from '../../Assets/Icons/close.svg'
import VisibleIcon from '../../Assets/Icons/visible.svg'
import HiddenIcon from '../../Assets/Icons/hidden.svg'
import Button from './Button'
import { Colors } from '../../Utils/theme'

export default function Input(props: any) {
  const {
    id,
    label,
    value,
    placeholder,
    type,
    multiline,
    sx,
    inputSx,
    autoFocus,
    handleKeyPress,
    disabled,
    onAction
  } = props

  const getContainerStyle = () => {
    const extraStyles: any = {}
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const getInputStyle = () => {
    let inputStyles: any = multiline ? styles.multiline : styles.input
    if (inputSx) inputStyles = { ...inputStyles, ...inputSx }
    if (props?.inputMinHeight) inputStyles.minHeight = props.inputMinHeight
    if (props?.inputMaxHeight) inputStyles.maxHeight = props.inputMaxHeight
    if (props?.auth) {
      inputStyles = { ...inputStyles, ...styles.authInput }
    }
    return inputStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props?.iconSx) iconStyles = { ...iconStyles, ...props.iconSx }
    if (props?.auth) {
      iconStyles = { ...iconStyles, top: '1rem' }
    }
    return iconStyles
  }

  const getUnitContainerStyle = () => {
    let unitContainerStyles: any = { ...styles.unitContainer }
    if (props?.unitContainerSx) {
      unitContainerStyles = { ...unitContainerStyles, ...props.unitContainerSx }
    }
    if (props.unitTop) unitContainerStyles.top = props.unitTop
    
    return unitContainerStyles
  }

  const onChange = (evt: any) => props.onChange(evt.target.value)

  const onCopyToClipboard = () => navigator.clipboard.writeText(value)

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel sx={styles.label} htmlFor={id} disableAnimation>
          {label}
        </InputLabel>
      )
    }
    return null
  }

  const renderActionButton = () => {
    if (props?.copy && value?.length) {
      return (
        <Button
          onClick={onCopyToClipboard}
          icon='copy'
          sx={styles.iconButton}
          iconSx={styles.iconButtonIcon}
        />
      )
    }
    if (onAction && value?.length) {
      if (props?.password) {
        return (
          <Box
            component='img'
            sx={getIconStyle()}
            src={type === 'password' ? VisibleIcon : HiddenIcon}
            onClick={onAction}
          />
        )
      }
      return <Box component='img' sx={getIconStyle()} src={CloseIcon} onClick={onAction} />
    }
    if (props?.unit) {
      const unit = props.unit === 'euro' ? '€' : ''
      if (unit) {
        return (
          <Box sx={getUnitContainerStyle()}>
            <Typography
              variant='body1'
              sx={{ ...styles.unit, ...(props?.unitSize ? { fontSize: props?.unitSize } : {}) }}
            >
              {unit}
            </Typography>
          </Box>
        )
      }
    }
    return null
  }

  return (
    <Box sx={getContainerStyle()}>
      {renderLabel()}
      <TextField
        id={id}
        value={value ?? ''}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        type={type}
        multiline={multiline}
        autoFocus={autoFocus}
        disabled={disabled}
        sx={sx}
        InputProps={{
          sx: getInputStyle(),
          ...(multiline ? { rows: props?.rowCount || 5, multiline: true, inputComponent: 'textarea' } : {}),
          autoComplete: type === 'email' ? 'on' : 'off'
        }}
        autoComplete={type === 'email' ? 'on' : 'off'}
      />
      {renderActionButton()}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '0.875rem',
    fontWeight: 600
  },
  input: {
    height: '3.125rem',
    fontSize: '1rem',
    color: Colors.text60,
    backgroundColor: Colors.white,
    borderRadius: '0.625rem',
    borderColor: Colors.border,
    padding: '0rem 0.25rem',
    '&::placeholder': {
      color: Colors.text60
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.border
      }
    }
  },
  authInput: {
    backgroundColor: Colors.white,
    alignSelf: 'center',
    width: '100%',
    height: '4rem',
    color: Colors.text,
    mb: '1.25rem',
    '&::placeholder': {
      color: Colors.text
    }
  },
  multiline: {
    minHeight: '8rem',
    fontSize: '1rem',
    color: Colors.text60,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    borderColor: Colors.border,
    padding: '1rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '&::placeholder': {
      color: Colors.text60
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.text
      }
    },
    '&.Mui-disabled': {
      backgroundColor: Colors.black05
    }
  },
  icon: {
    position: 'absolute',
    top: '0.625rem',
    right: '1rem',
    cursor: 'pointer',
    width: '1.75rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  },
  iconButton: {
    position: 'absolute',
    top: '2.2125rem',
    right: '0.5rem',
    height: '2.25rem',
    minWidth: '2.5rem',
    padding: 0
  },
  iconButtonIcon: {
    width: '1.25rem',
    marginRight: '0rem'
  },
  unitContainer: {
    position: 'absolute',
    top: '2.275rem',
    right: '0.625rem',
    height: '2rem',
    minWidth: '2rem',
    backgroundColor: Colors.brandPrimary,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.3125rem'
  },
  unit: {
    fontSize: '1.375rem',
    color: Colors.white,
    fontWeight: 600
  }
} as const
