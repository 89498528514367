import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from './Select'
import Button from './Button'
import SeasonPassCodeIcon from '../../Assets/Icons/card.svg'
import { Colors } from '../../Utils/theme'

export default function CreateSeasonPassModal(props: any) {
  const { onClose, loading, fields, seasons } = props
  const { t } = useTranslation()

  const [quantity, setQuantity] = useState('')
  const [field, setField] = useState('')
  const [season, setSeason] = useState('')

  useEffect(() => {
    if (field) {
      props.getSeasons(
        fields.find((f: any) => f.id === field)?.organizationId,
        field
      )
    }
  }, [field])

  const getIsSaveDisabled = () => {
    if (!field) {
      return true
    }
    const targetField = fields.find((f: any) => f.id === field)
    return loading ||
      !targetField?.organizationId ||
      !targetField?.id ||
      !quantity ||
      !season
  }

  const getFieldOptions = () => {
    return fields.map((field: any) => ({ label: field.nameFi, value: field.id }))
  }

  const getQuantityOptions = () => {
    return Array.from({ length: 100 }, (_, i) => ({ label: `${i + 1}`, value: i + 1 }))
  }

  const getSeasonOptions = () => {
    return (seasons || []).map((season: any) => {
      return {
        value: season.id,
        label: `${season.nameFi} (${DateTime.fromISO(season.startTime).toFormat('dd.MM.yyyy')} - ${DateTime.fromISO(season.endTime).toFormat('dd.MM.yyyy')})`
      }
    })
  }

  const createSeasonPassCodes = () => {
    const targetField = fields.find((f: any) => f.id === field)
    if (targetField) {
      props.onCreate(
        targetField.organizationId,
        targetField.id,
        {
          quantity,
          fieldSeasonId: season
        }
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Box sx={styles.titleRow}>
        <Box component='img' sx={styles.icon} src={SeasonPassCodeIcon} alt='icon' />
        <DialogTitle variant='h2' sx={styles.title}>{t('create_season_pass_code')}</DialogTitle>
      </Box>
      <DialogContent sx={styles.content}>
        <Box sx={styles.row}>
          <Select
            label={t('field')}
            options={getFieldOptions()}
            value={field}
            onChange={setField}
            mr='2rem'
          />
          <Select
            label={t('quantity')}
            options={getQuantityOptions()}
            value={quantity}
            onChange={setQuantity}
            maxWidth='11rem'
          />
        </Box>
        <Select
          label={t('season')}
          options={getSeasonOptions()}
          value={season}
          onChange={setSeason}
          disabled={!field || !seasons?.length}
          mb='1.5rem'
        />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('cancel')}
          onClick={onClose}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={createSeasonPassCodes}
          loading={loading}
          disabled={getIsSaveDisabled()}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '2.5rem'
  },
  title: {
    color: Colors.heading
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
