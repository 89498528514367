import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LangSelect from '../../../Components/Common/LangSelect'
import Map from '../../../Components/Common/Map'
import SquareButton from '../../../Components/Common/SquareButton'
import { Button, Input, Select } from '../../../Components'
import TrashIcon from '../../../Assets/Icons/trash.svg'
import { CountryOptions, TrackClassifications } from '../../../Utils/constants'
import { Colors } from '../../../Utils/theme'

const CreateTrack = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { sessionStore, organizationStore, fieldStore }: any = useStore()
  const { user } = sessionStore

  const [lang, setLang] = useState('fi')
  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [nameSv, setNameSv] = useState('')
  const [descriptionFi, setDescriptionFi] = useState('')
  const [descriptionEn, setDescriptionEn] = useState('')
  const [descriptionSv, setDescriptionSv] = useState('')
  const [laneCount, setLaneCount] = useState('')
  const [trackClassification, setTrackClassification] = useState('')
  const [paidStatus, setPaidStatus] = useState(false)
  const [supportPaymentStatus, setSupportPaymentStatus] = useState(false)
  const [supportPaymentOptions, setSupportPaymentOptions] = useState<any>([])
  const [slotPrice, setSlotPrice] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [supportPaymentInfoFi, setSupportPaymentInfoFi] = useState('')
  const [supportPaymentInfoEn, setSupportPaymentInfoEn] = useState('')
  const [supportPaymentInfoSv, setSupportPaymentInfoSv] = useState('')
  const [reminderMessageExtensionFi, setReminderMessageExtensionFi] = useState('')
  const [reminderMessageExtensionEn, setReminderMessageExtensionEn] = useState('')
  const [reminderMessageExtensionSv, setReminderMessageExtensionSv] = useState('')
  const [links, setLinks] = useState<any>([])
  const [fieldAdmin, setFieldAdmin] = useState('')

  const setSupportPaymentOption = (value: any, index: number) => {
    const newOptions: any = [...supportPaymentOptions]
    while (newOptions?.length < 4) {
      newOptions.push('')
    }
    newOptions[index] = value
    setSupportPaymentOptions(newOptions)
  }

  const updatePaidStatus = (value: boolean) => {
    if (paidStatus && !value) {
      // Currently is paid, change to free
      setPaidStatus(false)
      setSlotPrice('')
    } else if (!paidStatus && value) {
      // Currently is free, change to paid
      setPaidStatus(true)
      setSupportPaymentStatus(false)
      setSupportPaymentOptions([])
    }
  }

  const addLink = () => {
    setLinks([...links, { name: '', url: '' }])
  }

  const removeLink = (index: number) => {
    const newLinks = links.filter((link: any, i: number) => i !== index)
    setLinks(newLinks)
  }

  const updateLinkLabel = (value: string, index: number) => {
    const newLinks = [...links]
    newLinks[index].name = value
    setLinks(newLinks)
  }

  const updateLinkUrl = (value: string, index: number) => {
    const newLinks = [...links]
    newLinks[index].url = value
    setLinks(newLinks)
  }

  useEffect(() => {
    const organizationId = params?.organizationId ?? null
    const fieldId = params?.fieldId ?? null
    if (organizationId && fieldId) {
      if (user?.role === 'superadmin' || user?.role === 'admin') {
        organizationStore.getOrganizationUsers(organizationId)
      } else {
        organizationStore.getMyOrganizationUsers()
      }
    }
  }, [])

  const getTrackClassificationOptions = () => {
    return TrackClassifications.map(value => {
      return { value, label: value }
    })
  }

  const getPaidStatusOptions = () => {
    return [
      { value: true, label: t('paid') },
      { value: false, label: t('free') },
    ]
  }

  const getSupportPaymentStatusOptions = () => {
    return [
      { value: true, label: t('yes') },
      { value: false, label: t('no') },
    ]
  }

  const getFieldAdminOptions = () => {
    return organizationStore?.organizationUsers
      .filter((user: any) => user.role === 'field_admin')
      .map((user: any) => {
        return {
          value: user.id,
          label: `${user.firstName} ${user.lastName}`
        }
      })
  }

  const getFieldAdminEmail = () => {
    const user = organizationStore?.organizationUsers.find((user: any) => user.id === fieldAdmin)
    return user?.email
  }

  const getFieldAdminPhone = () => {
    const user = organizationStore?.organizationUsers.find((user: any) => user.id === fieldAdmin)
    return user?.phone
  }

  const searchForLocation = async () => {
    let address = `${streetAddress}, ${postalCode} ${city}`
    if (country) {
      address = `${address}, ${country}`
    }
    const location = await fieldStore.searchForLocation(address)
    if (location) {
      setLatitude(location.latitude)
      setLongitude(location.longitude)
    }
  }

  const create = () => {
    const callback = () => navigate(-1)

    const slotPriceCents = (slotPrice ? (parseFloat(slotPrice) * 100) : null) || null
    const supportPaymentPrices: any = !paidStatus && supportPaymentStatus &&
      supportPaymentOptions
        ?.filter((price: any) => price && price > 0)
        .map((price: any) => parseFloat(price) * 100)
        .sort((a: any, b: any) => a - b)

    let supportPaymentPrice1, supportPaymentPrice2, supportPaymentPrice3, supportPaymentPrice4 = null
    if (supportPaymentPrices?.length) {
      supportPaymentPrice1 = supportPaymentPrices?.[0] ?? null
      supportPaymentPrice2 = supportPaymentPrices?.[1] ?? null
      supportPaymentPrice3 = supportPaymentPrices?.[2] ?? null
      supportPaymentPrice4 = supportPaymentPrices?.[3] ?? null
    }

    const newTrack = {
      nameFi,
      nameEn,
      nameSv,
      descriptionFi,
      descriptionEn,
      descriptionSv,
      laneCount: Number(laneCount),
      trackClassification,
      isFree: !paidStatus,
      slotPrice: paidStatus ? slotPriceCents : 0,
      hasSupportPayment: !paidStatus && supportPaymentStatus,
      supportPaymentPrice1: !paidStatus && supportPaymentStatus ? supportPaymentPrice1 : null,
      supportPaymentPrice2: !paidStatus && supportPaymentStatus ? supportPaymentPrice2 : null,
      supportPaymentPrice3: !paidStatus && supportPaymentStatus ? supportPaymentPrice3 : null,
      supportPaymentPrice4: !paidStatus && supportPaymentStatus ? supportPaymentPrice4 : null,
      streetAddress,
      postalCode,
      city,
      country,
      latitude: (latitude ? parseFloat(latitude) : null) || null,
      longitude: (longitude ? parseFloat(longitude) : null) || null,
      links: links.filter((link: any) => link?.name && link?.url),
      supportPaymentInfoFi,
      supportPaymentInfoEn,
      supportPaymentInfoSv,
      reminderMessageExtensionFi,
      reminderMessageExtensionEn,
      reminderMessageExtensionSv,
      fieldAdminId: fieldAdmin || null
    }
    fieldStore.createOrganizationFieldTrack(
      Number(params.organizationId),
      Number(params.fieldId),
      newTrack,
      callback
    )
  }
  const cancel = () => navigate(-1)

  const renderPriceOrSupportPaymentSelection = () => {
    if (paidStatus) {
      return (
        <Input
          label={t('slot_price')}
          value={slotPrice}
          onChange={setSlotPrice}
          type='number'
          maxWidth='10rem'
          ml='2rem'
          unit='euro'
        />
      )
    }
    return (
      <Select
        label={t('support_payment')}
        options={getSupportPaymentStatusOptions()}
        value={supportPaymentStatus}
        onChange={setSupportPaymentStatus}
        maxWidth='10rem'
        ml='2rem'
      />
    )
  }

  const renderSupportPaymentOptions = () => {
    if (supportPaymentStatus) {
      const options: any = supportPaymentOptions || []
      while (options?.length < 4) {
        options.push('')
      }
      return (
        <Box sx={{ ...styles.row, justifyContent: 'space-between' }}>
          {
            options.slice(0, 4).map((option: any, index: number) => (
              <Input
                label={`${t('support_payment')} #${index + +1}`}
                value={option}
                onChange={(value: any) => setSupportPaymentOption(value, index)}
                type='number'
                maxWidth='10rem'
                mr={index < options?.length - 1 ? '2rem' : '0rem'}
                unit='euro'
              />
            ))
          }
        </Box>
      )
    }
    return null
  }

  const renderCity = () => {
    /*
    if (country === 'Suomi') {
      return (
        <Select
          label={t('city')}
          options={FinnishMunicipalityOptions}
          value={city}
          onChange={setCity}
        />
      )
    } else {
      return <Input label={t('city')} value={city} onChange={setCity} />
    }
    */
    return <Input label={t('city')} value={city} onChange={setCity} />
  }

  const renderMap = () => {
    if (latitude && longitude && parseFloat(latitude) && parseFloat(longitude)) {
      return (
        <>
          <Typography variant='body2' sx={styles.label}>{t('on_map')}</Typography>
          <Map location={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
        </>
      )
    }
    return null
  }

  const renderLinks = () => {
    return links.map((link: any, index: number) => (
      <Box key={index} sx={styles.row}>
        <Input
          label={t('name')}
          value={link?.name || ''}
          onChange={(value: any) => updateLinkLabel(value, index)}
          maxWidth='15rem'
          mr='1rem'
        />
        <Input
          label={t('url')}
          value={link?.url || ''}
          onChange={(value: any) => updateLinkUrl(value, index)}
          mr='1rem'
        />
        <Box>
          <SquareButton
            icon={TrashIcon}
            onClick={() => removeLink(index)}
            size='3.125rem'
            iconSize='1.625rem'
            mt='1.75rem'
            grey
          />
        </Box>
      </Box>
    ))
  }

  const renderFieldAdminSelect = () => {
    if (!fieldAdmin && !getFieldAdminOptions()?.length) {
      return (
        <Box sx={styles.infoContainer}>
          <Typography variant='body2' fontWeight={600} color={Colors.darkText} textAlign='center'>
            {t('organization_has_no_field_admins')}
          </Typography>
        </Box>
      )
    }
    return (
      <>
        <Select
          label={t('name')}
          options={getFieldAdminOptions()}
          value={fieldAdmin}
          onChange={setFieldAdmin}
          disabled={!getFieldAdminOptions()?.length}
          mb='1.5rem'
        />
        <Box sx={styles.row}>
          <Input
            label={t('email')}
            value={getFieldAdminEmail()}
            mr='2rem'
            copy
            disabled
          />
          <Input
            label={t('phone')}
            value={getFieldAdminPhone()}
            maxWidth='12rem'
            disabled
          />
        </Box>
      </>
    )
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('track_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.langSelectContainer}>
            <LangSelect
              value={lang}
              onChange={setLang}
              status={{
                fi: nameFi && descriptionFi,
                en: nameEn && descriptionEn,
                sv: nameSv && descriptionSv
              }}
            />
          </Box>
          <Input
            label={t('name')}
            value={lang === 'fi' ? nameFi : lang === 'en' ? nameEn : nameSv}
            onChange={lang === 'fi' ? setNameFi : lang === 'en' ? setNameEn : setNameSv}
            maxWidth='24rem'
            mb='1.5rem'
          />
          <Input
            label={t('description')}
            value={lang === 'fi' ? descriptionFi : lang === 'en' ? descriptionEn : descriptionSv}
            onChange={lang === 'fi' ? setDescriptionFi : lang === 'en' ? setDescriptionEn : setDescriptionSv}
            mb='1.5rem'
            multiline
          />
          <Box sx={{ ...styles.row, ...{ justifyContent: 'flex-start' } }}>
            <Input
              label={t('lanes')}
              value={laneCount}
              onChange={setLaneCount}
              maxWidth='10rem'
              mr='1.5rem'
              type='number'
            />
            <Select
              label={t('classification')}
              options={getTrackClassificationOptions()}
              value={trackClassification}
              onChange={setTrackClassification}
              maxWidth='12rem'
            />
          </Box>
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('paid_status')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Select
              label={t('paid_status')}
              options={getPaidStatusOptions()}
              value={paidStatus}
              onChange={updatePaidStatus}
            />
            {renderPriceOrSupportPaymentSelection()}
          </Box>
          {renderSupportPaymentOptions()}
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('location')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('street_address')}
            value={streetAddress}
            onChange={setStreetAddress}
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Input
              label={t('postal_code')}
              value={postalCode}
              onChange={setPostalCode}
              maxWidth='12rem'
              mr='2rem'
            />
            {renderCity()}
          </Box>
          <Select
            label={t('country')}
            options={CountryOptions}
            value={country}
            onChange={setCountry}
            maxWidth='24rem'
            mb='1.5rem'
          />
          <Box sx={{ ...styles.row, justifyContent: 'flex-start' }}>
            <Input
              label={t('latitude')}
              value={latitude}
              onChange={setLatitude}
              maxWidth='9rem'
              mr='2rem'
            />
            <Input
              label={t('longitude')}
              value={longitude}
              onChange={setLongitude}
              maxWidth='9rem'
              mr='2rem'
            />
            <Button
              text={t('search_for_location')}
              onClick={searchForLocation}
              disabled={!streetAddress || !postalCode || !city}
              variant='secondary'
              width='11rem'
              mt='1.75rem'
            />
          </Box>
          {renderMap()}
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('links')}</Typography>
        <Box sx={styles.formContainer}>
          {renderLinks()}
          <Button
            text={t('add_new')}
            onClick={addLink}
            width='11rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('messaging')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.langSelectContainer}>
            <LangSelect
              value={lang}
              onChange={setLang}
              status={{
                fi: supportPaymentInfoFi && reminderMessageExtensionFi,
                en: supportPaymentInfoEn && reminderMessageExtensionEn,
                sv: supportPaymentInfoSv && reminderMessageExtensionSv
              }}
            />
          </Box>
          <Input
            label={t('reminder_message_extension_text')}
            value={lang === 'fi' ? reminderMessageExtensionFi : lang === 'en' ? reminderMessageExtensionEn : reminderMessageExtensionSv}
            onChange={lang === 'fi' ? setReminderMessageExtensionFi : lang === 'en' ? setReminderMessageExtensionEn : setReminderMessageExtensionSv}
            mt='2rem'
            mb='1.5rem'
            multiline
          />
          <Input
            label={t('support_payment_info_text')}
            value={lang === 'fi' ? supportPaymentInfoFi : lang === 'en' ? supportPaymentInfoEn : supportPaymentInfoSv}
            onChange={lang === 'fi' ? setSupportPaymentInfoFi : lang === 'en' ? setSupportPaymentInfoEn : setSupportPaymentInfoSv}
            mb='1.5rem'
            multiline
          />
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('field_admin')}</Typography>
        <Box sx={{ ...styles.formContainer, ...{ mb: '10rem' } }}>
          {renderFieldAdminSelect()}
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('create_track')} onClick={create} width='100%' mb='1rem' />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateTrack)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    overflowY: 'auto',
  },
  langSelectContainer: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '1rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '1rem',
    mb: '1rem'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '0.875rem',
    fontWeight: 600
  }
} as const
