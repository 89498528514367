import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton, Button } from '../../../Components'
import ReservationCalendar from '../../../Components/Calendar/ReservationCalendar'
import ChevronLeft from '../../../Assets/Icons/chevron-left.svg'
import ChevronRight from '../../../Assets/Icons/chevron-right.svg'
import SettingsIcon from '../../../Assets/Icons/settings.svg'
import WeekPicker from '../../../Components/Common/WeekPicker'
import ExceptionModal from '../../../Components/Calendar/ExceptionModal'
import SlotParticipantsModal from '../../../Components/Calendar/SlotParticipantsModal'
import CalendarAddItemModal from '../../../Components/Calendar/CalendarAddItemModal'
import { Colors } from '../../../Utils/theme'

const Calendar = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const params: any = useParams()
  const { organizationId, fieldId, trackId } = params

  const { sessionStore, userStore, fieldStore, slotStore }: any = useStore()
  const { track } = fieldStore

  const timezone = 'Europe/Helsinki'

  const [startTime, setStartTime] = useState(DateTime.now().setZone(timezone).startOf('week').toJSDate())
  const [endTime, setEndTime] = useState(DateTime.now().setZone(timezone).endOf('week').toJSDate())
  const [exceptionStartTime, setExceptionStartTime] = useState(null)
  const [exceptionEndTime, setExceptionEndTime] = useState(null)
  const [exceptionType, setExceptionType] = useState(null)
  const [slot, setSlot] = useState(null)
  const [selectedExceptionSlot, setSelectedExceptionSlot] = useState<any>(null)
  // Slot with participants
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [showActionSelection, setShowActionSelection] = useState(false)
  const [showAddExceptionSlotModal, setShowAddExceptionSlotModal] = useState(false)

  useEffect(() => {
    if (organizationId && fieldId) {
      fieldStore.getOrganizationField(organizationId, fieldId)
    }
  }, [])

  const openSlotModal = (slot: any) => {
    setSelectedSlot(slot)
  }
  const closeSlotModal = () => {
    setSelectedSlot(null)
  }
  const openActionSelection = (slot: any) => {
    setSlot(slot)
    setShowActionSelection(true)
  }
  const closeActionSelection = () => setShowActionSelection(false)

  const onSelectAction = (type: string) => {
    closeActionSelection()
    handleOpenExceptionFromSlot({ ...(slot || {}), type })
    setShowAddExceptionSlotModal(true)
  }

  const toSeasonSettings = () => navigate(`/organizations/${organizationId}/fields/${fieldId}`)
  const toCalendarSettings = () => navigate(`/organizations/${organizationId}/fields/${fieldId}/tracks/${trackId}`)

  const onConfigure = () => {
    if (!getHasActiveFieldSeasons()) {
      toSeasonSettings()
    } else {
      toCalendarSettings()
    }
  }

  const getHasActiveFieldSeasons = () => {
    if (fieldStore?.field?.seasons?.length) {
      const now = DateTime.now().valueOf()
      return fieldStore.field.seasons.some((season: any) => {
        if (season?.startTime && season?.endTime) {
          const startTimeVal = DateTime.fromISO(season.startTime).valueOf()
          const endTimeVal = DateTime.fromISO(season.endTime).valueOf()
          return startTimeVal < now && now < endTimeVal
        }
        return false
      })
    }
    return false
  }

  const getHasActiveSeasons = () => {
    if (slotStore?.trackSeasons?.length) {
      const now = DateTime.now().valueOf()
      return slotStore.trackSeasons.some((season: any) => {
        if (season?.startTime && season?.endTime) {
          const startTimeVal = DateTime.fromISO(season.startTime).valueOf()
          const endTimeVal = DateTime.fromISO(season.endTime).valueOf()
          return startTimeVal < now && now < endTimeVal
        }
        return false
      })
    }
    return false
  }

  const getEmptyCalendarTitle = () => {
    if (!getHasActiveFieldSeasons()) {
      return t('setup_season_title')
    }
    if (!getHasActiveSeasons()) {
      return t('setup_calendar_title')
    }
    return t('setup_calendar_title_no_active_seasons')
  }
  const getEmptyCalendarInfo = () => {
    if (!getHasActiveFieldSeasons()) {
      return t('setup_season_message')
    }
    if (!getHasActiveSeasons()) {
      return t('setup_calendar_message')
    }
    return t('setup_calendar_message_no_active_seasons')
  }

  const getEmptyCalendarButtonText = () => {
    if (!getHasActiveFieldSeasons()) {
      return t('setup_season')
    }
    if (!getHasActiveSeasons()) {
      return t('setup_calendar')
    }
    return t('setup_calendar')
  }

  const handleCreateExceptionSlot = (exceptionSlot: any) => {
    const { organizationId, trackId } = params
    slotStore.createOrganizationTrackExceptionSlot(
      organizationId,
      trackId,
      exceptionSlot,
      reloadWeek
    )
  }

  const handleEditExceptionSlot = (exceptionSlotId: number, exceptionSlot: any) => {
    const { organizationId, trackId } = params
    slotStore.updateOrganizationTrackExceptionSlot(
      organizationId,
      trackId,
      exceptionSlotId,
      exceptionSlot,
      reloadWeek
    )
  }

  const handleDeleteExceptionSlot = (exceptionSlotId: number) => {
    const { organizationId, trackId } = params
    slotStore.deleteOrganizationTrackExceptionSlot(
      organizationId,
      trackId,
      exceptionSlotId,
      reloadWeek
    )
  }

  const reloadWeek = () => {
    // Null exception selections
    setExceptionStartTime(null)
    setExceptionEndTime(null)
    setSelectedExceptionSlot(null)

    slotStore.getOrganizationTrackCalendar(
      organizationId,
      trackId,
      startTime.toISOString(),
      endTime.toISOString()
    )
  }

  const toPreviousWeek = () => {
    const newStartTime = DateTime
      .fromJSDate(startTime)
      .minus({ days: 6 })
      .setZone(timezone)
      .startOf('week')
      .toJSDate()
    const newEndTime = DateTime
      .fromJSDate(endTime)
      .minus({ days: 8 })
      .setZone(timezone)
      .endOf('week')
      .toJSDate()
    setStartTime(newStartTime)
    setEndTime(newEndTime)
    slotStore.getOrganizationTrackCalendar(
      organizationId,
      trackId,
      newStartTime.toISOString(),
      newEndTime.toISOString()
    )
  }

  const toNextWeek = () => {
    const newStartTime = DateTime
      .fromJSDate(startTime)
      .plus({ days: 8 })
      .setZone(timezone)
      .startOf('week')
      .toJSDate()
    const newEndTime = DateTime
      .fromJSDate(endTime)
      .plus({ days: 6 })
      .setZone(timezone)
      .endOf('week')
      .toJSDate()
    setStartTime(newStartTime)
    setEndTime(newEndTime)
    slotStore.getOrganizationTrackCalendar(
      organizationId,
      trackId,
      newStartTime.toISOString(),
      newEndTime.toISOString()
    )
  }

  useEffect(() => {
    fieldStore.getOrganizationFieldTrack(organizationId, fieldId, trackId)
    slotStore.getOrganizationTrackSeasons(organizationId, trackId)
    slotStore.getOrganizationTrackCalendar(
      organizationId,
      trackId,
      startTime.toISOString(),
      endTime.toISOString()
    )
  }, [])

  const handleCloseExceptionModal = () => {
    setSelectedExceptionSlot(null)
    setExceptionStartTime(null)
    setExceptionEndTime(null)
    setShowAddExceptionSlotModal(false)
    setExceptionType(null)
  }

  const handleOpenExceptionFromSlot = (slot: any) => {
    if (slot.exception) {
      // TODO
      setExceptionStartTime(null)
      setExceptionEndTime(null)
      setSelectedExceptionSlot(slot.exception)
    } else {
      setExceptionStartTime(slot.startTime)
      setExceptionEndTime(slot.endTime)
      setExceptionType(slot.type)
    }
  }

  const handleSetWeek = (value: any) => {
    const moment = DateTime.fromISO(value).setZone(timezone)
    const newStartTime = moment.startOf('week').toJSDate()
    const newEndTime = moment.endOf('week').toJSDate()
    setStartTime(newStartTime)
    setEndTime(newEndTime)
    slotStore.getOrganizationTrackCalendar(
      organizationId,
      trackId,
      newStartTime.toISOString(),
      newEndTime.toISOString()
    )
  }

  const renderSelectedWeek = () => {
    const weekMoment = DateTime.fromJSDate(startTime).setZone(timezone)
    const formatted = `${t('week')} ${weekMoment.weekNumber} / ${weekMoment.year}`
    // Add 2 days to avoid any timezone problems
    const weekPickerValue = DateTime.fromJSDate(startTime).plus({ days: 2 }).toFormat('yyyy-MM-dd')

    return (
      <WeekPicker
        formattedValue={formatted}
        value={weekPickerValue}
        onChange={handleSetWeek}
      />
    )
  }

  const renderTimePeriod = () => {
    const start = DateTime.fromJSDate(startTime).setZone(timezone).toFormat('dd.MM.yyyy')
    const end = DateTime.fromJSDate(endTime).setZone(timezone).toFormat('dd.MM.yyyy')
    return <Box sx={styles.timePeriod}>{start} - {end}</Box>
  }

  const renderTrackName = () => {
    const fieldName = track?.field?.nameFi || track?.field?.nameEn || track?.field?.nameSv || ''
    const trackName = track?.nameFi || track?.nameEn || track?.nameSv || ''
    return <Typography variant='h3'>{`${fieldName} - ${trackName}`}</Typography>
  }

  const renderActionSelectionModal = () => {
    if (showActionSelection) {
      return <CalendarAddItemModal onClose={closeActionSelection} onSelect={onSelectAction} />
    }
    return null
  }

  const renderSlotParticipantsModal = () => {
    if (selectedSlot) {
      return (
        <SlotParticipantsModal
          slot={selectedSlot}
          timezone={timezone}
          onClose={closeSlotModal}
        />
      )
    }
    return null
  }

  const renderExceptionModal = () => {
    if (showAddExceptionSlotModal || selectedExceptionSlot) {
      return (
        <ExceptionModal
          open={selectedExceptionSlot || (exceptionStartTime && exceptionEndTime)}
          onClose={handleCloseExceptionModal}
          startTime={exceptionStartTime}
          endTime={exceptionEndTime}
          type={exceptionType || selectedExceptionSlot?.type}
          onCreate={handleCreateExceptionSlot}
          onEdit={handleEditExceptionSlot}
          onDelete={handleDeleteExceptionSlot}
          timezone='Europe/Helsinki'
          selectedExceptionSlot={selectedExceptionSlot}
          loading={slotStore.loading}
        />
      )
    }
    return null
  }

  const renderCalendarContent = () => {
    if (!slotStore.loading && (!fieldStore?.field?.seasons?.length || !slotStore.trackSeasons?.length)) {
      return (
        <Box sx={styles.emptyCalendarContainer}>
          <Box sx={styles.emptyCalendarContentContainer}>
            <Typography variant='h4' mb='1rem'>{getEmptyCalendarTitle()}</Typography>
            <Typography sx={styles.text}>{getEmptyCalendarInfo()}</Typography>
            <Button
              text={getEmptyCalendarButtonText()}
              onClick={onConfigure}
              width='14rem'
            />
          </Box>
        </Box>
      )
    } else if (!slotStore.loading && slotStore.calendar?.slots?.length === 0) {
      return (
        <>
          <Box sx={styles.weekPickerContainer}>
            <Box>
              {renderTrackName()}
              {renderTimePeriod()}
            </Box>
            <Box sx={styles.flex} />
            <IconButton
              icon={SettingsIcon}
              onClick={toSeasonSettings}
              text={t('season_config')}
              iconSize='1.25rem'
            />
            <IconButton icon={ChevronLeft} onClick={toPreviousWeek} ml='1rem' mr='1rem' />
            {renderSelectedWeek()}
            <IconButton icon={ChevronRight} onClick={toNextWeek} ml='1rem' />
          </Box>
          <Box sx={styles.emptyCalendarContainer}>
            <Box sx={styles.emptyCalendarContentContainer}>
              <Typography variant='h4' mb='1rem'>{getEmptyCalendarTitle()}</Typography>
              <Typography sx={styles.text}>{getEmptyCalendarInfo()}</Typography>
              <Button text={t('configure')} onClick={onConfigure} width='14rem' />
            </Box>
          </Box>
        </>
      )
    }
    return (
      <Box sx={styles.calendarOuterContainer}>
        <Box sx={styles.weekPickerContainer}>
          <Box>
            {renderTrackName()}
            {renderTimePeriod()}
          </Box>
          <Box sx={styles.flex} />
          <IconButton
            icon={SettingsIcon}
            onClick={toSeasonSettings}
            text={t('season_config')}
            iconSize='1.25rem'
          />
          <IconButton icon={ChevronLeft} onClick={toPreviousWeek} ml='1rem' mr='1rem' />
          {renderSelectedWeek()}
          <IconButton icon={ChevronRight} onClick={toNextWeek} ml='1rem' />
        </Box>
        <ReservationCalendar
          slots={slotStore.calendar?.slots}
          timezone={slotStore.calendar?.timezone}
          openException={handleOpenExceptionFromSlot}
          openSlot={openSlotModal}
          openAction={openActionSelection}
          startTime={startTime}
          endTime={endTime}
        />
        {renderActionSelectionModal()}
        {renderExceptionModal()}
        {renderSlotParticipantsModal()}
      </Box>
    )
  }

  return (
    <>
      <Box sx={styles.container}>
        {renderCalendarContent()}
      </Box>
    </>
  )
}

export default observer(Calendar)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem'
  },
  calendarOuterContainer: {
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    padding: '2rem',
    marginBottom: '5rem'
  },
  /*
  calendarContainer: {
    maxHeight: {
      lg: 'calc(100vh - 23rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
    overflow: 'scroll',
    borderBottom: `1px solid ${Colors.border}`
  },
  */
  timePeriod: {
    color: Colors.text60
  },
  weekPickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '1rem'
  },
  spacer: {
    width: '1rem'
  },
  flex: {
    flex: 1
  },
  emptyCalendarContainer: {
    backgroundColor: Colors.light,
    padding: '1rem',
    display: 'flex',
    flex: 1,
    width: '100%',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: {
      xs: 'calc(100vh - 11rem)'
    }
  },
  emptyCalendarContentContainer: {
    padding: '1rem',
    textAlign: 'center'
  },
  text: {
    maxWidth: '33rem',
    color: Colors.text60,
    marginBottom: '2rem'
  }
} as const
