import Box from '@mui/material/Box'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import { DateTime } from 'luxon'
import InputLabel from '@mui/material/InputLabel'
import { Colors } from '../../Utils/theme'

export default function TimeInput(props: any) {
  const { id, label, value } = props

  const getContainerStyle = () => {
    const extraStyles: any = {}
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

    const getInputStyle = () => {
    let inputStyles: any = styles.input
    return inputStyles
  }

  const getValue = () => {
    if (value) {
      if (value instanceof DateTime) {
        return value
      }
      return DateTime.fromFormat(value, 'HH:mm')
    }
    return null
  }

  const onChange = (newValue: any) => props.onChange(newValue.toFormat('HH:mm'))

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel sx={styles.label} htmlFor={id} disableAnimation>
          {label}
        </InputLabel>
      )
    }
    return null
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='fi'>
      <Box sx={getContainerStyle()}>
        {renderLabel()}
        <TimeField
          value={getValue()}
          onChange={onChange}
          format='HH:mm'
          timezone='Europe/Helsinki'
          InputProps={{ sx: getInputStyle() }}
        />
      </Box>
    </LocalizationProvider>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.text,
    fontSize: '0.875rem',
    fontWeight: 600
  },
  input: {
    height: '3.125rem',
    fontSize: '1rem',
    color: Colors.text60,
    backgroundColor: Colors.white,
    borderRadius: '0.625rem',
    borderColor: Colors.border,
    padding: '0rem 0.25rem',
    '&::placeholder': {
      color: Colors.text60
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.border
      }
    }
  }
} as const
