import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Collapse from '@mui/material/Collapse'
import Select from './Select'
import Button from './Button'
import Input from './Input'
import PaymentCodeIcon from '../../Assets/Icons/code.svg'
import { Colors } from '../../Utils/theme'

export default function CreatePaymentCodeModal(props: any) {
  const { onClose, loading, tracks } = props
  const { t } = useTranslation()

  const [quantity, setQuantity] = useState('')
  const [track, setTrack] = useState('')
  const [hasExpirationDate, setHasExpirationDate] = useState(false)
  const [validUntil, setValidUntil] = useState('')

  const getIsDisabled = () => {
    if (!track) {
      return true
    }
    const targetTrack = tracks.find((t: any) => t.id === track)
    return loading ||
      !targetTrack?.organizationId ||
      !targetTrack?.id ||
      !quantity ||
      (hasExpirationDate && !validUntil)
  }

  const getQuantityOptions = () => {
    return Array.from({ length: 100 }, (_, i) => ({ label: `${i + 1}`, value: i + 1 }))
  }
  const getTrackOptions = () => {
    return tracks.map((track: any) => ({ label: track.nameFi, value: track.id }))
  }
  const getHasExpirationDateOptions = () => {
    return [
      { label: t('yes'), value: true },
      { label: t('no'), value: false }
    ]
  }

  const createPaymentCodes = () => {
    const targetTrack = tracks.find((t: any) => t.id === track)
    if (targetTrack) {
      props.onCreate(
        targetTrack.organizationId,
        targetTrack.fieldId,
        targetTrack.id,
        {
          quantity,
          validUntil: hasExpirationDate ? validUntil : null
        }
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Box sx={styles.titleRow}>
        <Box component='img' sx={styles.icon} src={PaymentCodeIcon} alt='icon' />
        <DialogTitle variant='h2' sx={styles.title}>{t('create_payment_code')}</DialogTitle>
      </Box>
      <DialogContent sx={styles.content}>
        <Box sx={styles.row}>
          <Select
            label={t('track')}
            options={getTrackOptions()}
            value={track}
            onChange={setTrack}
            mr='2rem'
          />
          <Select
            label={t('quantity')}
            options={getQuantityOptions()}
            value={quantity}
            onChange={setQuantity}
            maxWidth='11rem'
          />
        </Box>
        <Box sx={styles.row}>
          <Select
            label={t('has_expiration_date')}
            options={getHasExpirationDateOptions()}
            value={hasExpirationDate}
            onChange={setHasExpirationDate}
            maxWidth='11rem'
            mr='2rem'
          />
          <Collapse in={hasExpirationDate}>
            <Input
              label={t('valid_until')}
              value={validUntil}
              onChange={setValidUntil}
              disabled={!hasExpirationDate}
              type='date'
            />
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.textButton}
          text={t('cancel')}
          onClick={onClose}
          variant='text'
        />
        <Button
          sx={styles.button}
          text={t('save')}
          onClick={createPaymentCodes}
          loading={loading}
          disabled={getIsDisabled()}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  icon: {
    height: '4rem'
  },
  title: {
    color: Colors.heading,
    paddingLeft: '1rem'
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
