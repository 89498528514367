import { makeAutoObservable } from 'mobx'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import { Api } from '../Services'

export default class FeedbackStore {
  rootStore

  trackFeedbacks: any = []
  totalTrackFeedbacks = 0
  trackFeedback: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTrackFeedbacks = (trackFeedbacks: any) => { this.trackFeedbacks = trackFeedbacks }
  setTotalTrackFeedbacks = (totalTrackFeedbacks: any) => { this.totalTrackFeedbacks = totalTrackFeedbacks}
  setTrackFeedback = (trackFeedback: any) => { this.trackFeedback = trackFeedback }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getOrganizationTrackFeedbacks(organizationId: number, trackId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getOrganizationTrackFeedbacks(organizationId, trackId)
      if (response.ok) {
        this.setTrackFeedbacks(response.data.items)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getAllTrackFeedbacks() {
    this.setLoading(true)
    try {
      const response: any = await Api.getAllTrackFeedbacks()
      if (response.ok) {
        this.setTrackFeedbacks(response.data.items)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getOrganizationTrackFeedback(organizationId: number, trackId: number, feedbackId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getOrganizationTrackFeedback(organizationId, trackId, feedbackId)
      if (response.ok) {
        this.setTrackFeedback(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getTrackFeedback(feedbackId: number) {
    this.setLoading(true)
    try {
      const response: any = await Api.getTrackFeedback(feedbackId)
      if (response.ok) {
        this.setTrackFeedback(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async publishTrackFeedbackResponse(organizationId: number, trackId: number, feedbackId: number, body: any) {
    try {
      const response: any = await Api.publishTrackFeedbackResponse(organizationId, trackId, feedbackId, body)
      if (response.ok) {
        const trackFeedback = response.data
        this.setTrackFeedback(trackFeedback)
        showMessage(i18n.t('feedback_response_published'), 'success')
        if (this.rootStore.sessionStore?.user?.role === 'admin') {
          this.getAllTrackFeedbacks()
        } else {
          this.getOrganizationTrackFeedbacks(organizationId, trackId)
        }
      } else {
        showMessage(i18n.t('feedback_response_publish_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async publishAdminTrackFeedbackResponse(feedbackId: number, body: any) {
    try {
      const response: any = await Api.publishAdminTrackFeedbackResponse(feedbackId, body)
      if (response.ok) {
        const trackFeedback = response.data
        this.setTrackFeedback(trackFeedback)
        showMessage(i18n.t('feedback_response_published'), 'success')
        if (this.rootStore.sessionStore?.user?.role === 'admin') {
          this.getAllTrackFeedbacks()
        } else {
          this.getOrganizationTrackFeedbacks(trackFeedback.organizationId, trackFeedback.trackId)
        }
      } else {
        showMessage(i18n.t('feedback_response_publish_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async updateTrackFeedbackResponse(organizationId: number, trackId: number, feedbackId: number, body: any) {
    try {
      const response: any = await Api.updateTrackFeedbackResponse(organizationId, trackId, feedbackId, body)
      if (response.ok) {
        const trackFeedback = response.data
        this.setTrackFeedback(trackFeedback)
        showMessage(i18n.t('feedback_response_updated'), 'success')
        if (this.rootStore.sessionStore?.user?.role === 'admin') {
          this.getAllTrackFeedbacks()
        } else {
          this.getOrganizationTrackFeedbacks(organizationId, trackId)
        }
      } else {
        showMessage(i18n.t('feedback_response_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async updateAdminTrackFeedbackResponse(feedbackId: number, body: any) {
    try {
      const response: any = await Api.updateAdminTrackFeedbackResponse(feedbackId, body)
      if (response.ok) {
        const trackFeedback = response.data
        this.setTrackFeedback(trackFeedback)
        showMessage(i18n.t('feedback_response_updated'), 'success')
        if (this.rootStore.sessionStore?.user?.role === 'admin') {
          this.getAllTrackFeedbacks()
        } else {
          this.getOrganizationTrackFeedbacks(trackFeedback.organizationId, trackFeedback.trackId)
        }
      } else {
        showMessage(i18n.t('feedback_response_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }
}
