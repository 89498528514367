import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import BackIcon from '../../Assets/Icons/back-white.svg'
import SortIcon from '../../Assets/Icons/sort.svg'
import CopyIcon from '../../Assets/Icons/copy.svg'
import ExcelIcon from '../../Assets/Icons/excel.svg'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  back: BackIcon,
  sort: SortIcon,
  copy: CopyIcon,
  excel: ExcelIcon
}

const BaseButton = React.forwardRef((props: any, ref: any) => {
  const { text, sx, variant, onClick, loading, ...rest } = props

  const getStyles = () => {
    let buttonStyles: any = variant === 'secondary' ? { ...styles.secondaryButton } : { ...styles.button }
    if (variant === 'alt') {
      buttonStyles = { ...styles.altButton }
    }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props.mb) buttonStyles.marginBottom = props.mb
    if (props.mt) buttonStyles.marginTop = props.mt
    if (props.mr) buttonStyles.marginRight = props.mr
    if (props.ml) buttonStyles.marginLeft = props.ml
    if (props?.width) buttonStyles.width = props.width
    if (props?.height) buttonStyles.height = props.height
    return buttonStyles
  }

  const getIconStyle = () => {
    let iconStyles: any = { ...styles.icon }
    if (props.iconSx) {
      iconStyles = { ...iconStyles, ...props.iconSx }
    }
    return iconStyles
  }

  const renderContent = () => {
    if (loading) {
      return <CircularProgress size='1.25rem' sx={styles.progress} />
    }
    if (props.icon) {
      return (
        <>
          <Box component='img' src={IconMappings[props.icon]} alt='icon' sx={getIconStyle()} />
          {text ?? null}
        </>
      )
    }
    if (props.children) return props.children
    return text ?? null
  }

  return (
    <Button
      sx={getStyles()}
      variant={variant ?? 'contained'}
      onClick={onClick}
      disabled={loading || props.disabled}
      ref={ref ?? null}
      // {...rest}
    >
      {renderContent()}
    </Button>
  )
})

export default BaseButton

const styles = {
  button: {
    height: '3.125rem',
    borderRadius: '0.625rem',
    padding: '0.75rem 1.75rem',
    boxShadow: 0,
    ':hover': {
      boxShadow: 0
    },
    ':disabled': {
      backgroundColor: Colors.brandPrimary60,
      color: Colors.white
    }
  },
  altButton: {
    height: '3.125rem',
    backgroundColor: Colors.brandSecondary,
    color: Colors.brandPrimary,
    borderRadius: '0.625rem',
    padding: '0.75rem 1.75rem',
    boxShadow: 0,
    ':hover': {
      backgroundColor: Colors.brandSecondary80,
      boxShadow: 0
    },
    ':disabled': {
      backgroundColor: Colors.brandSecondary60,
      color: Colors.brandPrimary
    }
  },
  secondaryButton: {
    height: '3.125rem',
    color: Colors.brandPrimary,
    borderRadius: '0.625rem',
    padding: '0.75rem 1.75rem',
    boxShadow: 0,
    ':hover': {
      boxShadow: 0
    },
    ':disabled': {
      // backgroundColor: Colors.brandPrimary60,
      opacity: 0.4,
      color: Colors.brandPrimary
    },
    border: `2px solid ${Colors.brandPrimary}`
  },
  progress: {
    color: Colors.white
  },
  icon: {
    width: '1.3125rem',
    height: 'auto',
    mr: '0.625rem'
  }
} as const
