import { useMemo, useEffect } from 'react'
import Box from '@mui/material/Box'
import Checkbox from './Checkbox'
import NoRowsOverlay from './NoRowsOverlay'
import { DataGrid, gridClasses, useGridApiContext } from '@mui/x-data-grid'
import { Colors } from '../../Utils/theme'

const Table = (props: any) => {
  /*
  const gridApiRef = useGridApiContext()

  useEffect(() => {
    if (gridApiRef?.current && props.updateGridApiRef) {
      props.updateGridApiRef(gridApiRef.current)
    }
  }, [gridApiRef])
  */

  const columns = useMemo(() => props.columns, [props.columns])
  const rows = useMemo(() => props.rows, [props.rows])
  const getRowClassName = (params: any) => {
    if (props?.fields && !params.row?.tracks) {
      return 'field-track-row'
    }
    return ''
  }

  return (
    <Box sx={{...styles.tableContainer, ...(props.sx ?? {})}}>
      <DataGrid
        apiRef={props.apiRef ?? null}
        columns={columns}
        rows={rows}
        sx={styles.table}
        columnHeaderHeight={64}
        rowHeight={64}
        getRowClassName={getRowClassName}
        slots={{
          baseCheckbox: Checkbox,
          noRowsOverlay: NoRowsOverlay
        }}
        getRowId={props.getRowId}
        checkboxSelection={!!props.checkboxSelection}
        autoHeight={!props.loading && !rows?.length}
        columnVisibilityModel={props.columnVisibilityModel ?? undefined}
        hideFooter
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default Table

const styles = {
  tableContainer: {
    width: '100%',
    maxHeight: {
      lg: 'calc(100vh - 10rem - 5rem - 3.125rem)',
      xs: 'calc(100vh - 8.5rem - 5rem - 3.125rem)'
    },
    backgroundColor: Colors.white,
    boxShadow: 0
  },
  table: {
    boxShadow: 0,
    '& .MuiDataGrid-cell': {
      fontSize: '1rem',
      color: Colors.heading60,
      pl: '1rem'
    },
    '& .MuiDataGrid-cell:hover': {
    },
    '& .pdg-table-column--header': {
      backgroundColor: Colors.text,
      borderTopLeftRadius: 0
    },
    '& .pdg-table--header': {
      backgroundColor: Colors.brandBlue,
      color: Colors.text,
      borderLeft: `1px solid ${Colors.border}`,
      pl: '1rem'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.125rem',
      color: Colors.white,
      textTransform: 'uppercase'
    },
    '& .pdg-table--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .pdg-table-column--header > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
    },
    '.MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
    },
    '.MuiDataGrid-sortIcon': {
      color: Colors.white,
      opacity: 'inherit !important',
    },
    '--DataGrid-overlayHeight': {
      height: '10rem'
    },
    '& .field-track-row': {
      backgroundColor: Colors.brandSecondary10
    }
  }
}
