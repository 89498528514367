import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'

export default function CustomMap(props: any) {
  const { location } = props
  
  if (!location) {
    return null
  }

  const getMapStyles = () => {
    let mapStyles: any = styles.map
    if (props.sx) {
      mapStyles = { ...mapStyles, ...props.sx }
    }
    if (props?.width) {
      mapStyles.width = props.width
    }
    if (props?.height) {
      mapStyles.height = props.height
    }
    if (props?.mb) {
      mapStyles.marginBottom = props.mb
    }
    if (props?.mt) {
      mapStyles.marginTop = props.mt
    }
    return mapStyles
  }

  return (
    <APIProvider apiKey='AIzaSyCIpjCpFyCflW8fRT2zNsDpn34E79G9ZN8'>
      <Map defaultCenter={location} defaultZoom={10} style={getMapStyles()}>
        <Marker position={location} />
      </Map>
    </APIProvider>
  )
}

const styles = {
  map: {
    width: '100%',
    height: '20rem',
    position: 'relative',
    borderRadius: '0.625rem',
    overflow: 'hidden',
    marginBottom: '1.25rem'
  }
}