import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import SlotPlusDarkIcon from '../../Assets/Icons/slot-plus-dark.svg'
import SlotPlusWhiteIcon from '../../Assets/Icons/slot-plus-white.svg'
import { Colors } from '../../Utils/theme'

const CalendarPlusButton = React.forwardRef((props: any, ref: any) => {
  const { dark, onClick } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }
    if (dark) buttonStyles = { ...styles.buttonDark }
    return buttonStyles
  }

  const renderContent = () => {
    return (
      <Box
        component='img'
        src={dark ? SlotPlusWhiteIcon : SlotPlusDarkIcon}
        alt='icon'
        sx={styles.icon}
      />
    )
  }

  return (
    <ButtonBase
      sx={getStyles()}
      onClick={onClick}
      ref={ref ?? null}
    >
      {renderContent()}
    </ButtonBase>
  )
})

export default CalendarPlusButton

const styles = {
  button: {
    userSelect: 'none',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: Colors.white,
    height: '1.825rem',
    width: '1.825rem',
    borderRadius: '1rem',
    ':hover': {
      background: Colors.white90
    }
  },
  buttonDark: {
    userSelect: 'none',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: Colors.brandPrimary,
    height: '1.825rem',
    width: '1.825rem',
    borderRadius: '1rem',
    ':hover': {
      background: Colors.brandPrimary80
    }
  },
  icon: {
    width: '1.3125rem',
    height: 'auto',
    userSelect: 'none'
  }
} as const
