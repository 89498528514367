import { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import logo from '../../Assets/Icons/logo.svg'
import ArrowDown from '../../Assets/Icons/arrow-down.svg'
import Link from '@mui/material/Link'
import { Colors } from '../../Utils/theme'

const Sidebar = (props: any) => {
  const { t } = useTranslation()
  const currentLocation = useLocation()

  const [openedSubMenus, setOpenedSubMenus] = useState<string[]>([])

  const toggleSubMenu = (parent: any) => {
    let newOpenedSubMenus = openedSubMenus.filter(item => item !== parent)
    if (newOpenedSubMenus.length === openedSubMenus.length) {
      newOpenedSubMenus = [...newOpenedSubMenus, parent]
    }
    setOpenedSubMenus(newOpenedSubMenus)
  }

  const renderItemIcon = (icon: any) => {
    if (icon) {
      return (
        <ListItemIcon sx={{ root: styles.icon }}>
          <Box
            component='img'
            src={icon}
            sx={styles.itemIcon}
            alt='icon'
          />
        </ListItemIcon>
      )
    }
    return null
  }

  const renderItemText = (text: any, isActive: boolean) => {
    return (
      <ListItemText
        primary={t(text)}
        sx={{ primary: isActive ? styles.activeItemText : styles.itemText }}
      />
    )
  }

  const renderSubItemText = (text: any) => {
    return <ListItemText primary={t(text)} sx={{ primary: styles.subItemText }} />
  }

  /*
  const handleListItemClick = () => {
    if (props?.open) {
      props.toggleDrawer()
    }
  }
  */

  const renderChildItems = (item: any) => {
    if (item.children) {
      return (
        <Collapse in={openedSubMenus.includes(item.url)} timeout='auto' unmountOnExit>
          {item.children.map((child: any) => {
            return (
              <ListItemButton key={child.url} title={t(item.text)} disableGutters>
                <Link key={child.url} href={child.url} sx={styles.subLink}>
                  {renderSubItemText(child.text)}
                </Link>
              </ListItemButton>
            )
          })}
        </Collapse>
      )
    }
    return null
  }

  const renderItems = (items: any) => {
    return items.map((item: any) => {
      const isActive = currentLocation.pathname === item.url
      if (item.children) {
        return (
          <Fragment key={item.url}>
            <ListItemButton sx={isActive ? styles.activeItem : {}} title={t(item.text)} disableGutters>
              <Link href={item.url} sx={styles.link}>
                {renderItemIcon(item.icon)}
                {renderItemText(item.text, isActive)}
              </Link>
              <Box
                component='img'
                src={ArrowDown}
                sx={styles.arrowDown}
                onClick={() => toggleSubMenu(item.url)}
              />
            </ListItemButton>
            {renderChildItems(item)}
          </Fragment>
        )
      }

      return (
        <ListItemButton
          key={item.url}
          sx={isActive ? styles.activeItem : {}}
          title={t(item.text)}
          disableGutters
        >
          <Link href={item.url} sx={styles.link}>
            {renderItemIcon(item.icon)}
            {renderItemText(item.text, isActive)}
          </Link>
        </ListItemButton>
      )
    })
  }

  const renderContent = () => {
    return (
      <Box sx={props?.permanentDrawer ? styles.drawerInner : styles.drawerInnerMobile}>
        <Box sx={styles.logoContainer}>
          <Box component='img' src={logo} alt='logo' sx={styles.logo} />
        </Box>
        <List sx={styles.primaryItemsList} disablePadding>
          {renderItems(props.primaryNavigationItems)}
        </List>
        <Box sx={styles.flex} />
        {/*
          <List sx={styles.secondaryItemsList} disablePadding>
            {renderItems(props.secondaryNavigationItems)}
          </List>
        */}
      </Box>
    )
  }

  if (props?.permanentDrawer) {
    return (
      <Drawer variant='permanent' sx={styles.drawer}>
        {renderContent()}
      </Drawer>
    )
  } else {
    return (
      <Drawer
        variant='temporary'
        open={props.open}
        onClose={props.toggleDrawer}
        ModalProps={{ keepMounted: true }}
        sx={styles.drawer}
      >
        {renderContent()}
      </Drawer>
    )
  }
}

export default Sidebar

const styles = {
  drawer: {
    height: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 1203
  },
  drawerInner: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    minWidth: {
      xxxl: '20rem',
      xs: '18rem'
    },
    background: Colors.brandPrimary,
    pl: '1rem',
    pr: '1rem'
  },
  drawerInnerMobile: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: '100vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    background: Colors.brandPrimary,
    pl: '1rem',
    pr: '1rem'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: {
      xxxl: '11rem',
      xxl: '9rem',
      xs: '7rem'
    }
  },
  logo: {
    width: {
      xxxl: '7rem',
      xxl: '6rem',
      xs: '5rem'
    },
    height: 'auto'
  },
  flex: {
    flex: 1
  },
  primaryItemsList: {
    borderTop: `1px solid ${Colors.white20}`,
    pt: {
      xxl: '2rem',
      xs: '1.5rem'
    }
  },
  secondaryItemsList: {
    borderTop: `1px solid ${Colors.white20}`,
    pt: {
      xxl: '2rem',
      xs: '1.5rem'
    },
    pb: {
      xxl: '2rem',
      xs: '1.5rem'
    }
  },
  listItem: {
    position: 'relative',
    height: '2.5rem'
  },
  subLink: {
    width: '100%',
    height: '1.75rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.75rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  link: {
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkClosed: {
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: '1.875rem',
    minWidth: 1
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontWeight: 500,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  activeItemText: {
    fontWeight: 700,
    color: Colors.white,
    fontSize: '1rem',
    marginLeft: '2rem'
  },
  subItemText: {
    fontSize: '0.875rem'
  },
  itemIcon: {
    marginLeft: '1.25rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '1.5rem'
  },
  arrowDown: {
    position: 'absolute',
    right: '1rem',
    height: '1rem'
  },
  activeItem: {
    backgroundColor: Colors.white10,
    '& .MuiListItemText-primary': {
      fontWeight: 700
    }
  }
} as const
