import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import ButtonBase from '@mui/material/ButtonBase'
import Checkbox from '../../../Components/Common/Checkbox'
import FeedbackStars from '../../../Components/Common/FeedbackStars'
import { Button, Input } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const TrackFeedback = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { sessionStore, organizationStore, feedbackStore }: any = useStore()
  const { user } = sessionStore
  const { organization } = organizationStore
  const { trackFeedback } = feedbackStore

  const [response, setResponse] = useState('')
  const [sendNotification, setSendNotification] = useState(true)

  const toggleSendNotification = () => setSendNotification(!sendNotification)

  useEffect(() => {
    const { trackId, trackFeedbackId } = params
    const isAdmin = ['superadmin', 'admin'].includes(user?.role)
    if (isAdmin) {
      if (trackFeedbackId) {
        feedbackStore.getTrackFeedback(Number(trackFeedbackId))
      }
    } else if (trackId && trackFeedbackId) {
      feedbackStore.getOrganizationTrackFeedback(
        Number(user?.organizationId),
        Number(trackId),
        Number(trackFeedbackId)
      )
    }
  }, [])

  useEffect(() => {
    if (trackFeedback) {
      setResponse(trackFeedback?.responses?.[0]?.message || '')
    }
  }, [trackFeedback])

  const publish = () => {
    if (trackFeedback && response) {
      const isAdmin = ['superadmin', 'admin'].includes(user?.role)
      if (isAdmin) {
        feedbackStore.publishAdminTrackFeedbackResponse(
          trackFeedback.id,
          { message: response, sendNotification }
        )
      } else {
        feedbackStore.publishTrackFeedbackResponse(
          user.organizationId,
          trackFeedback.trackId,
          trackFeedback.id,
          { message: response, sendNotification }
        )
      }
    }
  }
  const save = () => {
    if (trackFeedback && response) {
      const isAdmin = ['superadmin', 'admin'].includes(user?.role)
      if (isAdmin) {
        feedbackStore.updateAdminTrackFeedbackResponse(
          trackFeedback.id,
          { message: response, sendNotification }
        )
      } else {
        feedbackStore.updateTrackFeedbackResponse(
          user.organizationId,
          trackFeedback.trackId,
          trackFeedback.id,
          { message: response, sendNotification }
        )
      }
    }
  }

  const cancel = () => navigate(-1)

  const renderFeedback = () => {
    const datetime = DateTime.fromISO(trackFeedback?.createdAt)
    const timeFormatted = `${datetime.toFormat('dd.MM.yyyy')} ${t('at')} ${datetime.toFormat('HH.mm')}`

    return (
      <Box sx={styles.feedbackContainer}>
        <Box sx={styles.row}>
          <FeedbackStars value={trackFeedback?.rating} />
          <Typography sx={styles.date}>{timeFormatted}</Typography>
        </Box>
        <Typography sx={styles.message}>{trackFeedback?.feedback || ''}</Typography>
        <Box>
          <Chip
            label={`${trackFeedback?.mobileUser?.firstName || ''} ${trackFeedback?.mobileUser?.lastName || ''}`}
            variant='outlined'
            size='small'
            sx={styles.chip}
          />
        </Box>
      </Box>
    )
  }

  const renderActionButton = () => {
    if (!trackFeedback?.responses?.length) {
      return (
        <Button
          text={t('publish')}
          onClick={publish}
          disabled={!response}
          width='100%'
          mb='0.75rem'
        />
      )
    } else {
      return (
        <Button
          text={t('save')}
          onClick={save}
          disabled={!response || response === trackFeedback?.responses?.[0]?.message}
          width='100%'
          mb='0.75rem'
        />
      )
    }
  }

  if (!trackFeedback) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('feedback')}</Typography>
        {renderFeedback()}
        <Typography variant='h3' mt='2.5rem' mb='1.5rem'>
          {t('respond')}
        </Typography>
        <Box sx={styles.feedbackContainer}>
          <Input
            label={t('content')}
            value={response}
            onChange={setResponse}
            multiline
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Box sx={styles.checkboxContainer}>
            <Checkbox
              checked={sendNotification}
              onChange={toggleSendNotification}
            />
            <ButtonBase
              onClick={toggleSendNotification}
              sx={styles.checkboxTextButton}
              disableRipple
            >
              <Typography variant='body1' fontSize='1rem' fontWeight={600}>
                {t('feedback_send_notification')}
              </Typography>
            </ButtonBase>
          </Box>
          {renderActionButton()}
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(TrackFeedback)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    // height: '100vh',
    overflowY: 'auto'
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 2rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.25rem'
  },
  date: {
    color: Colors.text60
  },
  message: {
    fontSize: '1.125rem',
    color: Colors.text60
  },
  chip: {
    height: '2rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.white,
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.625rem',
    border: 0,
    padding: '0rem 0.375rem',
    mt: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    padding: '0.5rem 0.5rem',
    mb: '1.5rem'
  },
  checkboxTextButton: {
    pl: '0.25rem',
    cursor: 'pointer'
  }
} as const
