import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import { Colors } from '../../Utils/theme'

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '3.25rem',
  height: '1.875rem',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '1.375rem'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0.563rem)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.25rem',
    '&.Mui-checked': {
      transform: 'translateX(1.25rem)',
      color: Colors.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: Colors.cardBackground
      },
      '& .MuiSwitch-thumb': {
        color: Colors.brandPrimary,
        width: '1.375rem',
        height: '1.375rem',
        borderRadius: '50%',
      },
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    color: Colors.text,
    width: '1.375rem',
    height: '1.375rem',
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: '0.938rem',
    opacity: 1,
    backgroundColor: Colors.checkboxBg,
    border: `2px solid ${Colors.border}`,
    boxSizing: 'border-box'
  }
}))
