import { useEffect, useState } from 'react'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, Select, Input } from '..'
import ConfirmationDialog from '../Common/ConfirmationDialog'
import LangSelect from '../Common/LangSelect'
import TimeInput from '../Common/TimeInput'
import { validateExceptionSlotInput } from '../../Utils/validation'
import { Colors } from '../../Utils/theme'

export default function ExceptionModal(props: any) {
  const { timezone, onClose, type, loading } = props

  const [descriptionFi, setDescriptionFi] = useState('')
  const [descriptionEn, setDescriptionEn] = useState('')
  const [descriptionSv, setDescriptionSv] = useState('')
  const [startTime, setStartTime] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [dayNum, setDayNum] = useState<any>(null)
  const [playerCount, setPlayerCount] = useState<any>(null)
  const [billNumber, setBillNumber] = useState<any>(null)
  const [lang, setLang] = useState<string>('fi')
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
  
  const closeDeleteConfirmation = () => setConfirmDeleteVisible(false)

  useEffect(() => {
    if (props?.open) {
      // Copy values
      if (!props.selectedExceptionSlot) {
        const newStartTime = DateTime.fromISO(props.startTime).setZone(timezone).toFormat('HH:mm')
        const newEndTime = DateTime.fromISO(props.endTime).setZone(timezone).toFormat('HH:mm')
        const newStartDate = DateTime.fromISO(props.startTime).setZone(timezone).toFormat('yyyy-MM-dd')
        const newEndDate = DateTime.fromISO(props.endTime).setZone(timezone).toFormat('yyyy-MM-dd')
        const newDayNum = DateTime.fromISO(props.startTime).setZone(timezone).weekday

        setStartTime(newStartTime)
        setEndTime(newEndTime)
        setStartDate(newStartDate)
        setEndDate(newEndDate)
        setDayNum(newDayNum)
      } else {
        // We have premade exception slot
        const newStartDate = DateTime
          .fromISO(props.selectedExceptionSlot.startDate)
          .setZone(timezone)
          .toFormat('yyyy-MM-dd')
        const newEndDate = DateTime
          .fromISO(props.selectedExceptionSlot.endDate)
          .setZone(timezone)
          .toFormat('yyyy-MM-dd')
        const newDayNum = props.selectedExceptionSlot.dayNum || 0

        setStartTime(props.selectedExceptionSlot.startTime)
        setEndTime(props.selectedExceptionSlot.endTime)
        setStartDate(newStartDate)
        setEndDate(newEndDate)
        setDayNum(newDayNum)
        setDescriptionFi(props.selectedExceptionSlot.descriptionFi)
        setDescriptionEn(props.selectedExceptionSlot.descriptionEn)
        setDescriptionSv(props.selectedExceptionSlot.descriptionSv)
        setPlayerCount(props.selectedExceptionSlot?.playerCount || null)
        setBillNumber(props.selectedExceptionSlot?.billNumber || null)
      }
    } else {
      // Unset values
      setDescriptionFi('')
      setDescriptionEn('')
      setDescriptionSv('')
      setStartTime('')
      setEndTime('')
      setStartDate('')
      setEndDate('')
      setDayNum(null)
      setPlayerCount(null)
      setBillNumber(null)
    }
  }, [props.open])

  const handleSetStartTime = (value: any) => setStartTime(value)
  const handleSetEndTime = (value: any) => setEndTime(value)
  const handleSetStartDate = (value: any) => setStartDate(value)
  const handleSetEndDate = (value: any) => setEndDate(value)

  const onSave = () => {
    const startDateParts = startDate.split('-')
    const endDateParts = endDate.split('-')

    const newStartDate = DateTime.fromObject({
      year: parseInt(startDateParts[0], 10),
      month: parseInt(startDateParts[1], 10),
      day: parseInt(startDateParts[2], 10),
      hour: 0,
      minute: 0,
      second: 0
    }, {
      zone: timezone
    }).startOf('day').toUTC().toJSDate()

    const newEndDate = DateTime.fromObject({
      year: parseInt(endDateParts[0], 10),
      month: parseInt(endDateParts[1], 10),
      day: parseInt(endDateParts[2], 10),
      hour: 0,
      minute: 0,
      second: 0
    }, {
      zone: timezone
    }).endOf('day').toUTC().toJSDate()

    const newExceptionSlot = {
      type: type || 'exception_slot',
      startDate: newStartDate,
      endDate: newEndDate,
      startTime,
      endTime,
      descriptionFi,
      descriptionEn,
      descriptionSv,
      dayNum: dayNum || null,
      playerCount: (type && type === 'system_reservation' && playerCount) || null,
      billNumber: (type && type === 'system_reservation' && billNumber) || null
    }

    if (props.selectedExceptionSlot) {
      // Edit existing
      props.onEdit(props.selectedExceptionSlot.id, newExceptionSlot)
    } else {
      // Create new
      props.onCreate(newExceptionSlot)
    }
  }

  const onDelete = () => {
    if (!confirmDeleteVisible) {
      setConfirmDeleteVisible(true)
    } else {
      props.onDelete(props.selectedExceptionSlot.id)
      setConfirmDeleteVisible(false)
    }
  }

  const getWeekdayOptions = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7].map((value: any) => {
      if (!value) {
        return {
          label: t('on_all_days'),
          value: 0
        }
      }
      return {
        label: t(`weekday_${value}`),
        value
      }
    })
  }

  const getTitle = () => {
    if (!props.selectedExceptionSlot) {
      if (type && type === 'system_reservation') {
        return t('add_reservation')
      }
      return t('add_exception_slot')
    }
    if (type && type === 'system_reservation') {
      return t('edit_reservation')
    }
    return t('edit_exception_slot')
  }

  const getPlayerCountOptions = () => {
    return Array.from({ length: 20 }, (_, i) => ({ label: `${i + 1}`, value: i + 1 }))
  }

  const isSaveDisabled = () => {
    if (loading) {
      return true
    }

    if (!dayNum && dayNum !== 0) {
      return true
    }

    if (!descriptionFi) {
      return true
    }

    if (type === 'system_reservation' && !playerCount) {
      return true
    }

    const isValid = validateExceptionSlotInput({
      startTime,
      endTime,
      startDate,
      endDate,
      descriptionFi,
      descriptionEn,
      descriptionSv
    })
    return !isValid
  }

  const renderDeleteButton = () => {
    if (props.selectedExceptionSlot) {
      /*
      if (type && type === 'system_reservation') {
        const now = DateTime.now().setZone(timezone)
        const startDate = DateTime.fromISO(props.selectedExceptionSlot?.startDate).setZone(timezone)
        if (now > startDate) {
          return null
        }
      }
      */
      return (
        <Button
          text={t('delete')}
          onClick={onDelete}
          variant='secondary'
          mr='1rem'
        />
      )
    }
    return null
  }

  const renderWeekdaySelection = () => {
    if (type && type === 'system_reservation') {
      return null
    }
    return (
      <Select
        label={t('weekday')}
        options={getWeekdayOptions()}
        value={dayNum}
        onChange={setDayNum}
        maxWidth='14rem'
      />
    )
  }

  const renderPlayerCountSelection = () => {
    if (type && type === 'system_reservation') {
      return (
        <Select
          label={t('player_count')}
          options={getPlayerCountOptions()}
          value={playerCount}
          onChange={setPlayerCount}
          maxWidth='11rem'
        />
      )
    }
    return null
  }

  const renderBillNumber = () => {
    if (type && type === 'system_reservation') {
      return (
        <Input
          label={t('bill_number')}
          value={billNumber}
          onChange={setBillNumber}
          ml='2rem'
        />
      )
    }
    return null
  }

  const renderButtons = () => {
    return (
      <Box sx={styles.footerRow}>
        <Button
          text={t('cancel')}
          onClick={onClose}
          variant='secondary'
          width='10rem'
        />
        <Box sx={styles.flex} />
        {renderDeleteButton()}
        <Button
          text={t('save')}
          onClick={onSave}
          disabled={isSaveDisabled()}
          loading={loading}
          width='10rem'
        />
      </Box>
    )
  }

  const renderConfirmDelete = () => {
    if (confirmDeleteVisible) {
      return (
        <ConfirmationDialog
          title={type && type === 'system_reservation' ? t('confirm_delete_system_reservation_title') : t('confirm_delete_exception_slot_title')}
          content={type && type === 'system_reservation' ? t('confirm_delete_system_reservation_content') : t('confirm_delete_exception_slot_content')}
          onClose={closeDeleteConfirmation}
          cancelText={t('cancel')}
          onAction={onDelete}
          actionText={t('delete')}
        />
      )
    }
    return null
  }

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={props?.open}>
      <DialogTitle variant='h2' sx={styles.title}>{getTitle()}</DialogTitle>
      <Box sx={styles.container}>
        <Box sx={styles.langSelectContainer}>
          <LangSelect
            value={lang}
            onChange={setLang}
            status={{
              fi: descriptionFi,
              en: descriptionEn,
              sv: descriptionSv
            }}
          />
        </Box>
        <Input
          label={t('description')}
          value={lang === 'fi' ? descriptionFi : lang === 'en' ? descriptionEn : descriptionSv}
          onChange={lang === 'fi' ? setDescriptionFi : lang === 'en' ? setDescriptionEn : setDescriptionSv}
          mb='1.5rem'
          multiline
        />
        <Box sx={styles.row}>
          <Input
            label={t('date_range')}
            value={startDate}
            onChange={handleSetStartDate}
            width='12rem'
            type='date'
          />
          <Box sx={styles.spacer}>-</Box>
          <Input
            mt='1.75rem'
            value={endDate}
            onChange={handleSetEndDate}
            width='12rem'
            type='date'
          />
        </Box>
        <Box sx={styles.row}>
          <TimeInput
            label={t('time_range')}
            value={startTime}
            onChange={handleSetStartTime}
            width='5.625rem'
          />
          <Box sx={styles.spacer}>-</Box>
          <TimeInput
            mt='1.75rem'
            value={endTime}
            onChange={handleSetEndTime}
            width='5.625rem'
          />
        </Box>
        <Box sx={styles.row}>
          {renderWeekdaySelection()}
          {renderPlayerCountSelection()}
          {renderBillNumber()}
        </Box>
      </Box>
      {renderButtons()}
      {renderConfirmDelete()}
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '40rem',
      borderRadius: '0.625rem',
      padding: '1rem 2rem 2rem 2rem'
    }
  },
  title: {
    textAlign: 'center',
    mb: '0.5rem'
  },
  container: {
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    padding: '1rem 2rem',
    mb: '0.5rem'
  },
  box: {
    padding: '1rem',
    border: `1px solid ${Colors.darkBorder}`,
    borderRadius: '.25rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem'
  },
  spacer: {
    marginTop: '2.5rem',
    padding: '0 1rem',
    fontWeight: 700
  },
  footerRow: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  flex: {
    flex: 1
  },
  langSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
} as const
