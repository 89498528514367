import Box from '@mui/material/Box'
import StarFilledIcon from '../../Assets/Icons/star-filled.svg'
import StarEmptyIcon from '../../Assets/Icons/star-empty.svg'

const FeedbackStars = (props: any) => {
  const { value } = props

  const renderStars = () => {
    return [1, 2, 3, 4, 5].map((stars: number) => {
      if (value >= stars) {
        return <Box component='img' key={stars} src={StarFilledIcon} sx={styles.star} />
      }
      return <Box component='img' key={stars} src={StarEmptyIcon} sx={styles.star} />
    })
  }

  return <Box sx={styles.container}>{renderStars()}</Box>
}

export default FeedbackStars

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem'
  },
  star: {
    height: '1.5rem'
  }
}
