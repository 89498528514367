import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { Colors } from '../../Utils/theme'

export default function SlotParticipant(props: any) {
  const { photo, name, phone } = props

  const renderPhoto = () => {
    return <Avatar sx={styles.avatar} src={photo} alt='photo' />
  }

  return (
    <Box sx={styles.container}>
      {renderPhoto()}
      <Box sx={styles.column}>
        <Typography sx={styles.name}>{name || '-'}</Typography>
        <Typography sx={styles.phone}>{phone || '-'}</Typography>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    padding: '.75rem .5rem',
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${Colors.border}`,
    borderRadius: '.5rem'
  },
  avatar: {
    width: '3rem',
    height: '3rem',
    marginRight: '1rem'
  },
  name: {
    fontSize: '1rem',
    fontWeight: 700
  },
  phone: {
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.heading60
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
} as const
