import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import SquareButton from '../../../Components/Common/SquareButton'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import CreateSeasonPassModal from '../../../Components/Common/CreateSeasonPassModal'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import CopyIcon from '../../../Assets/Icons/copy.svg'
import DeleteIcon from '../../../Assets/Icons/trash.svg'
import { Colors } from '../../../Utils/theme'
import { showMessage } from '../../../Utils/message'

const SeasonPasses = (props: any) => {
  const { sessionStore, fieldStore, proShopStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading } = proShopStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [seasonPass, setSeasonPass] = useState<any>(null)
  const [showCreateSeasonPassModal, setShowCreateSeasonPassModal] = useState(false)
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)
  const openCreateSeasonPassModal = () => setShowCreateSeasonPassModal(true)
  const closeCreateSeasonPassModal = () => setShowCreateSeasonPassModal(false)
  const openArchiveConfirmation = (seasonPass: any) => {
    setShowArchiveConfirmation(true)
    setSeasonPass(seasonPass)
  }
  const closeArchiveConfirmation = () => {
    setSeasonPass(null)
    setShowArchiveConfirmation(false)
  }

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
      proShopStore.getSeasonPasses()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
      proShopStore.getOrganizationSeasonPasses(sessionStore.user?.organizationId)
    }
  }, [])

  const getAvailableSeasons = (organizationId: number, fieldId: number) => {
    fieldStore.getOrganizationField(
      Number(organizationId),
      Number(fieldId)
    )
  }

  const copyToClipboard = (code: string) => () => {
    navigator.clipboard.writeText(code)
    showMessage(t('copied_to_clipboard'))
  }

  const toField = (seasonPass: any) => {
    navigate(`/organizations/${seasonPass.organizationId}/fields/${seasonPass.fieldId}`)
  }

  const createSeasonPasses = (organizationId: number, fieldId: number, data: any) => {
    proShopStore.createSeasonPasses(
      organizationId,
      fieldId,
      data,
      closeCreateSeasonPassModal
    )
  }

  const archiveSeasonPassCode = () => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      proShopStore.archiveSeasonPass(
        seasonPass.field.organizationId,
        seasonPass.fieldId,
        seasonPass.id,
        closeArchiveConfirmation
      )
    } else {
      proShopStore.archiveSeasonPass(
        seasonPass.field.organizationId,
        seasonPass.fieldId,
        seasonPass.id,
        closeArchiveConfirmation
      )
    }
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'code',
      headerName: t('code'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={`${params.row.code.slice(0, 6)}...${params.row.code.slice(-6)}`}
            onClick={copyToClipboard(params.row.code)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'fieldSeasonId',
      headerName: t('season'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        const season = params?.row?.fieldSeason
        if (!season) {
          return '-'
        }
        return season?.nameFi || '-'
        /*
        // If years are different, show both years
        if (DateTime.fromISO(season.startTime).year !== DateTime.fromISO(season.endTime).year) {
          return `${season.nameFi} (${DateTime.fromISO(season.startTime).toFormat('dd.MM.yyyy')}-${DateTime.fromISO(season.endTime).toFormat('dd.MM.yyyy')})`
        } else {
          return `${season.nameFi} (${DateTime.fromISO(season.startTime).toFormat('dd.MM.')}-${DateTime.fromISO(season.endTime).toFormat('dd.MM.yyyy')})`
        }
        */
      }
    },
    {
      field: 'field',
      headerName: t('field'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.field?.nameFi || ''}
            onClick={() => toField(params.row)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'holderMobileUser',
      headerName: t('user'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return `${params.value?.firstName || ''} ${params.value?.lastName || ''}`.trim()
        }
        return '-'
      }
    },
    {
      field: 'status',
      headerName: t('state'),
      flex: 1,
      minWidth: 150,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        let text = params?.row?.activatedAt ?
          t('used') :
          params?.row?.archivedAt ?
          t('archived') :
          t('unused')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 250,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box sx={styles.actions}>
            <SquareButton
              icon={CopyIcon}
              onClick={copyToClipboard(params.row.code)}
              mr='1rem'
              blue
            />
            {(params.row?.archivedAt || params.row?.activatedAt) ? null : (
              <SquareButton
                icon={DeleteIcon}
                onClick={() => openArchiveConfirmation(params.row)}
                grey
              />
            )}
          </Box>
        )
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let seasonPasses = proShopStore.seasonPasses
    if (searchKey) {
      const search = searchKey.toLowerCase()
      seasonPasses = seasonPasses.filter((seasonPass: any) => seasonPass?.code?.toLowerCase().includes(search))
    }
    return seasonPasses
  }, [proShopStore.seasonPasses, searchKey, filters])

  const renderCreateSeasonPassModal = () => {
    if (showCreateSeasonPassModal) {
      return (
        <CreateSeasonPassModal
          onClose={closeCreateSeasonPassModal}
          onCreate={createSeasonPasses}
          fields={fieldStore.fields}
          seasons={fieldStore.field?.seasons || []}
          getSeasons={getAvailableSeasons}
        />
      )
    }
    return null
  }

  const renderArchiveConfirmation = () => {
    if (showArchiveConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_season_pass_code')}
          content={t('archive_season_pass_code_confirmation')}
          onClose={closeArchiveConfirmation}
          onAction={archiveSeasonPassCode}
          actionText={t('archive')}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={openCreateSeasonPassModal}
        addText={t('create_new')}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderCreateSeasonPassModal()}
      {renderArchiveConfirmation()}
    </Box>
  )
}

export default observer(SeasonPasses)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
