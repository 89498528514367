import { t } from 'i18next'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SlotParticipant from './SlotParticipant'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Select } from '..'
import { Colors } from '../../Utils/theme'

export default function SlotParticipantsModal(props: any) {
  const { timezone, slot, onClose } = props

  const getSlotTime = () => {
    if (!slot) return null
    const slotMoment = DateTime.fromISO(slot.startTime).setZone(timezone)
    const slotTime = `${t(`weekday_short_${slotMoment.weekday}`)} ${slotMoment.toFormat('dd.MM.yyyy')} ${t('at')} ${slotMoment.toFormat('HH.mm')}`
    return slotTime
  }

  const renderParticipants = () => {
    return (
      <Box sx={styles.participantsContainer}>
        {slot.trackSlot.trackSlotSeats.map((seat: any) => {
          return (
            <SlotParticipant
              photo={seat?.photo}
              name={seat?.name}
              phone={seat?.phone}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <DialogTitle variant='h2' sx={styles.title}>
        {t('reservation')}
      </DialogTitle>
      <Typography variant='body1' fontSize='1.125rem' color={Colors.text60} textAlign='center' mb='1.5rem'>
        {getSlotTime()}
      </Typography>
      <DialogContent sx={styles.content}>
        <Box sx={styles.row}>
          <Typography variant='h4'>
            {t('participants')} {slot?.trackSlot?.trackSlotSeats?.length} / {slot?.slotSeats}
          </Typography>
        </Box>
        {slot.trackSlot.trackSlotSeats?.length && renderParticipants()}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.button}
          text={t('close')}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '40rem',
      width: '40rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem',
    marginBottom: '0.5rem'
  },
  icon: {
    height: '2rem'
  },
  title: {
    color: Colors.heading,
    alignSelf: 'center',
    textAlign: 'center'
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // minHeight: '15.5rem',
    maxHeight: '70vh',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    p: '1.5rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  label: {
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '1rem'
  },
  value: {
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 400
  },
  hr: {
    borderBottom: `1px solid ${Colors.border}`,
    marginTop: '0.5rem',
    marginBottom: '1rem'
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    width: '11rem',
    fontSize: '1.125rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.25rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    border: 0,
    mt: '1rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  participantsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem'
  },
  slotTime: {
    fontWeight: 300,
    fontSize: '1.75rem',
    marginBottom: '1rem'
  }
} as const
