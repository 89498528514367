import { useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { useDropzone } from 'react-dropzone'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'

const Profile = (props: any) => {
  const { sessionStore }: any = useStore()
  const { user } = sessionStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    setFirstName(user?.firstName || '')
    setLastName(user?.lastName || '')
    setEmail(user?.email || '')
    setPhone(user?.phone || '')
  }, [user])

  const onUploadProfilePhoto = useCallback((files: any) => {
    if (files && files?.length) {
      const file = files?.[0]
      sessionStore.updateMyProfilePhoto(file)
    }
  }, [])

  const onDeleteProfilePhoto = () => sessionStore.deleteMyProfilePhoto()

  const profileDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png']
    },
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: onUploadProfilePhoto
  })

  const save = () => {
    sessionStore.updateMe({
      firstName,
      lastName,
      phone
    })
  }

  const logout = () => sessionStore.logout()

  const renderPhotoActions = () => {
    if (user?.photo) {
      return (
        <Collapse in={user?.photo} unmountOnExit>
          <Button
            text={t('delete_photo')}
            onClick={onDeleteProfilePhoto}
            width='11rem'
            mb='1.5rem'
          />
          <Box {...profileDrop.getRootProps()}>
            <input {...profileDrop.getInputProps()} />
            <Button
              text={t('change_photo')}
              onClick={profileDrop.open}
              width='11rem'
              variant='secondary'
            />
          </Box>
        </Collapse>
      )
    }
    return (
      <Box {...profileDrop.getRootProps()}>
        <input {...profileDrop.getInputProps()} />
        <Button text={t('add_photo')} onClick={profileDrop.open} width='11rem' />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Box sx={styles.photoContainer}>
          <Avatar src={user?.photo} sx={styles.photo} />
          <Box sx={styles.photoActions}>{renderPhotoActions()}</Box>
        </Box>
        <Typography variant='h2' mb='1rem'>{t('general_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={lastName}
              onChange={setLastName}
            />
          </Box>
          <Input
            label={t('email')}
            value={email}
            onChange={setEmail}
            mb='1.5rem'
            copy
            disabled
          />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            maxWidth='20rem'
            mb='1.5rem'
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('save')} onClick={save} width='100%' mb='0.75rem' />
          <Button text={t('logout')} onClick={logout} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(Profile)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    // height: '100vh',
    overflowY: 'auto',
  },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    mb: '2.5rem'
  },
  photo: {
    width: '12rem',
    height: '12rem',
    marginRight: '2rem'
  },
  photoActions: {
    height: '12rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
