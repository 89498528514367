import apisauce from 'apisauce'

const ApiUrls = {
  Local: `http://127.0.0.1:1337/v1/admin/`,
  Stage: 'https://pdg-api-staging.peanutsgroup.com/v1/admin/',
  Production: 'https://api.disctimes.app/v1/admin/'
}

let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

const create = (baseURL = ApiUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: {},
    timeout: 15000,
    withCredentials: true
  })

  // Public endpoints
  const login = (body: any) => api.post('account/login', body)
  const requestPasswordReset = (body: any) => api.post('account/password-reset', body)
  const getPasswordResetRequest = (code: string) => api.get(`account/password-reset-requests/${code}`)
  const completePasswordReset = (body: any) => api.put('account/password-reset', body)
  const getInvite = (code: string) => api.get(`account/invites/${code}`)
  const verifyAccount = (body: any) => api.put('account/verify', body)

  // Authorization required
  const logout = () => api.put('account/logout')
  const keepAlive = () => api.get('session/keep-alive')
  const getMe = () => api.get('account/me')
  const updateMe = (body: any) => api.put('account/me', body)
  const updateMyProfilePhoto = (body: any) => {
    const formData = new FormData()
    formData.append('file', body)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post('account/me/profile-photo', formData, options)
  }
  const deleteMyProfilePhoto = () => api.delete('account/me/profile-photo')
  const searchForLocation = (location: string) => api.get('utilities/locations/search', { location })

  // Organizations and users
  const getOrganizations = (params?: any) => api.get('organizations', params || {})
  const getOrganization = (id: number) => api.get(`organizations/${id}`)
  const createOrganization = (body: any) => api.post('organizations', body)
  const updateOrganization = (organizationId: number, body: any) => api.put(`organizations/${organizationId}`, body)
  const getAllUsers = (params?: any) => api.get('users', params || {})
  const getOrganizationUsers = (organizationId: number, params?: any) => api.get(`organizations/${organizationId}/users`, params || {})
  const getOrganizationUser = (id: number, userId: number) => api.get(`organizations/${id}/users/${userId}`)
  const getAdminUser = (userId: number) => api.get(`users/${userId}`)
  const updateAdminUser = (userId: number, body: any) => api.put(`users/${userId}`, body)
  const archiveAdminUser = (userId: number) => api.delete(`users/${userId}`)
  const activateAdminUser = (userId: number) => api.put(`users/${userId}/activate`)
  const archiveOrganizationUser = (organizationId: number, userId: number) => api.delete(`organizations/${organizationId}/users/${userId}`)
  const activateOrganizationUser = (organizationId: number, userId: number) => api.put(`organizations/${organizationId}/users/${userId}/activate`)
  const updateOrganizationUser = (organizationId: number, userId: number, body: any) => api.put(`organizations/${organizationId}/users/${userId}`, body)
  const createOrganizationUserInvite = (id: number, body: any) => api.post(`organizations/${id}/users/invites`, body)
  const createAdminUserInvite = (body: any) => api.post('admin-invites', body)
  const removeOrganizationUserInvite = (id: number, inviteId: number) => api.delete(`organizations/${id}/users/invites/${inviteId}`)

  // Fields
  const getAllFields = (params: any) => api.get('fields', params || {})
  const getOrganizationFields = (organizationId: number, params: any) => api.get(`organizations/${organizationId}/fields`, params)
  const getOrganizationField = (organizationId: number, fieldId: number, params: any) => api.get(`organizations/${organizationId}/fields/${fieldId}`, params)
  const createOrganizationField = (organizationId: number, body: any) => api.post(`organizations/${organizationId}/fields`, body)
  const updateOrganizationField = (organizationId: number, fieldId: number, body: any) => api.put(`organizations/${organizationId}/fields/${fieldId}`, body)
  const deleteOrganizationField = (id: number, fieldId: number) => api.delete(`organizations/${id}/fields/${fieldId}`)
  const getOrganizationFieldTracks = (id: number, fieldId: number, params: any) => api.get(`organizations/${id}/fields/${fieldId}/tracks`, params)
  const getOrganizationFieldTrack = (id: number, fieldId: number, trackId: number) => api.get(`organizations/${id}/fields/${fieldId}/tracks/${trackId}`)
  const createOrganizationFieldTrack = (id: number, fieldId: number, body: any) => api.post(`organizations/${id}/fields/${fieldId}/tracks`, body)
  const updateOrganizationFieldTrack = (id: number, fieldId: number, trackId: number, body: any) => api.put(`organizations/${id}/fields/${fieldId}/tracks/${trackId}`, body)
  const deleteOrganizationFieldTrack = (id: number, fieldId: number, trackId: number) => api.delete(`organizations/${id}/fields/${fieldId}/tracks/${trackId}`)
  const updateOrganizationFieldTrackPhoto = (id: number, fieldId: number, trackId: number, file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const options = { headers: { 'Content-Type': 'multipart/form-data' } }
    return api.post(`organizations/${id}/fields/${fieldId}/tracks/${trackId}/photo`, formData, options)
  }
  const deleteOrganizationFieldTrackPhoto = (id: number, fieldId: number, trackId: number) => api.delete(`organizations/${id}/fields/${fieldId}/tracks/${trackId}/photo`)

  // Field seasons
  const createOrganizationFieldSeason = (organizationId: number, fieldId: number, body: any) => api.post(`organizations/${organizationId}/fields/${fieldId}/seasons`, body)
  const updateOrganizationFieldSeason = (organizationId: number, fieldId: number, seasonId: number, body: any) => api.put(`organizations/${organizationId}/fields/${fieldId}/seasons/${seasonId}`, body)
  const archiveOrganizationFieldSeason = (organizationId: number, fieldId: number, seasonId: number) => api.delete(`organizations/${organizationId}/fields/${fieldId}/seasons/${seasonId}`)

  // Track calendar and slots
  const getOrganizationTrackSeasons = (organizationId: number, trackId: number) => api.get(`organizations/${organizationId}/tracks/${trackId}/seasons`)
  const getOrganizationTrackSeason = (organizationId: number, trackId: number, seasonId: number) => api.get(`organizations/${organizationId}/tracks/${trackId}/seasons/${seasonId}`)
  const deleteOrganizationTrackSeason = (organizationId: number, trackId: number, seasonId: number) => api.delete(`organizations/${organizationId}/tracks/${trackId}/seasons/${seasonId}`)
  const createOrganizationTrackSeason = (organizationId: number, trackId: number, body: any) => api.post(`organizations/${organizationId}/tracks/${trackId}/seasons`, body)
  const updateOrganizationTrackSeason = (organizationId: number, trackId: number, seasonId: number, body: any) => api.put(`organizations/${organizationId}/tracks/${trackId}/seasons/${seasonId}`, body)
  const getOrganizationTrackCalendar = (organizationId: number, trackId: number, startTime: string, endTime: string) => api.get(`organizations/${organizationId}/tracks/${trackId}/calendar`, { startTime, endTime })
  const createOrganizationTrackExceptionSlot = (organizationId: number, trackId: number, body: any) => api.post(`organizations/${organizationId}/tracks/${trackId}/exception-slots`, body)
  const updateOrganizationTrackExceptionSlot = (organizationId: number, trackId: number, exceptionSlotId: number, body: any) => api.put(`organizations/${organizationId}/tracks/${trackId}/exception-slots/${exceptionSlotId}`, body)
  const deleteOrganizationTrackExceptionSlot = (organizationId: number, trackId: number, exceptionSlotId: number) => api.delete(`organizations/${organizationId}/tracks/${trackId}/exception-slots/${exceptionSlotId}`)

  // Track feedbacks
  const getOrganizationTrackFeedbacks = (organizationId: number, trackId: number) => api.get(`organizations/${organizationId}/track-feedbacks`)
  const getAllTrackFeedbacks = () => api.get('track-feedbacks')
  const getTrackFeedback = (trackFeedbackId: number) => api.get(`track-feedbacks/${trackFeedbackId}`)
  const getOrganizationTrackFeedback = (organizationId: number, trackId: number, feedbackId: number) => api.get(`organizations/${organizationId}/tracks/${trackId}/track-feedbacks/${feedbackId}`)
  const publishTrackFeedbackResponse = (organizationId: number, trackId: number, feedbackId: number, body: any) => api.post(`organizations/${organizationId}/tracks/${trackId}/track-feedbacks/${feedbackId}/responses`, body)
  const publishAdminTrackFeedbackResponse = (feedbackId: number, body: any) => api.post(`track-feedbacks/${feedbackId}/responses`, body)
  const updateTrackFeedbackResponse = (organizationId: number, trackId: number, feedbackId: number, body: any) => api.put(`organizations/${organizationId}/tracks/${trackId}/track-feedbacks/${feedbackId}/responses`, body)
  const updateAdminTrackFeedbackResponse = (feedbackId: number, body: any) => api.put(`track-feedbacks/${feedbackId}/responses`, body)

  // Track messages
  const getOrganizationTrackMessages = (id: number, params?: any) => api.get(`organizations/${id}/track-messages`, params)
  const getOrganizationTrackMessage = (id: number, trackId: number, messageId: number) => api.get(`organizations/${id}/tracks/${trackId}/messages/${messageId}`)
  const createOrganizationTrackMessage = (id: number, trackId: number, body: any) => api.post(`organizations/${id}/tracks/${trackId}/messages`, body)
  const updateOrganizationTrackMessage = (id: number, trackId: number, messageId: number, body: any) => api.put(`organizations/${id}/tracks/${trackId}/messages/${messageId}`, body)
  const archiveOrganizationTrackMessage = (id: number, trackId: number, messageId: number) => api.delete(`organizations/${id}/tracks/${trackId}/messages/${messageId}`)
  
  // Messages
  const getMessages = (params?: any) => api.get('messages', params)
  const getMessage = (id: number) => api.get(`messages/${id}`)
  const createMessage = (body: any) => api.post('messages', body)
  const updateMessage = (id: number, body: any) => api.put(`messages/${id}`, body)
  const archiveMessage = (id: number) => api.delete(`messages/${id}`)

  // Track notifications
  const getOrganizationTrackNotifications = (id: number) => api.get(`organizations/${id}/track-notifications`, {})
  const getOrganizationTrackNotification = (id: number, trackId: number, notificationId: number) => api.get(`organizations/${id}/tracks/${trackId}/notifications/${notificationId}`)
  const createOrganizationTrackNotification = (id: number, trackId: number, body: any) => api.post(`organizations/${id}/tracks/${trackId}/notifications`, body)
  const updateOrganizationTrackNotification = (id: number, trackId: number, notificationId: number, body: any) => api.put(`organizations/${id}/tracks/${trackId}/notifications/${notificationId}`, body)
  const archiveOrganizationTrackNotification = (id: number, trackId: number, notificationId: number) => api.delete(`organizations/${id}/tracks/${trackId}/notifications/${notificationId}`)
  
  // Payment codes
  const getPaymentCodes = (params?: any) => api.get('payment-codes', params)
  const getOrganizationPaymentCodes = (id: number, params?: any) => api.get(`organizations/${id}/payment-codes`, params)
  const createOrganizationPaymentCodes = (id: number, fieldId: number, trackId: number, body: any) => api.post(`organizations/${id}/fields/${fieldId}/tracks/${trackId}/payment-codes`, body)
  const updateOrganizationPaymentCode = (id: number, fieldId: number, trackId: number, paymentCodeId: number, body: any) => api.put(`organizations/${id}/fields/${fieldId}/tracks/${trackId}/payment-codes/${paymentCodeId}`, body)
  const archiveOrganizationPaymentCode = (id: number, fieldId: number, trackId: number, paymentCodeId: number) => api.delete(`organizations/${id}/fields/${fieldId}/tracks/${trackId}/payment-codes/${paymentCodeId}`)

  // Discount codes
  const getDiscountCodes = (params?: any) => api.get('discount-codes', params)
  const getOrganizationDiscountCodes = (id: number, params?: any) => api.get(`organizations/${id}/discount-codes`, params)
  const createOrganizationDiscountCode = (id: number, fieldId: number, body: any) => api.post(`organizations/${id}/fields/${fieldId}/discount-codes`, body)
  const updateOrganizationDiscountCode = (id: number, fieldId: number, discountCodeId: number, body: any) => api.put(`organizations/${id}/fields/${fieldId}/discount-codes/${discountCodeId}`, body)
  const archiveOrganizationDiscountCode = (id: number, fieldId: number, discountCodeId: number) => api.delete(`organizations/${id}/fields/${fieldId}/discount-codes/${discountCodeId}`)

  // Season passes
  const getSeasonPasses = (params?: any) => api.get('season-passes', params)
  const getOrganizationSeasonPasses = (id: number, params?: any) => api.get(`organizations/${id}/season-passes`, params)
  const createOrganizationSeasonPasses = (id: number, fieldId: number, body: any) => api.post(`organizations/${id}/fields/${fieldId}/season-passes`, body)
  const updateOrganizationSeasonPass = (id: number, fieldId: number, seasonPassId: number, body: any) => api.put(`organizations/${id}/fields/${fieldId}/season-passes/${seasonPassId}`, body)
  const archiveOrganizationSeasonPass = (id: number, fieldId: number, seasonPassId: number) => api.delete(`organizations/${id}/fields/${fieldId}/season-passes/${seasonPassId}`)
  
  // Reports
  const getReport = (organizationId: number, fieldId: number, params: any) => api.get(`organizations/${organizationId}/fields/${fieldId}/reports`, params)

  // Events
  const getProShopEvents = (params?: any) => api.get('proshop-events', params)
  const getOrganizationProShopEvents = (id: number, params?: any) => api.get(`organizations/${id}/proshop-events`, params)

  return {
    logout,
    login,
    keepAlive,
    requestPasswordReset,
    getPasswordResetRequest,
    completePasswordReset,
    getInvite,
    verifyAccount,
    getMe,
    updateMe,
    updateMyProfilePhoto,
    deleteMyProfilePhoto,
    searchForLocation,
    getOrganizations,
    getOrganization,
    createOrganization,
    updateOrganization,
    getAllUsers,
    getOrganizationUsers,
    getOrganizationUser,
    getAdminUser,
    updateAdminUser,
    archiveAdminUser,
    activateAdminUser,
    updateOrganizationUser,
    archiveOrganizationUser,
    activateOrganizationUser,
    createOrganizationUserInvite,
    createAdminUserInvite,
    removeOrganizationUserInvite,
    getAllFields,
    getOrganizationFields,
    getOrganizationField,
    createOrganizationField,
    updateOrganizationField,
    deleteOrganizationField,
    createOrganizationFieldSeason,
    updateOrganizationFieldSeason,
    archiveOrganizationFieldSeason,
    getOrganizationFieldTracks,
    getOrganizationFieldTrack,
    createOrganizationFieldTrack,
    updateOrganizationFieldTrack,
    deleteOrganizationFieldTrack,
    getOrganizationTrackSeasons,
    getOrganizationTrackSeason,
    deleteOrganizationTrackSeason,
    createOrganizationTrackSeason,
    updateOrganizationTrackSeason,
    getOrganizationTrackCalendar,
    createOrganizationTrackExceptionSlot,
    updateOrganizationTrackExceptionSlot,
    deleteOrganizationTrackExceptionSlot,
    getOrganizationTrackFeedbacks,
    getAllTrackFeedbacks,
    getOrganizationTrackFeedback,
    getTrackFeedback,
    publishAdminTrackFeedbackResponse,
    updateAdminTrackFeedbackResponse,
    getOrganizationTrackMessages,
    getOrganizationTrackMessage,
    createOrganizationTrackMessage,
    updateOrganizationTrackMessage,
    archiveOrganizationTrackMessage,
    getMessages,
    getMessage,
    createMessage,
    updateMessage,
    archiveMessage,
    getPaymentCodes,
    getOrganizationPaymentCodes,
    createOrganizationPaymentCodes,
    updateOrganizationPaymentCode,
    archiveOrganizationPaymentCode,
    getDiscountCodes,
    getOrganizationDiscountCodes,
    createOrganizationDiscountCode,
    updateOrganizationDiscountCode,
    archiveOrganizationDiscountCode,
    getSeasonPasses,
    getOrganizationSeasonPasses,
    createOrganizationSeasonPasses,
    updateOrganizationSeasonPass,
    archiveOrganizationSeasonPass,
    getReport,
    updateOrganizationFieldTrackPhoto,
    deleteOrganizationFieldTrackPhoto,
    publishTrackFeedbackResponse,
    updateTrackFeedbackResponse,
    getOrganizationTrackNotifications,
    getOrganizationTrackNotification,
    createOrganizationTrackNotification,
    updateOrganizationTrackNotification,
    archiveOrganizationTrackNotification,
    getProShopEvents,
    getOrganizationProShopEvents

  }
}

const Api = create()

export default Api
