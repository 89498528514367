import { makeAutoObservable } from 'mobx'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import { Api } from '../Services'

export default class ReportStore {
  rootStore

  rows: any[] = []
  totalRows: number = 0
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setRows = (rows: any[]) => { this.rows = rows }
  setTotalRows = (totalRows: number) => { this.totalRows = totalRows }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getReport(organizationId: number, fieldId: number, params: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.getReport(organizationId, fieldId, params)
      if (response.ok) {
        const rows = response?.data?.items || []
        const totalRows = response?.data?.total || 0
        this.setRows(rows)
        this.setTotalRows(totalRows)
      }
    } catch (error) {
      showMessage(i18n.t('failed_to_get_report'))
    }
    this.setLoading(false)
  }
}
