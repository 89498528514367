import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { Button, Table } from '../../../Components'
import ProShopCategory from '../../../Components/Common/ProShopCategory'
import CreateDiscountCodeModal from '../../../Components/Common/CreateDiscountCodeModal'
import CreateSeasonPassCodeModal from '../../../Components/Common/CreateSeasonPassModal'
import CreatePaymentCodeModal from '../../../Components/Common/CreatePaymentCodeModal'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Colors } from '../../../Utils/theme'

const ProShop = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { sessionStore, userStore, fieldStore, proShopStore }: any = useStore()
  const { loading } = proShopStore

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
      proShopStore.getEvents()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
      proShopStore.getOrganizationEvents(sessionStore.user?.organizationId)
    }
  }, [])

  const [showCreateDiscountCodeModal, setShowCreateDiscountCodeModal] = useState(false)
  const [showCreatePaymentCodeModal, setShowCreatePaymentCodeModal] = useState(false)
  const [showCreateSeasonPassCodeModal, setShowCreateSeasonPassCodeModal] = useState(false)

  const openCreateDiscountCodeModal = () => setShowCreateDiscountCodeModal(true)
  const closeCreateDiscountCodeModal = () => setShowCreateDiscountCodeModal(false)
  const openCreatePaymentCodeModal = () => setShowCreatePaymentCodeModal(true)
  const closeCreatePaymentCodeModal = () => setShowCreatePaymentCodeModal(false)
  const openCreateSeasonPassCodeModal = () => setShowCreateSeasonPassCodeModal(true)
  const closeCreateSeasonPassCodeModal = () => setShowCreateSeasonPassCodeModal(false)

  const toDiscountCodes = () => navigate('/proshop/discount-codes')
  const toPaymentCodes = () => navigate('/proshop/payment-codes')
  const toSeasonPasses = () => navigate('/proshop/season-passes')

  const toField = (seasonPass: any) => {
    navigate(`/organizations/${seasonPass.organizationId}/fields/${seasonPass.fieldId}`)
  }
  const toTrack = (track: any) => {
    navigate(`/organizations/${track.organizationId}/fields/${track.fieldId}/tracks/${track.id}`)
  }

  const getAvailableSeasons = (organizationId: number, fieldId: number) => {
    fieldStore.getOrganizationField(
      Number(organizationId),
      Number(fieldId)
    )
  }

  const createDiscountCode = (organizationId: number, fieldId: number, data: any) => {
    proShopStore.createDiscountCode(
      organizationId,
      fieldId,
      data,
      closeCreateDiscountCodeModal
    )
  }
  const createPaymentCodes = (organizationId: number, fieldId: number, trackId: number, data: any) => {
    proShopStore.createPaymentCodes(
      organizationId,
      fieldId,
      trackId,
      data,
      closeCreatePaymentCodeModal
    )
  }
  const createSeasonPasses = (organizationId: number, fieldId: number, data: any) => {
    proShopStore.createSeasonPasses(
      organizationId,
      fieldId,
      data,
      closeCreateSeasonPassCodeModal
    )
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'updatedAt',
      headerName: t('event_time'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        if (params.row?.updatedAt) {
          const date = DateTime.fromISO(params.row.updatedAt)
          return `${date.toFormat('dd.MM.yyyy')} ${t('at')} ${date.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        let text = params.row?.type ? t(params.row.type) : '-'
        if (text) {
          return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
        }
        return '-'
      }
    },
    {
      field: 'fieldId',
      headerName: t('field'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.field?.nameFi || ''}
            onClick={() => toField(params.row)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'track',
      headerName: t('track'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.track?.nameFi || ''}
            onClick={() => toTrack(params.row?.track)}
            variant='text'
          />
        )
      }
    },
    /*
    {
      field: 'mobileUser',
      headerName: t('user'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return `${params.value?.firstName || ''} ${params.value?.lastName || ''}`.trim()
        }
        return '-'
      }
    },
    */
    {
      field: 'event',
      headerName: t('event'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const wasArchived = params?.row?.archivedAt || false
        const wasCreated = params?.row?.creatorAdminUserId || false
        const wasBought = params?.row?.mobileUserId || params?.row?.buyerMobileUserId || false
        const wasRefunded = params?.row?.refundedFromTrackSlotId || false
        const wasActivated = params?.row?.activatedAt || false
        const wasUsed = params?.row?.usedAt || false
        let status = '-'
        if (wasArchived) {
          status = t('archive_action')
        } else if (wasCreated && !wasUsed) {
          status = t('creation')
        } else if (wasBought && !wasUsed) {
          status = t('purchase')
        } else if (wasRefunded && !wasUsed) {
          status = t('refund')
        } else if (wasActivated && !wasUsed) {
          status = t('activation')
        } else if (wasUsed) {
          status = t('use')
        }
        return <Chip label={status} variant='outlined' size='small' sx={styles.chip} />
      }
    }
  ], [loading])

  const rows = useMemo(() => proShopStore.events, [proShopStore.events])

  const renderCreateDiscountCodeModal = () => {
    if (showCreateDiscountCodeModal) {
      return (
        <CreateDiscountCodeModal
          onClose={closeCreateDiscountCodeModal}
          onCreate={createDiscountCode}
          fields={fieldStore.fields}
        />
      )
    }
    return null
  }

  const renderCreatePaymentCodeModal = () => {
    if (showCreatePaymentCodeModal) {
      return (
        <CreatePaymentCodeModal
          onClose={closeCreatePaymentCodeModal}
          onCreate={createPaymentCodes}
          tracks={flatten(fieldStore.fields?.map((field: any) => field?.tracks || null).filter((track: any) => track) || [])}
        />
      )
    }
    return null
  }

  const renderCreateSeasonPassModal = () => {
    if (showCreateSeasonPassCodeModal) {
      return (
        <CreateSeasonPassCodeModal
          onClose={closeCreateSeasonPassCodeModal}
          onCreate={createSeasonPasses}
          fields={fieldStore.fields}
          seasons={fieldStore.field?.seasons || []}
          getSeasons={getAvailableSeasons}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.categoriesContainer}>
        <ProShopCategory
          type='discount_code'
          name={t('discount_codes')}
          onClick={toDiscountCodes}
          onActionClick={openCreateDiscountCodeModal}
          mr='2rem'
        />
        <ProShopCategory
          type='payment_code'
          name={t('payment_codes')}
          onClick={toPaymentCodes}
          onActionClick={openCreatePaymentCodeModal}
          mr='2rem'
        />
        <ProShopCategory
          type='season_pass'
          name={t('season_passes')}
          onClick={toSeasonPasses}
          onActionClick={openCreateSeasonPassCodeModal}
        />
      </Box>
      <Typography variant='h3' mb='1.5rem'>{t('latest_events')}</Typography>
      <Table
        rows={rows}
        columns={columns}
        loading={loading}
        sx={{ maxHeight: null }}
      />
      {renderCreateDiscountCodeModal()}
      {renderCreatePaymentCodeModal()}
      {renderCreateSeasonPassModal()}
    </Box>
  )
}

export default observer(ProShop)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    pt: '2rem',
    pb: '10rem'
  },
  categoriesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '64rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem',
    mb: '3rem'
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    mb: '1.5rem',
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2.5rem',
    mb: '1rem'
  },
  categoryIcon: {
    width: '7.5rem',
    height: '7.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
} as const
