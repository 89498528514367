import { useState } from 'react'
import { t } from 'i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import CalendarIcon from '../../Assets/Icons/calendar-alt.svg'
import CloseIcon from '../../Assets/Icons/close.svg'
import CheckIcon from '../../Assets/Icons/check-alt.svg'
import { Button } from '..'
import { Colors } from '../../Utils/theme'

export default function CalendarAddItemModal(props: any) {
  const { onClose } = props

  const [type, setType] = useState<string | null>(null)

  const selectReservationType = () => setType('system_reservation')
  const selectExceptionType = () => setType('exception_slot')
  
  const onSelect = () => props.onSelect(type)

  const renderSelectedIndicator = (value: string) => {
    if (type === value) {
      return (
        <Box sx={styles.selectedIconContainer}>
          <Box
            component='img'
            src={CheckIcon}
            alt='icon'
            sx={styles.selectedIcon}
          />
        </Box>  
      )
    }
    return null
  }

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open>
      <DialogTitle variant='h2' sx={styles.title}>{t('add')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.typeContainer}>
          <ButtonBase
            onClick={selectReservationType}
            sx={{
              ...styles.typeButton,
              mr: '2rem',
              ...(type === 'system_reservation' ? styles.selectedTypeButton : {}) 
            }}
          >
            <Box component='img' src={CalendarIcon} alt='icon' sx={styles.icon} />
            <Typography variant='body1' sx={styles.type}>{t('reservation')}</Typography>
          </ButtonBase>
          {renderSelectedIndicator('system_reservation')}
        </Box>
        <Box sx={styles.typeContainer}>
          <ButtonBase
            onClick={selectExceptionType}
            sx={{ ...styles.typeButton, ...(type === 'exception_slot' ? styles.selectedTypeButton : {}) }}
          >
            <Box component='img' src={CloseIcon} alt='icon' sx={styles.icon} />
            <Typography variant='body1' sx={styles.type}>{t('exception_slot')}</Typography>
          </ButtonBase>
          {renderSelectedIndicator('exception_slot')}
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={t('cancel')}
          onClick={onClose}
          mr='2rem'
          width='12rem'
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={t('next')}
          onClick={onSelect}
          disabled={!type}
          width='12rem'
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '28rem',
      width: '28rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem'
  },
  title: {
    color: Colors.heading,
    alignSelf: 'center',
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    alignSelf: 'center'
  },
  typeContainer: {
    position: 'relative',
    paddingTop: '1.5rem'
  },
  typeButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '10rem',
    // backgroundColor: Colors.cardBackground,
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem',
    '&:hover': {
      // backgroundColor: Colors.cardBackground70
    }
  },
  selectedTypeButton: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `3px solid ${Colors.brandPrimary}`
  },
  icon: {
    height: '4rem',
    mb: '0.75rem'
  },
  type: {
    fontSize: '1.125rem'
  },
  selectedIconContainer: {
    alignSelf: 'center',
    position: 'absolute',
    top: '0.5rem',
    left: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    backgroundColor: Colors.brandPrimary,
    borderRadius: '50%'
  },
  selectedIcon: {
    height: '0.75rem'
  },
  outlinedButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mt: '1rem',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
