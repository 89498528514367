import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import SquareButton from '../../../Components/Common/SquareButton'
import EditIcon from '../../../Assets/Icons/edit.svg'
import TrashIcon from '../../../Assets/Icons/trash.svg'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { Colors } from '../../../Utils/theme'

const Messages = (props: any) => {
  const { sessionStore, messageStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { language } = sessionStore
  const { loading } = messageStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [archiveRow, setArchiveRow] = useState<any>(null)

  const clearSearchKey = () => setSearchKey('')

  const handleDelete = () => {
    if (archiveRow) {
      if (['admin', 'superadmin'].includes(sessionStore.user?.role)) {
        messageStore.archiveMessage(archiveRow.id)
        setArchiveRow(null)
      } else {
        messageStore.archiveTrackMessage(archiveRow.trackId, archiveRow.id)
        setArchiveRow(null)
      }
    }
  }

  useEffect(() => {
    if (['admin', 'superadmin'].includes(sessionStore.user?.role)) {
      messageStore.getMessages()
    } else {
      messageStore.getOrganizationTrackMessages()
    }
  }, [])

  const toMessage = (message: any) => {
    if (['admin', 'superadmin'].includes(sessionStore.user?.role)) {
      navigate(`/messages/${message.id}`)
    } else {
      navigate(`/tracks/${message.trackId}/messages/${message.id}`)
    }
  }
  const toCreateMessage = () => navigate('/messages/create')

  const formatDateTime = (date: string) => {
    const dayPart = DateTime.fromISO(date).toFormat('dd.MM.yyyy')
    const timePart = DateTime.fromISO(date).toFormat('HH.mm')
    return `${dayPart} ${t('at')} ${timePart}`
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'title',
      headerName: t('title'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.titleFi || params.row.titleEn || params.row.titleSv}
          onClick={() => toMessage(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'archivedAt',
      headerName: t('state'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        const now = DateTime.now()
        let text = params?.row?.archivedAt ?
          t('archived') :
          params.row?.sentAt && DateTime.fromISO(params.row.sentAt) < now ?
          t('sent') :
          t('not_sent')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'sentAt',
      headerName: t('sent_at_time'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.sentAt) {
          return formatDateTime(params.row.sentAt)
        }
        return formatDateTime(params.row.publishedAt)
      }
    },
    {
      field: 'creatorAdminUser',
      headerName: t('sender'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return `${params.value?.firstName} ${params.value?.lastName}`
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actionsContainer}>
          <SquareButton
            icon={EditIcon}
            onClick={() => toMessage(params.row)}
            iconSize='1.25rem'
            mr='0.5rem'
            blue
          />
          <SquareButton
            icon={TrashIcon}
            onClick={() => setArchiveRow(params.row)}
            grey
          />
        </Box>
      )
    }
  ], [language])

  const rows = useMemo(() => {
    let messages = messageStore.messages
    if (searchKey) {
      messages = messages.filter((message: any) => 
        (`${message.titleFi} ${message.titleEn} ${message.titleSv}`)
        .toLowerCase()
        .includes(searchKey.toLowerCase())
      )
    }
    return messages
  }, [messageStore.messages, searchKey])

  const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)

  const renderConfirmDelete = () => {
    if (archiveRow) {
      return (
        <ConfirmationDialog
          title={t('delete_message')}
          content={t('delete_message_confirmation')}
          onClose={() => setArchiveRow(null)}
          cancelText={t('cancel')}
          onAction={handleDelete}
          actionText={t('archive')}
          delete
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onAddClick={toCreateMessage}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderConfirmDelete()}
    </Box>
  )
}

export default observer(Messages)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  }
} as const
