import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { CustomSwitch } from './Switch'
import LangSelect from './LangSelect'
import { Button, Input, Select } from '..'
import { validateNewTrackSeason, validateSeasonDates } from '../../Utils/validation'
import { Colors } from '../../Utils/theme'

const SeasonPresaleStartOptions = {
  OneWeek: '1_week',
  TwoWeeks: '2_weeks',
  ThreeWeeks: '3_weeks',
  OneMonth: '1_month',
  TwoMonths: '2_months',
  ThreeMonths: '3_months'
}

const SeasonPresaleEndOptions = {
  UntilSeasonEnd: 'until_season_end',
  UntilOneWeekBeforeSeasonEnd: 'until_one_week_before_season_end',
  UntilTwoWeeksBeforeSeasonEnd: 'until_two_weeks_before_season_end',
  UntilThreeWeeksBeforeSeasonEnd: 'until_three_weeks_before_season_end',
  UntilOneMonthBeforeSeasonEnd: 'until_one_month_before_season_end'
}

export default function SeasonModal(props: any) {
  const { t } = useTranslation()
  const { proShopEnabled, seasonPassEnabled  } = props

  const seasonPassAvailable = proShopEnabled && seasonPassEnabled
  const maxPhase = seasonPassAvailable ? 5 : 3

  const [lang, setLang] = useState('fi')
  const [phase, setPhase] = useState(1)
  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [nameSv, setNameSv] = useState('')
  const [startTime, setStartTime] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')
  const [seasonPassPriceStatus, setSeasonPassPriceStatus] = useState<any>(seasonPassAvailable ? 'single_price' : '')
  const [generalSeasonPassPrice, setGeneralSeasonPassPrice] = useState<any>('')
  const [adultSeasonPassPrice, setAdultSeasonPassPrice] = useState<any>('')
  const [juniorSeasonPassPrice, setJuniorSeasonPassPrice] = useState<any>('')
  const [studentSeasonPassPrice, setStudentSeasonPassPrice] = useState<any>('')
  const [availableForSaleFrom, setAvailableForSaleFrom] = useState<any>(seasonPassAvailable ? SeasonPresaleStartOptions.OneMonth : '')
  const [availableForSaleUntil, setAvailableForSaleUntil] = useState<any>(seasonPassAvailable ? SeasonPresaleEndOptions.UntilSeasonEnd : '')
  const [availableForSaleFromTime, setAvailableForSaleFromTime] = useState<any>('')
  const [availableForSaleUntilTime, setAvailableForSaleUntilTime] = useState<any>('')

  useEffect(() => {
    const season = props.season
    if (season) {
      setNameFi(season.nameFi || '')
      setNameEn(season.nameEn || '')
      setNameSv(season.nameSv || '')

      const newStartTime = DateTime
        .fromISO(season.startTime)
        .setZone('Europe/Helsinki')
        .toFormat('yyyy-MM-dd')
      const newEndTime = DateTime
        .fromISO(season.endTime)
        .setZone('Europe/Helsinki')
        .toFormat('yyyy-MM-dd')

      setStartTime(newStartTime)
      setEndTime(newEndTime)

      if (seasonPassAvailable) {
        setSeasonPassPriceStatus(season?.seasonPassPriceStatus || 'single_price')
        setGeneralSeasonPassPrice(priceFromCents(season.generalSeasonPassPrice) || '')
        setAdultSeasonPassPrice(priceFromCents(season.adultSeasonPassPrice) || '')
        setJuniorSeasonPassPrice(priceFromCents(season.juniorSeasonPassPrice) || '')
        setStudentSeasonPassPrice(priceFromCents(season.studentSeasonPassPrice) || '')
        setAvailableForSaleFrom(season?.availableForSaleFrom || SeasonPresaleStartOptions.OneMonth)
        setAvailableForSaleUntil(season?.availableForSaleUntil || SeasonPresaleEndOptions.UntilSeasonEnd)
        setAvailableForSaleFromTime(season?.availableForSaleFromTime || null)
        setAvailableForSaleUntilTime(season?.availableForSaleUntilTime || null)
      }
    }
  }, [props.season])

  const getPhaseTitle = () => {
    if (phase === 1) {
      return t('season_name')
    } else if (phase === 2) {
      return t('season_length')
    } else if (phase === 3) {
      if (!seasonPassAvailable) {
        return t('summary')
      }
      return t('season_pass_price')
    } else if (phase === 4) {
      return t('season_pass_presale')
    } else if (phase === 5) {
      return t('summary')
    }
    return ''
  }

  const getSaveButtonText = () => {
    if (seasonPassAvailable && phase === 5) {
      if (props?.season) {
        return t('save')
      }
      return t('create_season')
    }
    if (!seasonPassAvailable && phase === 3) {
      if (props?.season) {
        return t('save')
      }
      return t('create_season')
    }
    return t('next')
  }

  const getSeasonPassPriceStatusOptions = () => {
    return [
      { value: 'single_price', label: t('single_price') },
      { value: 'price_by_ticket_type', label: t('price_by_ticket_type') }
    ]
  }

  const getSeasonPreSaleStartOptions = () => {
    return Object.values(SeasonPresaleStartOptions).map((option: any) => ({
      value: option,
      label: t(option)
    }))
  }

  const getSeasonPreSaleEndOptions = () => {
    return Object.values(SeasonPresaleEndOptions).map((option: any) => ({
      value: option,
      label: t(option)
    }))
  }

  const getSeasonAvailableForSaleFrom = () => {
    if (availableForSaleFromTime) {
      return DateTime.fromISO(availableForSaleFromTime).toFormat('dd.MM.yyyy')
    }
    return ''
  }

  const getSeasonAvailableForSaleUntil = () => {
    if (availableForSaleUntilTime) {
      return DateTime.fromISO(availableForSaleUntilTime).toFormat('dd.MM.yyyy')
    }
    return ''
  }

  const priceToCents = (price: string) => {
    if (price) {
      return parseFloat(price) * 100
    }
    return null
  }

  const priceFromCents = (price: number) => {
    if (price) {
      return (price / 100)
    }
    return null
  }

  const handleSetStartTime = (value: any) => {
    setStartTime(value)
  }

  const handleSetEndTime = (value: any) => {
    setEndTime(value)
  }

  const updateAvailableForSaleFrom = (value: any) => {
    setAvailableForSaleFrom(value)
    if (value === SeasonPresaleStartOptions.OneWeek) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ week: 1 }).toISO())
    } else if (value === SeasonPresaleStartOptions.TwoWeeks) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ weeks: 2 }).toISO())
    } else if (value === SeasonPresaleStartOptions.ThreeWeeks) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ weeks: 3 }).toISO())
    } else if (value === SeasonPresaleStartOptions.OneMonth) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ months: 1 }).toISO())
    } else if (value === SeasonPresaleStartOptions.TwoMonths) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ months: 2 }).toISO())
    } else if (value === SeasonPresaleStartOptions.ThreeMonths) {
      setAvailableForSaleFromTime(DateTime.fromISO(startTime).minus({ months: 3 }).toISO())
    }
  }

  const updateAvailableForSaleUntil = (value: any) => {
    setAvailableForSaleUntil(value)
    if (value === SeasonPresaleEndOptions.UntilSeasonEnd) {
      setAvailableForSaleUntilTime(endTime)
    } else if (value === SeasonPresaleEndOptions.UntilOneWeekBeforeSeasonEnd) {
      setAvailableForSaleUntilTime(DateTime.fromISO(endTime).minus({ week: 1 }).toISO())
    } else if (value === SeasonPresaleEndOptions.UntilTwoWeeksBeforeSeasonEnd) {
      setAvailableForSaleUntilTime(DateTime.fromISO(endTime).minus({ weeks: 2 }).toISO())
    } else if (value === SeasonPresaleEndOptions.UntilThreeWeeksBeforeSeasonEnd) {
      setAvailableForSaleUntilTime(DateTime.fromISO(endTime).minus({ weeks: 3 }).toISO())
    } else if (value === SeasonPresaleEndOptions.UntilOneMonthBeforeSeasonEnd) {
      setAvailableForSaleUntilTime(DateTime.fromISO(endTime).minus({ months: 1 }).toISO())
    }
  }

  const next = () => {
    if (phase < 5) {
      if (phase === 4) {
        updateAvailableForSaleFrom(availableForSaleFrom)
        updateAvailableForSaleUntil(availableForSaleUntil)
      }
      setPhase(phase + 1)
    }
  }
  const back = () => {
    if (phase > 1) {
      setPhase(phase - 1)
    }
  }

  const getIsSaveDisabled = () => {
    if (phase === 1) {
      return !nameFi
    } else if (phase === 2) {
      return !validateSeasonDates({ startTime, endTime })
    } else if (phase === 3) {
      if (
        seasonPassAvailable &&
        seasonPassPriceStatus === 'single_price'
      ) {
        return !generalSeasonPassPrice
      } else if (
        seasonPassAvailable &&
        seasonPassPriceStatus === 'price_by_ticket_type'
      ) {
        return !adultSeasonPassPrice && !juniorSeasonPassPrice && !studentSeasonPassPrice
      }
      return false
    } else if (phase === 4) {
      return !availableForSaleFrom || !availableForSaleUntil
    }
  }

  const onCreate = () => {
    const startParts = startTime.split('-')
    const endParts = endTime.split('-')

    if (startParts.length !== 3 || endParts.length !== 3) {
      return null
    }

    const newStartTime = DateTime.fromObject({
      year: parseInt(startParts[0], 10),
      month: parseInt(startParts[1], 10),
      day: parseInt(startParts[2], 10),
    }, {
      zone: 'Europe/Helsinki'
    }).startOf('day').toUTC().toJSDate()

    const newEndTime = DateTime.fromObject({
      year: parseInt(endParts[0], 10),
      month: parseInt(endParts[1], 10),
      day: parseInt(endParts[2], 10),
    }, {
      zone: 'Europe/Helsinki'
    }).endOf('day').startOf('second').toUTC().toJSDate()

    const newSeason = {
      nameFi,
      nameEn: nameEn || null,
      nameSv: nameSv || null,
      startTime: newStartTime,
      endTime: newEndTime,
      seasonPassPriceStatus: seasonPassAvailable ? seasonPassPriceStatus : null,
      generalSeasonPassPrice: seasonPassAvailable && seasonPassPriceStatus === 'single_price' ? priceToCents(generalSeasonPassPrice) : null,
      adultSeasonPassPrice: seasonPassAvailable && seasonPassPriceStatus === 'price_by_ticket_type' ? priceToCents(adultSeasonPassPrice) : null,
      juniorSeasonPassPrice: seasonPassAvailable && seasonPassPriceStatus === 'price_by_ticket_type' ? priceToCents(juniorSeasonPassPrice) : null,
      studentSeasonPassPrice: seasonPassAvailable && seasonPassPriceStatus === 'price_by_ticket_type' ? priceToCents(studentSeasonPassPrice) : null,
      availableForSaleFrom: seasonPassAvailable ? availableForSaleFrom : null,
      availableForSaleUntil: seasonPassAvailable ? availableForSaleUntil : null,
      availableForSaleFromTime: seasonPassAvailable ? availableForSaleFromTime : null,
      availableForSaleUntilTime: seasonPassAvailable ? availableForSaleUntilTime : null
    }
    props.onCreate(newSeason)
  }

  const renderGeneralTicketPrice = () => {
    if (seasonPassPriceStatus === 'single_price') {
      return (
        <Input
          label={t('season_pass_price')}
          value={generalSeasonPassPrice}
          onChange={setGeneralSeasonPassPrice}
          type='number'
          maxWidth='10rem'
          ml='2rem'
          unit='euro'
        />
      )
    }
    return null
  }

  const renderTicketTypePrices = () => {
    if (seasonPassPriceStatus === 'price_by_ticket_type') {
      return (
        <Box sx={styles.row}>
          <Input
            label={t('adult')}
            value={adultSeasonPassPrice}
            onChange={setAdultSeasonPassPrice}
            type='number'
            maxWidth='10rem'
            unit='euro'
          />
          <Input
            label={t('junior')}
            value={juniorSeasonPassPrice}
            onChange={setJuniorSeasonPassPrice}
            type='number'
            maxWidth='10rem'
            unit='euro'
            ml='2rem'
          />
          <Input
            label={t('student')}
            value={studentSeasonPassPrice}
            onChange={setStudentSeasonPassPrice}
            type='number'
            maxWidth='10rem'
            unit='euro'
            ml='2rem'
          />
        </Box>
      )
    }
    return null
  }

  const renderSeasonPassPriceSummary = () => {
    if (seasonPassPriceStatus === 'single_price') {
      return (
        <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
          {t('single_price')}: {`${generalSeasonPassPrice}`.replace('.', ',')} €
        </Typography>
      )
    } else if (seasonPassPriceStatus === 'price_by_ticket_type') {
      return (
        <>
          {
            adultSeasonPassPrice ? (
              <Typography variant='body1' color={Colors.text60} mb='0.5rem'>
                {t('adult')}: {`${adultSeasonPassPrice}`?.replace('.', ',')} €
              </Typography>
            ) : null
          }
          {
            juniorSeasonPassPrice ? (
              <Typography variant='body1' color={Colors.text60} mb='0.5rem'>
                {t('junior')}: {`${juniorSeasonPassPrice}`?.replace('.', ',')} €
              </Typography>
            ) : null
          }
          {
            studentSeasonPassPrice ? (
              <Typography variant='body1' color={Colors.text60} mb='0.5rem'>
                {t('student')}: {`${studentSeasonPassPrice}`?.replace('.', ',')} €
              </Typography>
            ) : null
          }
        </>
      )
    }
    return null
  }

  const renderSeasonPassSummary = () => {
    if (seasonPassAvailable) {
      return (
        <>
          <Typography variant='h5' mb='0.5rem'>{t('season_pass_price')}</Typography>
          {renderSeasonPassPriceSummary()}
          <Typography variant='h5' mt='1.5rem' mb='0.5rem'>{t('season_passes_on_sale')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {getSeasonAvailableForSaleFrom()} - {getSeasonAvailableForSaleUntil()}
          </Typography>
        </>
      )
    }
    return null
  }

  const renderPhaseContent = () => {
    if (phase === 1) {
      return (
        <>
          <Box sx={styles.langSelectContainer}>
            <LangSelect
              value={lang}
              onChange={setLang}
              status={{ fi: nameFi, en: nameEn, sv: nameSv }}
            />
          </Box>
          <Typography variant='body1' color={Colors.text60} mt='0.25rem' mb='1.5rem'>
            {t('season_name_info')}
          </Typography>
          <Input
            placeholder={t('name')}
            value={lang === 'fi' ? nameFi : lang === 'en' ? nameEn : nameSv}
            onChange={lang === 'fi' ? setNameFi : lang === 'en' ? setNameEn : setNameSv}
            mb='1.5rem'
          />
      </>
      )
    } else if (phase === 2) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('season_length_info_2')}
          </Typography>
          <Box sx={styles.row}>
            <Input
              label={t('season_start_date')}
              value={startTime}
              onChange={handleSetStartTime}
              maxWidth='11rem'
              mr='1.5rem'
              type='date'
            />
            <Box sx={styles.separator}>-</Box>
            <Input
              label={t('season_end_date')}
              value={endTime}
              onChange={handleSetEndTime}
              type='date'
              maxWidth='11rem'
              ml='1.5rem'
            />
          </Box>
        </>
      )
    } else if (seasonPassAvailable && phase === 3) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('season_pass_price_info')}
          </Typography>
          <Box sx={styles.row}>
            <Select
              label={t('ticket_price_status')}
              options={getSeasonPassPriceStatusOptions()}
              value={seasonPassPriceStatus}
              onChange={setSeasonPassPriceStatus}
              maxWidth='calc(100% - 12rem)'
            />
            {renderGeneralTicketPrice()}
          </Box>
          {renderTicketTypePrices()}
        </>
      )
    } else if (seasonPassAvailable && phase === 4) {
      return (
        <>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {t('season_pass_presale_info')}
          </Typography>
          <Select
            label={t('season_pass_presale_start')}
            options={getSeasonPreSaleStartOptions()}
            value={availableForSaleFrom}
            onChange={updateAvailableForSaleFrom}
            mb='1.5rem'
          />
          <Select
            label={t('season_pass_presale_end')}
            options={getSeasonPreSaleEndOptions()}
            value={availableForSaleUntil}
            onChange={updateAvailableForSaleUntil}
            mb='1.5rem'
          />
        </>
      )
    } else if (seasonPassAvailable ? phase === 5 : phase === 3) {
      return (
        <Box sx={styles.formContainer}>
          <Typography variant='h5' mb='0.5rem'>{t('season_name')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {nameFi}
          </Typography>
          <Typography variant='h5' mb='0.5rem'>{t('season_length')}</Typography>
          <Typography variant='body1' color={Colors.text60} mb='1.5rem'>
            {DateTime.fromISO(startTime).toFormat('dd.MM.yyyy')} - {DateTime.fromISO(endTime).toFormat('dd.MM.yyyy')}
          </Typography>
          {renderSeasonPassSummary()}
        </Box>
      )
    }
    return null
  }

  return (
    <Dialog sx={styles.dialog} onClose={props.onClose} open>
      <DialogTitle variant='h2' sx={styles.title}>
        {props?.season ? t('edit_season') : t('configure_new_season')}
      </DialogTitle>
      <Typography variant='body1' color={Colors.text60} textAlign='center' mb='1.5rem'>
        {t('season_settings_info')}
      </Typography>
      <DialogContent sx={styles.content}>
        <Box sx={styles.phaseRow}>
          <Chip label={`${phase}/${maxPhase}`} variant='outlined' size='small' sx={styles.chip} />
          <Typography variant='h4' ml='1rem'>{getPhaseTitle()}</Typography>
        </Box>
        {renderPhaseContent()}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={phase === 1 ? t('cancel') : t('back')}
          onClick={phase === 1 ? props.onClose : back}
          mr='2rem'
          width='11rem'
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={getSaveButtonText()}
          onClick={(seasonPassAvailable ? phase === 5 : phase === 3) ? onCreate : next}
          disabled={getIsSaveDisabled()}
          width='11rem'
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '40rem',
      width: '40rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  dayDialog: {
    '.MuiDialog-paper': {
      minWidth: '46rem',
      width: '46rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  title: {
    color: Colors.heading,
    paddingLeft: '1rem',
    alignSelf: 'center',
    textAlign: 'center',
    mb: '0.25rem'
  },
  phaseRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.25rem'
  },
  chip: {
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.white,
    backgroundColor: Colors.brandPrimary,
    borderRadius: '0.625rem',
    border: 0,
    padding: '0rem 0.375rem'
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // minHeight: '15.5rem',
    maxHeight: '70vh',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  outlinedButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  dayRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: '3.125rem',
    mb: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mt: '1.5rem',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  day: {
    width: '7rem',
    fontWeight: 700
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '11.5rem'
  },
  timeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  formContainer: {
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1rem',
    fontWeight: 900,
    color: Colors.text60
  },
  langSelectContainer: {
    position: 'absolute',
    top: '1.875rem',
    right: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
} as const
