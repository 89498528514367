import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const User = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { sessionStore, organizationStore }: any = useStore()
  const { user } = sessionStore
  const { loading, organizationUser } = organizationStore

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [organization, setOrganization] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')

  const openArchiveConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive')
  }
  const openActivateConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('activate')
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
  }

  useEffect(() => {
    const organizationId = params?.organizationId ?? null
    const userId = params?.userId ?? null
    const isAdmin = ['superadmin', 'admin'].includes(user?.role)
    if (organizationId && userId) {
      organizationStore.getOrganizationUser(organizationId, userId)
    } else {
      if (isAdmin) {
        organizationStore.getAdminUser(userId)
      }
    }
    if (isAdmin) {
      organizationStore.getOrganizations()
    }
  }, [])

  useEffect(() => {
    if (organizationUser && organizationUser.id === Number(params?.userId)) {
      setFirstName(organizationUser?.firstName || '')
      setLastName(organizationUser?.lastName || '')
      setEmail(organizationUser?.email || '')
      setPhone(organizationUser?.phone || '')
      setRole(organizationUser?.role || '')
      setOrganization(organizationUser?.organizationId || '')
    }
  }, [organizationUser])

  const getIsSaveDisabled = () => {
    return organizationUser?.archivedAt ||
      !firstName ||
      !lastName ||
      !role ||
      (role === 'field_admin' && !organization)
  }

  const getRoleOptions = () => {
    return [
      { value: 'admin', label: t('admin') },
      { value: 'field_admin', label: t('field_admin') }
    ]
  }

  const getOrganizationOptions = () => {
    return organizationStore.organizations.map((item: any) => {
      return { label: item.name, value: item.id }
    })
  }

  const save = () => {
    let updatedUser: any = {
      firstName,
      lastName,
      phone
    }
    const { organizationId, userId } = params
    if (organizationId) {
      organizationStore.editOrganizationUser(
        Number(organizationId),
        Number(userId),
        updatedUser
      )
    } else {
      const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
      if (isAdmin) {
        if (organizationUser?.role !== role || organizationUser?.organizationId !== organization) {
          updatedUser = { ...updatedUser, role, organizationId: organization }
        }
        organizationStore.editAdminUser(Number(userId), updatedUser)
      }
    }
  }

  const cancel = () => navigate(-1)

  const archiveUser = () => {
    const { organizationId, userId } = params
    if (organizationId) {
      organizationStore.archiveOrganizationUser(
        Number(organizationId),
        Number(userId),
        closeActionConfirmation
      )
    } else {
      const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
      if (isAdmin) {
        organizationStore.archiveAdminUser(Number(userId), closeActionConfirmation)
      }
    }
  }

  const activateUser = () => {
    const { organizationId, userId } = params
    if (organizationId) {
      organizationStore.activateOrganizationUser(
        Number(organizationId),
        Number(userId),
        closeActionConfirmation
      )
    } else {
      const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
      if (isAdmin) {
        organizationStore.activateAdminUser(
          Number(userId),
          closeActionConfirmation
        )
      }
    }
  }

  const renderOrganizationSelect = () => {
    if (['superadmin', 'admin'].includes(user?.role) && role === 'field_admin') {
      return (
        <Select
          label={t('organization')}
          options={getOrganizationOptions()}
          value={organization}
          onChange={setOrganization}
        />
      )
    }
    return null
  }

  const renderBottomActionButton = () => {
    if (organizationUser?.archivedAt) {
      return (
        <Button
          text={t('activate_user')}
          onClick={openActivateConfirmation}
          variant='secondary'
          sx={styles.bottomActionButton}
        />
      )
    } else {
      return (
        <Button
          text={t('archive_user')}
          onClick={openArchiveConfirmation}
          variant='secondary'
          sx={styles.bottomActionButton}
        />
      )
    }
  }

  const renderConfirmationDialog = () => {
    if (showActionConfirmation) {
      return (
        <ConfirmationDialog
          title={t(`${actionType}_user`)}
          content={t(`${actionType}_user_confirmation`)}
          onClose={closeActionConfirmation}
          onAction={actionType === 'archive' ? archiveUser : activateUser}
          actionText={t(actionType)}
          loading={loading}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('user_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={firstName}
              onChange={setFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input label={t('last_name')} value={lastName} onChange={setLastName} />
          </Box>
          <Input
            label={t('email')}
            value={email}
            onChange={setEmail}
            mb='1.5rem'
            copy
            disabled
          />
          <Input
            label={t('phone')}
            value={phone}
            onChange={setPhone}
            maxWidth='20rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('permissions')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Select
              label={t('user_role')}
              options={getRoleOptions()}
              value={role}
              onChange={setRole}
              maxWidth='15rem'
              mr='2rem'
              disabled={!['superadmin', 'admin'].includes(user?.role)}
            />
            {renderOrganizationSelect()}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('save')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={getIsSaveDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
        {renderBottomActionButton()}
      </Box>
      {renderConfirmationDialog()}
    </Box>
  )
}

export default observer(User)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    overflowY: 'auto'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  bottomActionButton: {
    position: 'absolute',
    bottom: 0,
    width: '16rem',
    m: '0rem 2rem'
  }
} as const
