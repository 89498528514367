import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import Typography from '@mui/material/Typography'
import { validateNotificationInput } from '../../../Utils/validation'
import { t } from 'i18next'
import LangSelect from '../../../Components/Common/LangSelect'
import { Colors } from '../../../Utils/theme'
import TimeInput from '../../../Components/Common/TimeInput'

const CreateNotification = (props: any) => {
  const { sessionStore, fieldStore, notificationStore }: any = useStore()
  const { user } = sessionStore

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [lang, setLang] = useState('fi')
  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [titleSv, setTitleSv] = useState('')
  const [trackId, setTrackId] = useState(null)
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [contentSv, setContentSv] = useState('')
  const [htmlFi, setHtmlFi] = useState('')
  const [htmlEn, setHtmlEn] = useState('')
  const [htmlSv, setHtmlSv] = useState('')
  const [startDate, setStartDate] = useState<any>(null)
  const [startTime, setStartTime] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)

  useEffect(() => {
    if (sessionStore.user?.role === 'field_admin') {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
    }
  }, [])

  const getTrackOptions = () => {
    if (['field_admin']?.includes(user?.role)) {
      const tracks = flatten(fieldStore.fields?.map((field: any) => field?.tracks?.map((tracks: any) => ({ ...tracks, field }) || null).filter((track: any) => track) || []))
      return tracks.map((track: any) => {
        return { value: track.id, label: `${track?.field?.nameFi} - ${track?.nameFi}` }
      })
    }
    return []
  }

  const getIsSaveDisabled = () => {
    if (!trackId) {
      return true
    }
    const isValid = validateNotificationInput({
      titleFi,
      titleEn,
      titleSv,
      contentFi,
      contentEn,
      contentSv,
      htmlFi,
      htmlEn,
      htmlSv,
      startDate,
      startTime,
      endDate,
      endTime
    })
    return !isValid
  }

  const save = () => {
    const start = DateTime
      .fromFormat(`${startDate} ${startTime}`, `yyyy-MM-dd HH:mm`)
      .toJSDate()
    const end = DateTime
      .fromFormat(`${endDate} ${endTime}`, `yyyy-MM-dd HH:mm`)
      .toJSDate()

    const notification = {
      titleFi,
      titleEn,
      titleSv,
      contentFi,
      contentEn,
      contentSv,
      htmlFi,
      htmlEn,
      htmlSv,
      startTime: start,
      endTime: end
    }

    notificationStore.createTrackNotification(
      trackId,
      notification,
      cancel
    )
  }

  const cancel = () => navigate(-1)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('notification')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.langSelectContainer}>
            <LangSelect
              value={lang}
              onChange={setLang}
              status={{
                fi: titleFi && contentFi,
                en: titleEn && contentEn,
                sv: titleSv && contentSv
              }}
            />
          </Box>
          <Select
            label={t('track')}
            options={getTrackOptions()}
            value={trackId}
            onChange={setTrackId}
            mt='1.5rem'
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Box sx={styles.timesContainer}>
              <Input
                label={t('has_expiration_date')}
                value={startDate}
                onChange={setStartDate}
                width='10rem'
                type='date'
              />
              <TimeInput
                value={startTime}
                onChange={setStartTime}
                width='5.625rem'
                mt='1.75rem'
                mr='1rem'
              />
            </Box>
            <Box sx={styles.separator}>-</Box>
            <Box sx={styles.timesContainer}>
              <Input
                value={endDate}
                onChange={setEndDate}
                width='10rem'
                type='date'
                ml='1rem'
                mt='1.75rem'
              />
              <TimeInput
                value={endTime}
                onChange={setEndTime}
                width='5.625rem'
                mt='1.75rem'
              />
            </Box>
          </Box>
          <Input
            label={t('title')}
            value={lang === 'fi' ? titleFi : lang === 'en' ? titleEn : titleSv}
            onChange={lang === 'fi' ? setTitleFi : lang === 'en' ? setTitleEn : setTitleSv}
            mb='1.5rem'
          />
          <Input
            label={t('content')}
            value={lang === 'fi' ? contentFi : lang === 'en' ? contentEn : contentSv}
            onChange={lang === 'fi' ? setContentFi : lang === 'en' ? setContentEn : setContentSv}
            mb='1.5rem'
            multiline
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button
            text={t('create_notification')}
            onClick={save}
            width='100%'
            mb='1rem'
            disabled={getIsSaveDisabled()}
          />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateNotification)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem',
    pb: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto'
  },
  formContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '45rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.25rem 1.25rem 1.25rem 1.25rem'
  },
  langSelectContainer: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  separator: {
    marginTop: '1.625rem',
    fontSize: '1rem',
    fontWeight: 900,
    color: Colors.text60
  }
} as const
