import * as React from 'react'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import { styled } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import updateLocale from 'dayjs/plugin/updateLocale'
import isoWeek from 'dayjs/plugin/isoWeek'
import { Menu } from '@mui/material'
import { Button } from '..'
import { Colors } from '../../Utils/theme'

dayjs.extend(isoWeek)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  months: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
  weekStart: 1,
})

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false
  }
  if (dayA.year() !== dayB.year()) return false
  return dayA.isoWeek() === dayB.isoWeek()
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null
    hoveredDay?: Dayjs | null
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export default function WeekPicker(props: any) {
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null)
  const [value, setValue] = React.useState<Dayjs | null>(dayjs())

  const [anchorEl, setAnchorEl] = React.useState<any>(null)

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleSetValue = (value: any) => {
    handleClose()
    props.onChange(value.format('YYYY-MM-DDTHH:mm:ss'))
  }

  const getValue = () => {
    return dayjs(props.value)
  }

  const renderPicker = () => {
    return (
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={getValue()}
            onChange={(newValue) => handleSetValue(newValue)}
            showDaysOutsideCurrentMonth
            displayWeekNumber
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) =>
                ({
                  selectedDay: getValue(),
                  hoveredDay,
                  //onPointerEnter: () => setHoveredDay(ownerState.day),
                  //onPointerLeave: () => setHoveredDay(null),
                }) as any,
            }}
          />
        </LocalizationProvider>
      </Menu>
    )
  }

  const renderInput = () => {
    return (
      <>
        <Button
          onClick={handleOpen}
          text={props.formattedValue}
          sx={styles.button}
        />
        {renderPicker()}
      </>
    )
  }

  return (
    <>
      {renderInput()}
    </>
  )
}

const styles = {
  button: {
    minWidth: '13rem',
    fontWeight: 400,
    backgroundColor: 'transparent',
    color: Colors.text,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    border: `1px solid ${Colors.border}`
  }
}
