import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { Colors } from '../../Utils/theme'

const LangSelect = (props: any) => {
  const { value, status, onChange } = props

  const selectFi = () => onChange('fi')
  const selectEn = () => onChange('en')
  const selectSv = () => onChange('sv')

  return (
    <Box sx={styles.container}>
      <ButtonBase
        sx={value === 'fi' ? styles.activeButton : styles.button}
        onClick={selectFi}
      >
        FI
        <Box sx={status?.['fi'] ? styles.green : styles.red} />
      </ButtonBase>
      <ButtonBase
        sx={value === 'en' ? styles.activeButton : styles.button}
        onClick={selectEn}
      >
        EN
        <Box sx={status?.['en'] ? styles.green : styles.red} />
      </ButtonBase>
      <ButtonBase
        sx={value === 'sv' ? styles.activeButton : styles.button}
        onClick={selectSv}
      >
        SV
        <Box sx={status?.['sv'] ? styles.green : styles.red} />
      </ButtonBase>
    </Box>
  )
}

export default LangSelect

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    zIndex: 1
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
    padding: '0.375rem 1rem',
    backgroundColor: Colors.light,
    color: Colors.heading,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 900,
    borderRadius: '.3125rem',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: Colors.lightDarker
    }
  },
  activeButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.375rem 1rem',
    color: Colors.white,
    backgroundColor: Colors.brandPrimary,
    borderRadius: '.3125rem',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '0.875rem',
    fontWeight: 900,
    userSelect: 'none',
    '&:hover': {
      backgroundColor: Colors.brandPrimary90
    }
  },
  green: {
    marginLeft: '.5rem',
    backgroundColor: '#4DB974',
    height: '0.875rem',
    width: '0.875rem',
    borderRadius: '50%'
  },
  red: {
    marginLeft: '.5rem',
    backgroundColor: '#BD4849',
    height: '0.875rem',
    width: '0.875rem',
    borderRadius: '50%'
  }
}
