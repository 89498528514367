import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import CalendarIcon from '../../../Assets/Icons/calendar2.svg'
import AddIcon from '../../../Assets/Icons/add-white.svg'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import SquareButton from '../../../Components/Common/SquareButton'
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Fields = (props: any) => {
  const { sessionStore, fieldStore, organizationStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, totalFields } = fieldStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    organizationStore.getOrganizations()
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
    }
  }, [])

  const toOrganizationField = (organizationId: number, fieldId: number) => {
    return navigate(`/organizations/${organizationId}/fields/${fieldId}`)
  }
  const toOrganizationFieldTrack = (organizationId: number, fieldId: number, trackId: number) => {
    return navigate(`/organizations/${organizationId}/fields/${fieldId}/tracks/${trackId}`)
  }
  const toCreateField = () => navigate('/fields/create')
  const toCreateTrack = (organizationId: number, fieldId: number) => navigate(`/organizations/${organizationId}/fields/${fieldId}/tracks/create`)

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 260,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.tracks) {
          // Field
          return (
            <Button
              sx={styles.name}
              text={params.row.nameFi || params.row.nameEn || params.row.nameSv}
              onClick={() => toOrganizationField(params.row.organizationId, params.row.id)}
              variant='text'
            />
          )
        } else {
          // Track
          return (
            <Button
              sx={styles.name}
              text={` ${params.row.nameFi || params.row.nameEn || params.row.nameSv}`}
              onClick={() => toOrganizationFieldTrack(params.row.organizationId, params.row.fieldId, params.row.id)}
              variant='text'
            />
          )
        }
      }
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.tracks ? t('field') : t('track')
      }
    },
    {
      field: 'city',
      headerName: t('city'),
      flex: 1,
      maxWidth: 260,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.city || '-'
      }
    },
    {
      field: 'laneCount',
      headerName: t('lanes'),
      flex: 1,
      maxWidth: 140,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true
    },
    {
      field: 'trackClassification',
      headerName: t('classification'),
      flex: 1,
      maxWidth: 160,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true
    },
    {
      field: 'organizationName',
      headerName: t('organization'),
      minWidth: 200,
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Chip
            label={params.row?.organizationName || ''}
            variant='outlined'
            size='small'
            sx={styles.chip}
          />
        )
      }
    },
    /*
    {
      field: 'proshopEnabled',
      headerName: t('proshop'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params?.row?.tracks) {
          if (params?.row?.proShopEnabled) {
            return t('in_use')
          }
          return t('not_in_use')
        }
        return ''
      }
    },
    */
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.row.tracks) {
          return (
            <Box sx={styles.actions}>
              <SquareButton
                icon={CalendarIcon}
                onClick={() => toCalendar(params.row.organizationId, params.row.fieldId, params.row.id)}
                mr='1rem'
              />
            </Box>
          )
        } else {
          return (
            <Box sx={styles.actions}>
              <SquareButton
                icon={AddIcon}
                onClick={() => toCreateTrack(params.row.organizationId, params.row.id)}
                mr='1rem'
                blue
              />
            </Box>
          )
        }
      }
    }
  ], [language])

  const toCalendar = (organizationId: number, fieldId: number, trackId: number) => {
    navigate(`/organizations/${organizationId}/fields/${fieldId}/tracks/${trackId}/calendar`)
  }

  const getRowId = (item: any) => {
    if (item.tracks) return `field_${item.id}`
    return `track_${item.id}`
  }

  const rows = useMemo(() => {
    let fields = fieldStore.fields

    let combined = []
    for (const field of fields) {
      const organizationName = organizationStore.organizations
        .find((item: any) => item.id === field.organizationId)?.name
      combined.push({ ...field, organizationName })

      for (const track of field.tracks) {
        combined.push({ ...track, organizationName })
      }
    }

    const searchFields = ['nameFi', 'nameEn', 'nameSv', 'organizationName']

    if (searchKey) {
      combined = combined.filter((field: any) => {
        for (const key of searchFields) {
          if (field[key] && field[key]?.toLowerCase().includes(searchKey.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
    return combined
  }, [fieldStore.fields, organizationStore.organizations, searchKey, filters])

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={toCreateField}
      />
      <Table
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        loading={loading}
        fields
      />
    </Box>
  )
}

export default observer(Fields)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
