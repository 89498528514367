import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import SquareButton from '../../../Components/Common/SquareButton'
import { Button } from '../../../Components'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import CreatePaymentCodeModal from '../../../Components/Common/CreatePaymentCodeModal'
import CopyIcon from '../../../Assets/Icons/copy.svg'
import DeleteIcon from '../../../Assets/Icons/trash.svg'
import CheckIcon from '../../../Assets/Icons/verified.svg'
import { Colors } from '../../../Utils/theme'
import { showMessage } from '../../../Utils/message'

const PaymentCodes = (props: any) => {
  const { sessionStore, fieldStore, proShopStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, totalPaymentCodes } = proShopStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [showCreatePaymentCodeModal, setShowCreatePaymentCodeModal] = useState(false)
  const [paymentCode, setPaymentCode] = useState<any>(null)
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)
  const openCreatePaymentCodeModal = () => setShowCreatePaymentCodeModal(true)
  const closeCreatePaymentCodeModal = () => setShowCreatePaymentCodeModal(false)
  const openArchiveConfirmation = (paymentCode: any) => {
    setShowArchiveConfirmation(true)
    setPaymentCode(paymentCode)
  }
  const closeArchiveConfirmation = () => {
    setPaymentCode(null)
    setShowArchiveConfirmation(false)
  }

  useEffect(() => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      fieldStore.getAllFields()
      proShopStore.getPaymentCodes()
    } else {
      fieldStore.getOrganizationFields(sessionStore.user?.organizationId)
      proShopStore.getOrganizationPaymentCodes(sessionStore.user?.organizationId)
    }
  }, [])

  const copyToClipboard = (code: string) => () => {
    navigator.clipboard.writeText(code)
    showMessage(t('copied_to_clipboard'))
  }

  const markAsShared = (paymentCode: any) => {
    proShopStore.markPaymentCodeAsShared(
      paymentCode.track.organizationId,
      paymentCode.track.fieldId,
      paymentCode.trackId,
      paymentCode.id
    )
  }

  const toTrack = (track: any) => {
    navigate(`/organizations/${track.organizationId}/fields/${track.fieldId}/tracks/${track.id}`)
  }

  const createPaymentCodes = (organizationId: number, fieldId: number, trackId: number, data: any) => {
    proShopStore.createPaymentCodes(
      organizationId,
      fieldId,
      trackId,
      data,
      closeCreatePaymentCodeModal
    )
  }

  const archivePaymentCode = () => {
    const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)
    if (isAdmin) {
      proShopStore.archivePaymentCode(
        paymentCode.track.organizationId,
        paymentCode.track.fieldId,
        paymentCode.trackId,
        paymentCode.id,
        closeArchiveConfirmation
      )
    } else {
      proShopStore.archivePaymentCode(
        paymentCode.track.organizationId,
        paymentCode.track.fieldId,
        paymentCode.trackId,
        paymentCode.id,
        closeArchiveConfirmation
      )
    }
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'code',
      headerName: t('code'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={`${params.row.code.slice(0, 6)}...${params.row.code.slice(-6)}`}
            onClick={copyToClipboard(params.row.code)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'validUntil',
      headerName: t('valid_until'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        if (params.row?.validUntil) {
          const date = DateTime.fromISO(params.row.validUntil)
          return `${date.toFormat('dd.MM.yyyy')} ${t('at')} ${date.toFormat('HH.mm')}`
        }
        return t('for_now')
      }
    },
    {
      field: 'track',
      headerName: t('track'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            sx={styles.name}
            text={params.row.track?.nameFi || ''}
            onClick={() => toTrack(params.row?.track)}
            variant='text'
          />
        )
      }
    },
    {
      field: 'usedByMobileUser',
      headerName: t('user'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return `${params.value?.firstName || ''} ${params.value?.lastName || ''}`.trim()
        }
        return '-'
      }
    },
    {
      field: 'status',
      headerName: t('state'),
      flex: 1,
      maxWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        let text = params?.row?.usedAt ?
          t('used') :
          params?.row?.archivedAt ?
          t('archived') :
          params.row?.validUntil && DateTime.fromISO(params.row.validUntil) < DateTime.now() ?
          t('expired') :
          t('unused')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'shared',
      headerName: t('shared'),
      flex: 1,
      maxWidth: 140,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        let text = params?.row?.shared ?
          t('yes') :
          t('no')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 220,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Box sx={styles.actions}>
            <SquareButton
              icon={CopyIcon}
              onClick={copyToClipboard(params.row.code)}
              mr='1rem'
              blue
            />
            {(params.row?.archivedAt || params.row?.usedAt) ? null : (
              <SquareButton
                icon={DeleteIcon}
                onClick={() => openArchiveConfirmation(params.row)}
                mr={params?.row?.shared ? 0 : '1rem'}
                grey
              />
            )}
            {(params.row?.shared || params.row?.archivedAt || params.row?.usedAt) ? null : (
              <SquareButton
                icon={CheckIcon}
                onClick={() => markAsShared(params.row)}
                grey
              />
            )}
          </Box>
        )
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let paymentCodes = proShopStore.paymentCodes
    if (searchKey) {
      const search = searchKey.toLowerCase()
      paymentCodes = paymentCodes.filter((paymentCode: any) => paymentCode?.code?.toLowerCase().includes(search))
    }
    return paymentCodes
  }, [proShopStore.paymentCodes, searchKey, filters])

  const renderPaymentCodeModal = () => {
    if (showCreatePaymentCodeModal) {
      return (
        <CreatePaymentCodeModal
          onClose={closeCreatePaymentCodeModal}
          onCreate={createPaymentCodes}
          tracks={flatten(fieldStore.fields?.map((field: any) => field?.tracks || null).filter((track: any) => track) || [])}
        />
      )
    }
    return null
  }

  const renderArchiveConfirmation = () => {
    if (showArchiveConfirmation) {
      return (
        <ConfirmationDialog
          title={t('archive_payment_code')}
          content={t('archive_payment_code_confirmation')}
          onClose={closeArchiveConfirmation}
          onAction={archivePaymentCode}
          actionText={t('archive')}
        />
      )
    }
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={openCreatePaymentCodeModal}
        addText={t('create_new')}
      />
      <Table rows={rows} columns={columns} loading={loading} />
      {renderPaymentCodeModal()}
      {renderArchiveConfirmation()}
    </Box>
  )
}

export default observer(PaymentCodes)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    overflow: 'hidden',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
