import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

export enum Colors {
  transparent = 'transparent',
  black = '#000000',
  black90 = 'rgba(0, 0, 0, .9)',
  black80 = 'rgba(0, 0, 0, .8)',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
  navy = '#0B293F',
  white = '#FFFFFF',
  white90 = 'rgba(255, 255, 255, .9)',
  white80 = 'rgba(255, 255, 255, .8)',
  white70 = 'rgba(255, 255, 255, .7)',
  white60 = 'rgba(255, 255, 255, .6)',
  white50 = 'rgba(255, 255, 255, .5)',
  white40 = 'rgba(255, 255, 255, .4)',
  white30 = 'rgba(255, 255, 255, .3)',
  white20 = 'rgba(255, 255, 255, .2)',
  white10 = 'rgba(255, 255, 255, .1)',
  white05 = 'rgba(255, 255, 255, .05)',
  green = '#D5FB00',
  green90 = 'rgba(213, 251, 0, .9)',
  green80 = 'rgba(213, 251, 0, .8)',
  green70 = 'rgba(213, 251, 0, .7)',
  green60 = 'rgba(213, 251, 0, .6)',
  green50 = 'rgba(213, 251, 0, .5)',
  green40 = 'rgba(213, 251, 0, .4)',
  green30 = 'rgba(213, 251, 0, .3)',
  green20 = 'rgba(213, 251, 0, .2)',
  green10 = 'rgba(213, 251, 0, .1)',
  inputBg = 'rgba(216, 239, 72, .1)',
  heading = '#0F2E3C',
  heading60 = 'rgba(15, 46, 60, .6)',
  text = '#042940',
  text80 = 'rgba(4, 41, 64, .8)',
  text60 = 'rgba(4, 41, 64, .6)',
  darkText = '#4D4D4D',
  border = '#BDC1C3',
  darkBorder = '#A0ABB1',
  checkboxBg = '#F2F2F2',
  chip = '#E6EBED',
  chipDarker = '#D5E0E4',
  orange ='#F9986E',
  orangeDarker = '#F17E4A',
  orange90 = 'rgba(249, 152, 110, .9)',
  orange80 = 'rgba(249, 152, 110, .8)',
  brandBlue = '#12016C',
  brandBlue60 = 'rgba(18, 1, 108, 0.6)',
  light = '#E9FDFD',
  lightDarker = '#D5FDFD',
  brandPrimary = '#09006D',
  brandPrimaryDarker = '#07005A',
  brandPrimary90 = 'rgba(9, 0, 109, .9)',
  brandPrimary80 = 'rgba(9, 0, 109, .8)',
  brandPrimary60 = 'rgba(9, 0, 109, .6)',
  brandSecondary = '#0FF0F0',
  brandSecondary90 = 'rgba(15, 240, 240, 0.9)',
  brandSecondary80 = 'rgba(15, 240, 240, 0.8)',
  brandSecondary60 = 'rgba(15, 240, 240, 0.6)', 
  brandSecondary10 = 'rgba(15, 240, 240, 0.1)',
  cardBackground = '#E7FDFD',
  cardBackground70 = 'rgba(231, 253, 253, .7)'
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560
    }
  },
  palette: {
    primary: {
      main: Colors.brandBlue
    },
    secondary: {
      main: Colors.brandBlue
    },
    error: {
      main: Colors.brandBlue
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif !important',
    h1: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '2.5rem'
    },
    h2: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.875rem'
    },
    h3: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.625rem'
    },
    h4: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.375rem'
    },
    h5: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '1.125rem'
    },
    h6: {
      fontFamily: 'Poppins, sans-serif !important',
      color: Colors.heading,
      fontSize: '1rem'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: Colors.transparent,
          pl: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem'
          },
          pr: {
            xxl: '2rem',
            lg: '1.5rem',
            xs: '1rem'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.625rem',
          padding: '0.75rem 1.5rem',
          fontSize: '1rem',
          fontWeight: 700
        },
        text: {
          color: Colors.text
        }
      }
    },
    MuiTypography: {
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            opacity: 1
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.625rem',
          '&:hover': {
            backgroundColor: Colors.white10,
            pointer: 'cursor',
            '& .MuiListItemText-primary': {
              fontWeight: 700
            }
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0
        },
        primary: {
          color: Colors.white
        }
      }
    }
  }
})

export default theme
