import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class OrganizationStore {
  rootStore

  organizations: any = []
  totalOrganizations = 0
  organization: any = null
  organizationUsers: any = []
  totalOrganizationUsers = 0
  organizationUserInvites: any = []
  organizationUser: any = null
  users: any = []
  totalUsers = 0
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateOrganizations = (organizations: any) => { this.organizations = organizations }
  updateTotalOrganizations = (total: any) => { this.totalOrganizations = total }
  updateOrganization = (organization: any) => { this.organization = organization }
  updateOrganizationUsers = (users: any) => { this.organizationUsers = users }
  updateTotalOrganizationUsers = (total: any) => { this.totalOrganizationUsers = total }
  updateOrganizationUserInvites = (invites: any) => { this.organizationUserInvites = invites }
  updateOrganizationUser = (user: any) => { this.organizationUser = user }
  updateUsers = (users: any) => { this.users = users }
  updateTotalUsers = (total: any) => { this.totalUsers = total }
  updateLoading = (loading: boolean) => { this.loading = loading }

  async getOrganizations() {
    this.updateLoading(true)
    try {
      const isAdmin = ['superadmin', 'admin'].includes(this.rootStore.sessionStore.user?.role)
      if (isAdmin) {
        try {
          const params = {
            skip: 0,
            take: 10000
          }
          const response: any = await Api.getOrganizations(params)
          if (response.ok) {
            const organizations = response.data?.items || []
            const total = response.data?.total || 0
            this.updateOrganizations(organizations)
            this.updateTotalOrganizations(total)
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        // For non-admin user, we only fetch our organization
        const orgId = this.rootStore.sessionStore.user?.organizationId || null
        if (orgId) {
          const response: any = await Api.getOrganization(orgId)
          if (response.ok) {
            this.updateOrganizations([response.data])
            this.updateTotalOrganizations(1)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createOrganization(body: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganization(body)
      if (response.ok) {
        const organization = response.data ?? null
        this.updateOrganization(organization)
        showMessage(i18n.t('organization_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('organization_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async editOrganization(organizationId: number, body: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganization(organizationId, body)
      if (response.ok) {
        const organization = response.data ?? null
        this.updateOrganization(organization)
        showMessage(i18n.t('organization_updated'))
      } else {
        showMessage(i18n.t('organization_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganization(id: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getOrganization(id)
      if (response.ok) {
        const organization = response.data ?? null
        this.updateOrganization(organization)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getAllUsers() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getAllUsers()
      if (response.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.updateUsers(users)
        this.updateTotalUsers(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getAdminUser(userId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getAdminUser(userId)
      if (response.ok) {
        const user = response.data ?? null
        this.updateOrganizationUser(user)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getMyOrganizationUsers() {
    this.updateLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.getOrganizationUsers(organizationId)
      if (response.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.updateOrganizationUsers(users)
        this.updateTotalOrganizationUsers(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationUsers(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationUsers(organizationId)
      if (response.ok) {
        const users = response.data?.items || []
        const total = response.data?.total || 0
        this.updateOrganizationUsers(users)
        this.updateTotalOrganizationUsers(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationUser(organizationId: number, userId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getOrganizationUser(organizationId, userId)
      if (response.ok) {
        const user = response.data ?? null
        this.updateOrganizationUser(user)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async editOrganizationUser(organizationId: number, userId: number, payload: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationUser(organizationId, userId, payload)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {

    }
    this.updateLoading(false)
  }

  async editAdminUser(userId: number, payload: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateAdminUser(userId, payload)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_updated'))
      } else {
        showMessage(i18n.t('user_update_failed'), 'error')
      }
    } catch (e) {

    }
    this.updateLoading(false)
  }

  async createOrganizationUserInvite(organizationId: number, payload: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationUserInvite(organizationId, payload)
      if (response.ok) {
        showMessage(i18n.t('user_invited'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_invite_failed'), 'error')
      }
    } catch (e) {

    }
    this.updateLoading(false)
  }

  async createAdminUserInvite(payload: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createAdminUserInvite(payload)
      if (response.ok) {
        showMessage(i18n.t('user_invited'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_invite_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveOrganizationUser(organizationId: number, userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveOrganizationUser(organizationId, userId)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async activateOrganizationUser(organizationId: number, userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.activateOrganizationUser(organizationId, userId)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_activated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_activate_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveAdminUser(userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveAdminUser(userId)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async activateAdminUser(userId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.activateAdminUser(userId)
      if (response.ok) {
        const user = response.data
        this.updateOrganizationUser(user)
        showMessage(i18n.t('user_activated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('user_activate_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
