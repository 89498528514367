import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { CountryOptions, FinnishMunicipalityOptions } from '../../../Utils/constants'

const Organization = (props: any) => {
  const { sessionStore, organizationStore }: any = useStore()
  const { loading } = organizationStore
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('Suomi')
  const [accountNumber, setAccountNumber] = useState('')
  const [vatRegistered, setVatRegistered] = useState('')
  const [contactPersonFirstName, setContactPersonFirstName] = useState('')
  const [contactPersonLastName, setContactPersonLastName] = useState('')
  const [contactPersonEmail, setContactPersonEmail] = useState('')
  const [contactPersonPhone, setContactPersonPhone] = useState('')

  const getVatRegisteredOptions = () => {
    return [
      { value: true, label: t('yes') },
      { value: false, label: t('no') }
    ]
  }

  const create = () => {
    const callback = () => navigate(-1)
    const newOrganization = {
      name,
      businessId,
      streetAddress,
      postalCode,
      city,
      country
    }
    organizationStore.createOrganization(newOrganization, callback)
  }
  const cancel = () => navigate(-1)

  const renderCity = () => {
    /*
    if (country === 'Suomi') {
      return (
        <Select
          label={t('city')}
          options={FinnishMunicipalityOptions}
          value={city}
          onChange={setCity}
        />
      )
    } else {
      return <Input label={t('city')} value={city} onChange={setCity} />
    }
    */
    return <Input label={t('city')} value={city} onChange={setCity} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('organization_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('organization_name')}
              value={name}
              onChange={setName}
              mr='2rem'
            />
            <Input
              label={t('business_id')}
              value={businessId}
              onChange={setBusinessId}
              maxWidth='12rem'
            />
          </Box>
          <Input
            label={t('street_address')}
            value={streetAddress}
            onChange={setStreetAddress}
            mb='1.5rem'
          />
          <Box sx={styles.row}>
            <Input
              label={t('postal_code')}
              value={postalCode}
              onChange={setPostalCode}
              maxWidth='12rem'
              mr='2rem'
            />
            {renderCity()}
          </Box>
          <Select
            label={t('country')}
            options={CountryOptions}
            value={country}
            onChange={setCountry}
            maxWidth='20rem'
            mb='1.5rem'
          />
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('invoicing_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('account_number')}
              value={accountNumber}
              onChange={setAccountNumber}
              mr='2rem'
              copy
            />
            <Select
              label={t('vat_registered')}
              options={getVatRegisteredOptions()}
              value={vatRegistered}
              onChange={setVatRegistered}
              maxWidth='12rem'
            />
          </Box>
        </Box>
        <Typography variant='h3' mt='2.5rem' mb='1rem'>{t('contact_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Box sx={styles.row}>
            <Input
              label={t('first_name')}
              value={contactPersonFirstName}
              onChange={setContactPersonFirstName}
              maxWidth='15rem'
              mr='2rem'
            />
            <Input
              label={t('last_name')}
              value={contactPersonLastName}
              onChange={setContactPersonLastName}
            />
          </Box>
          <Box sx={styles.row}>
            <Input
              label={t('email')}
              value={contactPersonEmail}
              onChange={setContactPersonEmail}
              mr='2rem'
              copy
            />
            <Input
              label={t('phone')}
              value={contactPersonPhone}
              onChange={setContactPersonPhone}
              maxWidth='14rem'
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('create_organization')} onClick={create} width='100%' mb='1rem' />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(Organization)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // maxWidth: '45rem',
    height: '100vh',
    overflowY: 'auto',
    paddingBottom: '20rem'
  },
  formContainer: {
    maxWidth: '45rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  }
} as const
