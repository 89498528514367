import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from './Button'
import ReceiptIcon from '../../Assets/Icons/receipt.svg'
import { Colors } from '../../Utils/theme'

export default function ReportSummaryModal(props: any) {
  const { onClose, summary } = props
  const { t } = useTranslation()

  const renderRow = (label: string, value: any) => {
    return (
      <Box sx={styles.infoRow}>
        <Typography sx={styles.label}>{label ?? ''}</Typography>
        <Typography sx={styles.value}>{value || value === 0 ? value : 0} kpl</Typography>
      </Box>
    )
  }

  console.log(summary)

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Box sx={styles.titleRow}>
        <DialogTitle variant='h2' sx={styles.title}>{t('summary')}</DialogTitle>
      </Box>
      <DialogContent sx={styles.content}>
        {renderRow(t('total_reserved_slots'), summary?.appReservedSlots + summary?.systemReservedSlots)}
        {renderRow(t('app_reserved_slots'), summary?.appReservedSlots)}
        {renderRow(t('system_reserved_slots'), summary?.systemReservedSlots)}
        <Box style={styles.hr} />
        {renderRow(t('purchased_payment_codes'), summary?.appPaymentCodes)}
        {renderRow(t('created_payment_codes'), summary?.createdAdminPaymentCodes)}
        {renderRow(t('activated_payment_codes'), summary?.activatedAdminPaymentCodes)}
        <Box style={styles.hr} />
        {renderRow(t('purchased_season_passes'), summary?.appSeasonPasses)}
        {renderRow(t('created_season_passes'), summary?.createdAdminSeasonPasses)}
        {renderRow(t('activated_season_passes'), summary?.activatedAdminSeasonPasses)}
        <Box style={styles.hr} />
        {renderRow(t('product_purchases'), summary?.totalProducts)}
        <Box style={styles.hr} />
        {renderRow(t('support_payments'), summary?.totalSupportPayments)}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.button}
          text={t('close')}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '36rem',
      width: '36rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.375rem'
  },
  title: {
    color: Colors.heading
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // minHeight: '15.5rem',
    maxHeight: '70vh',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    p: '2rem 2rem 1.5rem 2rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  label: {
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '1rem'
  },
  value: {
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 400
  },
  hr: {
    borderBottom: `1px solid ${Colors.border}`,
    marginTop: '0.5rem',
    marginBottom: '1rem'
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    width: '11rem',
    fontSize: '1.125rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    border: 0,
    mt: '1rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
