import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class ProShopStore {
  rootStore

  discountCodes: any = []
  totalDiscountCodes = 0
  discountCode: any = null
  paymentCodes: any = []
  totalPaymentCodes = 0
  paymentCode: any = null
  seasonPasses: any = []
  totalSeasonPasses = 0
  seasonPass: any = null
  events: any = []
  totalEvents = 0
  event: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateDiscountCodes = (discountCodes: any) => { this.discountCodes = discountCodes }
  updateTotalDiscountCodes = (totalDiscountCodes: any) => { this.totalDiscountCodes = totalDiscountCodes }
  updateDiscountCode = (discountCode: any) => { this.discountCode = discountCode }
  updatePaymentCodes = (paymentCodes: any) => { this.paymentCodes = paymentCodes }
  updateTotalPaymentCodes = (totalPaymentCodes: any) => { this.totalPaymentCodes = totalPaymentCodes }
  updatePaymentCode = (paymentCode: any) => { this.paymentCode = paymentCode }
  updateSeasonPasses = (seasonPasses: any) => { this.seasonPasses = seasonPasses }
  updateTotalSeasonPasses = (totalSeasonPasses: any) => { this.totalSeasonPasses = totalSeasonPasses }
  updateSeasonPass = (seasonPass: any) => { this.seasonPass = seasonPass }
  updateEvents = (events: any) => { this.events = events }
  updateTotalEvents = (totalEvents: any) => { this.totalEvents = totalEvents }
  updateEvent = (event: any) => { this.event = event }
  updateLoading = (loading: boolean) => { this.loading = loading }

  async getDiscountCodes() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getDiscountCodes()
      if (response.ok) {
        const discountCodes = response.data?.items || []
        const total = response.data?.total || 0
        this.updateDiscountCodes(discountCodes)
        this.updateTotalDiscountCodes(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationDiscountCodes(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationDiscountCodes(organizationId)
      if (response.ok) {
        const discountCodes = response.data?.items || []
        const total = response.data?.total || 0
        this.updateDiscountCodes(discountCodes)
        this.updateTotalDiscountCodes(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createDiscountCode(organizationId: number, fieldId: number, data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationDiscountCode(organizationId, fieldId, data)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getDiscountCodes()
        } else {
          this.getOrganizationDiscountCodes(organizationId)
        }
        showMessage(i18n.t('discount_code_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveDiscountCode(organizationId: number, fieldId: number, codeId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveOrganizationDiscountCode(organizationId, fieldId, codeId)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getDiscountCodes()
        } else {
          this.getOrganizationDiscountCodes(organizationId)
        }
        showMessage(i18n.t('discount_code_archived'))
        
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getPaymentCodes() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPaymentCodes()
      if (response.ok) {
        const paymentCodes = response.data?.items || []
        const total = response.data?.total || 0
        this.updatePaymentCodes(paymentCodes)
        this.updateTotalPaymentCodes(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationPaymentCodes(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationPaymentCodes(organizationId)
      if (response.ok) {
        const paymentCodes = response.data?.items || []
        const total = response.data?.total || 0
        this.updatePaymentCodes(paymentCodes)
        this.updateTotalPaymentCodes(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createPaymentCodes(organizationId: number, fieldId: number, trackId: number, data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationPaymentCodes(organizationId, fieldId, trackId, data)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getPaymentCodes()
        } else {
          this.getOrganizationPaymentCodes(organizationId)
        }
        showMessage(i18n.t('payment_codes_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archivePaymentCode(organizationId: number, fieldId: number, trackId: number, codeId: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveOrganizationPaymentCode(organizationId, fieldId, trackId, codeId)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getPaymentCodes()
        } else {
          this.getOrganizationPaymentCodes(organizationId)
        }
        showMessage(i18n.t('payment_code_archived'))

        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async markPaymentCodeAsShared(organizationId: number, fieldId: number, trackId: number, codeId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationPaymentCode(
        organizationId,
        fieldId,
        trackId,
        codeId,
        { shared: true }
      )
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getPaymentCodes()
        } else {
          this.getOrganizationPaymentCodes(organizationId)
        }
        showMessage(i18n.t('payment_code_marked_as_shared'))
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getSeasonPasses() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getSeasonPasses()
      if (response.ok) {
        const seasonPasses = response.data?.items || []
        const total = response.data?.total || 0
        this.updateSeasonPasses(seasonPasses)
        this.updateTotalSeasonPasses(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationSeasonPasses(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationSeasonPasses(organizationId)
      if (response.ok) {
        const seasonPasses = response.data?.items || []
        const total = response.data?.total || 0
        this.updateSeasonPasses(seasonPasses)
        this.updateTotalSeasonPasses(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createSeasonPasses(organizationId: number, fieldId: number, data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationSeasonPasses(organizationId, fieldId, data)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getSeasonPasses()
        } else {
          this.getOrganizationSeasonPasses(organizationId)
        }
        showMessage(i18n.t('season_passes_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveSeasonPass(organizationId: number, fieldId: number, id: number, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveOrganizationSeasonPass(organizationId, fieldId, id)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getSeasonPasses()
        } else {
          this.getOrganizationSeasonPasses(organizationId)
        }
        showMessage(i18n.t('season_pass_archived'))

        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getEvents() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getProShopEvents()
      if (response.ok) {
        const events = response.data?.items || []
        const total = response.data?.total || 0
        this.updateEvents(events)
        this.updateTotalEvents(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationEvents(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationProShopEvents(organizationId)
      if (response.ok) {
        const events = response.data?.items || []
        const total = response.data?.total || 0
        this.updateEvents(events)
        this.updateTotalEvents(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

}
