import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from './Models/RootStore'
import { Authenticated, Unauthenticated } from './Containers'
import { ScrollToTop } from './Components'
import { SnackbarProvider } from 'notistack'

const App = () => {
  const { sessionStore }: any = useStore()
  const { initialized, isLoggedIn } = sessionStore

  useEffect(() => {
    sessionStore.getMe()
  }, [])

  const renderRouter = () => {
    if (!initialized) return null
    if (isLoggedIn) {
      return <Authenticated />
    }
    return <Unauthenticated />
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} />
      {renderRouter()}
    </BrowserRouter>
  )
}

export default observer(App)