import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class MessageStore {
  rootStore

  messages: any = []
  totalMessages = 0
  message: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateMessages = (messages: any) => { this.messages = messages }
  updateTotalMessages = (total: any) => { this.totalMessages = total }
  updateMessage = (message: any) => { this.message = message }
  updateLoading = (loading: boolean) => { this.loading = loading }

  async getMessages() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getMessages()
      if (response.ok) {
        const messages = response.data?.items || []
        const total = response.data?.total || 0
        this.updateMessages(messages)
        this.updateTotalMessages(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationTrackMessages() {
    this.updateLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.getOrganizationTrackMessages(organizationId)
      if (response.ok) {
        const messages = response.data?.items || []
        const total = response.data?.total || 0
        this.updateMessages(messages)
        this.updateTotalMessages(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getMessage(id: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getMessage(id)
      if (response.ok) {
        this.updateMessage(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationTrackMessage(trackId: number, messageId: number) {
    this.updateLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.getOrganizationTrackMessage(organizationId, trackId, messageId)
      if (response.ok) {
        this.updateMessage(response.data)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createMessage(data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createMessage(data)
      if (response.ok) {
        this.getMessages()
        showMessage(i18n.t('message_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateMessageInfo(data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateMessage(this?.message?.id, data)
      if (response.ok) {
        this.getMessages()
        showMessage(i18n.t('message_updated'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createTrackMessage(organizationId: number, trackId: number, data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationTrackMessage(organizationId, trackId, data)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getMessages()
        } else {
          this.getOrganizationTrackMessages()
        }
        showMessage(i18n.t('message_created'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateTrackMessage(organizationId: number, trackId: number, data: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationTrackMessage(organizationId, trackId, this?.message?.id, data)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getMessages()
        } else {
          this.getOrganizationTrackMessages()
        }
        showMessage(i18n.t('message_updated'))
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveMessage(id: number) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveMessage(id)
      if (response.ok) {
        this.getMessages()
        showMessage(i18n.t('message_archived'))
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveTrackMessage(trackId: number, id: number) {
    this.updateLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response = await Api.archiveOrganizationTrackMessage(organizationId, trackId, id)
      if (response.ok) {
        if (this.rootStore.sessionStore.user?.role === 'admin') {
          this.getMessages()
        } else {
          this.getOrganizationTrackMessages()
        }
        showMessage(i18n.t('message_archived'))
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
