import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { Typography } from '@mui/material'

const CreateField = (props: any) => {
  const { sessionStore, fieldStore, organizationStore }: any = useStore()
  const { user } = sessionStore
  const { field, fieldTracks } = fieldStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [nameSv, setNameSv] = useState('')
  const [organization, setOrganization] = useState('')
  const [proShopEnabled, setProShopEnabled] = useState(false)

  useEffect(() => {
    if (!organizationStore.organizations.length) {
      organizationStore.getOrganizations()
    }
  }, [])

  const getOrganizationOptions = () => {
    if (organizationStore.organizations?.length) {
      return organizationStore.organizations.map((item: any) => ({
        value: item.id,
        label: item.name
      }))
    }
    return []
  }

  useEffect(() => {
    organizationStore.getOrganizations()
  }, [])

  const create = () => {
    const callback = () => navigate(-1)

    const newField = {
      nameFi,
      nameEn: nameFi, // TODO: add localization
      nameSv: nameFi, // TODO: add localization
      proShopEnabled
    }

    let organizationId = null
    const isAdmin = ['superadmin', 'admin'].includes(user?.role)

    if (isAdmin) {
      organizationId = organization
    } else {
      // Use the user's organization id
      organizationId = user?.organizationId
    }

    if (organizationId) {
      fieldStore.createOrganizationField(organizationId, newField, callback)
    }
  }
  const cancel = () => navigate(-1)

  const getProShopOptions = () => {
    return [
      { value: true, label: t('in_use') },
      { value: false, label: t('not_in_use') }
    ]
  }

  const isCreateDisabled = () => {
    if (!nameFi) return true
    const isAdmin = ['superadmin', 'admin'].includes(user?.role)
    if (isAdmin && !organization) return true
    return false
  }

  const renderOrganizationSelect = () => {
    if (['superadmin', 'admin'].includes(user?.role)) {
      return (
        <Select
          label={t('organization')}
          options={getOrganizationOptions()}
          value={organization}
          onChange={setOrganization}
          maxWidth='30rem'
          mb='1.5rem'
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('fill_field_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('name')}
            value={nameFi}
            onChange={setNameFi}
            mb='1.5rem'
          />
          {renderOrganizationSelect()}
          <Select
            label={t('proshop')}
            options={getProShopOptions()}
            value={proShopEnabled}
            onChange={setProShopEnabled}
            width='16rem'
            mb='1.5rem'
          />
        </Box>
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('create_field')} onClick={create} width='100%' mb='1rem' disabled={isCreateDisabled()} />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%' />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(CreateField)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: '2rem',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45rem',
    // height: '100vh',
    overflowY: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '3rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      color: Colors.text80
    }
  }
} as const
