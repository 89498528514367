import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class NotificationStore {
  rootStore

  trackNotifications: any = []
  totalTrackNotifications = 0
  trackNotification: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setTrackNotifications = (notifications: any) => { this.trackNotifications = notifications }
  setTotalTrackNotifications = (totalNotifications: number) => { this.totalTrackNotifications = totalNotifications }
  setTrackNotification = (notification: any) => { this.trackNotification = notification }
  setLoading = (loading: boolean) => { this.loading = loading }

  async getTrackNotifications() {
    this.setLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.getOrganizationTrackNotifications(organizationId)
      if (response.ok) {
        const notifications = response.data?.items || []
        const total = response.data?.total || 0
        this.setTrackNotifications(notifications)
        this.setTotalTrackNotifications(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getOrganizationTrackNotification(trackId: number, notificationId: number) {
    this.setLoading(true)
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.getOrganizationTrackNotification(
        organizationId,
        trackId,
        notificationId
      )
      if (response.ok) {
        const notification = response?.data || null
        this.setTrackNotification(notification)
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createTrackNotification(trackId: number, data: any, callback: any) {
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.createOrganizationTrackNotification(
        organizationId,
        trackId,
        data
      )
      if (response.ok) {
        showMessage(i18n.t('notification_created'))
        callback()
      } else {
        showMessage(i18n.t('notification_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async updateTrackNotification(trackId: number, data: any, callback: any) {
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.updateOrganizationTrackNotification(
        organizationId,
        trackId,
        this.trackNotification?.id,
        data
      )
      if (response.ok) {
        showMessage(i18n.t('notification_updated'))
        callback()
      } else {
        showMessage(i18n.t('notification_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async archiveTrackNotification(trackId: number, notificationId: number) {
    try {
      const organizationId = this.rootStore.sessionStore.user?.organizationId
      const response: any = await Api.archiveOrganizationTrackNotification(
        organizationId,
        trackId,
        notificationId
      )
      if (response.ok) {
        showMessage(i18n.t('notification_archived'))
        this.getTrackNotifications()
      } else {
        showMessage(i18n.t('notification_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
  }
}
