import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import TableActions from '../../../Components/Common/TableActions'
import Table from '../../../Components/Common/Table'
import SeasonModal from '../../../Components/Common/SeasonModal'
import SquareButton from '../../../Components/Common/SquareButton'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import DeleteIcon from '../../../Assets/Icons/trash.svg'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, Input, Select } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Field = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { sessionStore, fieldStore, organizationStore }: any = useStore()
  const { loading, field, fieldTracks } = fieldStore

  const [nameFi, setNameFi] = useState('')
  const [nameEn, setNameEn] = useState('')
  const [nameSv, setNameSv] = useState('')
  const [proShopEnabled, setProShopEnabled] = useState(false)
  const [seasonPassEnabled, setSeasonPassEnabled] = useState(false)

  // Selected season
  const [showCreateSeasonModal, setShowCreateSeasonModal] = useState(false)
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
  const [season, setSeason] = useState<any>(null)

  const openCreateSeasonModal = () => setShowCreateSeasonModal(true)
  const closeCreateSeasonModal = () => setShowCreateSeasonModal(false)

  const openEditSeasonModal = (season: any) => setSeason(season)
  const closeEditSeasonModal = () => setSeason(null)
  
  const openArchiveConfirmation = (season: any) => {
    setSeason(season)
    setShowArchiveConfirmation(true)
  }
  const closeArchiveConfirmation = () => {
    setSeason(null)
    setShowArchiveConfirmation(false)
  }

  useEffect(() => {
    const organizationId = params?.organizationId ?? null
    const fieldId = params?.fieldId ?? null

    organizationStore.getOrganizations()

    if (fieldId) {
      fieldStore.getOrganizationField(Number(organizationId), Number(fieldId))
    }
  }, [])

  useEffect(() => {
    if (field) {
      setNameFi(field?.nameFi || '')
      setNameEn(field?.nameEn || '')
      setNameSv(field?.nameSv || '')
      setProShopEnabled(field?.proShopEnabled || false)
      setSeasonPassEnabled(field?.seasonPassEnabled || false)
    }
  }, [field])

  const getOrganizationName = () => {
    const match = organizationStore.organizations.find((item: any) => item?.id === Number(params.organizationId))
    if (match) {
      return match.name
    }
    return null
  }

  const getProShopOptions = () => {
    return [
      { value: true, label: t('in_use') },
      { value: false, label: t('not_in_use') }
    ]
  }

  const getSeasonPassEnabledOptions = () => {
    return [
      { value: true, label: t('in_use') },
      { value: false, label: t('not_in_use') }
    ]
  }

  const seasonColumns: GridColDef[] = useMemo(() => [
    {
      field: 'nameFi',
      headerName: t('name'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.nameFi}
          onClick={() => openEditSeasonModal(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'startTime',
      headerName: t('time_range'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        // If years are different, show both years
        if (DateTime.fromISO(params.row.startTime).year !== DateTime.fromISO(params.row.endTime).year) {
          return `${DateTime.fromISO(params.row.startTime).toFormat('dd.MM.yyyy')}-${DateTime.fromISO(params.row.endTime).toFormat('dd.MM.yyyy')}`
        } else {
          return `${DateTime.fromISO(params.row.startTime).toFormat('dd.MM.')}-${DateTime.fromISO(params.row.endTime).toFormat('dd.MM.yyyy')}`
        }
      }
    },
    {
      field: 'availableForSaleFromTime',
      headerName: t('on_sale'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams<any>) => {
        if (params.row.availableForSaleFromTime && params.row.availableForSaleUntilTime) {
          // If years are different, show both years
          if (DateTime.fromISO(params.row.availableForSaleFromTime).year !== DateTime.fromISO(params.row.availableForSaleUntilTime).year) {
            return `${DateTime.fromISO(params.row.availableForSaleFromTime).toFormat('dd.MM.yyyy')}-${DateTime.fromISO(params.row.availableForSaleUntilTime).toFormat('dd.MM.yyyy')}`
          } else {
            return `${DateTime.fromISO(params.row.availableForSaleFromTime).toFormat('dd.MM.')}-${DateTime.fromISO(params.row.availableForSaleUntilTime).toFormat('dd.MM.yyyy')}`
          }
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 160,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <SquareButton
          icon={DeleteIcon}
          onClick={() => openArchiveConfirmation(params.row)}
          grey
        />
      )
    }
  ], [field])

  const trackColumns: GridColDef[] = useMemo(() => [
    {
      field: 'nameFi',
      headerName: t('name'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.nameFi}
          onClick={() => toOrganizationFieldTrack(params.row.organizationId, params.row.fieldId, params.row.id)}
          variant='text'
        />
      )
    },
    {
      field: 'laneCount',
      headerName: t('lanes'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true
    },
    {
      field: 'trackClassification',
      headerName: t('classification'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true
    }
  ], [field])

  const rows = useMemo(() => fieldTracks || [], [fieldTracks])

  const toOrganizationFieldTrack = (organizationId: number, fieldId: number, trackId: number) => navigate(`/organizations/${organizationId}/fields/${fieldId}/tracks/${trackId}`)
  const toCreateTrack = () => navigate(`/organizations/${params.organizationId}/fields/${params.fieldId}/tracks/create`)

  const updateSeason = (updatedSeason: any) => {
    const { organizationId, fieldId } = params
    fieldStore.updateOrganizationFieldSeason(
      Number(organizationId),
      Number(fieldId),
      Number(season.id),
      updatedSeason,
      closeEditSeasonModal
    )
  }

  const createSeason = (season: any) => {
    const { organizationId, fieldId } = params
    fieldStore.createOrganizationFieldSeason(
      Number(organizationId),
      Number(fieldId),
      season,
      closeCreateSeasonModal
    )
  }

  const archiveSeason = () => {
    if (season) {
      const { organizationId, fieldId } = params
      fieldStore.archiveOrganizationFieldSeason(Number(organizationId), Number(fieldId), season.id)
      closeArchiveConfirmation()
    }
  }

  const save = () => {
    const newField = {
      nameFi,
      nameEn: nameEn || null,
      nameSv: nameSv || null,
      proShopEnabled,
      seasonPassEnabled: proShopEnabled ? seasonPassEnabled : false
    }
    fieldStore.editOrganizationField(Number(params.organizationId), Number(params.fieldId), newField)
  }
  const cancel = () => navigate(-1)

  const renderSeasonPassEnabledSelect = () => {
    if (proShopEnabled) {
      return (
        <Select
          label={t('season_pass_enabled')}
          options={getSeasonPassEnabledOptions()}
          value={seasonPassEnabled}
          onChange={setSeasonPassEnabled}
          width='16rem'
          ml='2rem'
        />
      )
    }
    return null
  }

  const renderSeasons = () => {
    if (!field) {
      return null
    }

    if (!field?.seasons?.length) {
      return (
        <Box sx={styles.noSeasonsContainer}>
          <Typography variant='body1'>{t('seasons_settings_info')}</Typography>
          <Button
            text={t('add_new')}
            onClick={openCreateSeasonModal}
            width='10rem'
            mt='1.5rem'
          />
        </Box>
      )
    }
    return (
      <>
        <TableActions addText={t('add_season')} onAddClick={openCreateSeasonModal} />
        <Table rows={field.seasons} columns={seasonColumns} />
      </>
    )
  }

  const renderSeasonModal = () => {
    if (showCreateSeasonModal) {
      return (
        <SeasonModal
          onClose={closeCreateSeasonModal}
          onCreate={createSeason}
          proShopEnabled={!!field?.proShopEnabled}
          seasonPassEnabled={!!field?.seasonPassEnabled}
          loading={loading}
        />
      )
    }
    if (showArchiveConfirmation && season) {
      return (
        <ConfirmationDialog
          title={t('archive_season')}
          content={t('archive_season_confirmation')}
          onClose={closeArchiveConfirmation}
          cancelText={t('cancel')}
          onAction={archiveSeason}
          actionText={t('archive')}
        />
      )
    }
    if (season) {
      return (
        <SeasonModal
          onClose={closeEditSeasonModal}
          onCreate={updateSeason}
          proShopEnabled={!!field?.proShopEnabled}
          seasonPassEnabled={!!field?.seasonPassEnabled}
          loading={loading}
          season={season}
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Typography variant='h1' mb='1.5rem'>{t('field_information')}</Typography>
        <Box sx={styles.formContainer}>
          <Input
            label={t('name')}
            value={nameFi}
            onChange={setNameFi}
            mb='1.5rem'
          />
          <Input
            label={t('organization')}
            value={getOrganizationName()}
            onChange={() => null}
            width='24rem'
            mb='1.5rem'
            disabled
          />
          <Box sx={styles.row}>
            <Select
              label={t('proshop')}
              options={getProShopOptions()}
              value={proShopEnabled}
              onChange={setProShopEnabled}
              width='16rem'
            />
            {renderSeasonPassEnabledSelect()}
          </Box>
        </Box>
        <Typography variant='h2' mt='2.5rem' mb='1rem'>{t('seasons_settings')}</Typography>
        {renderSeasons()}
        <Typography variant='h2' mt='2rem' mb='1rem'>{t('tracks')}</Typography>
        <TableActions onAddClick={toCreateTrack} />
        <Table rows={rows} columns={trackColumns} />
      </Box>
      <Box sx={styles.rightColumn}>
        <Typography variant='h2' mb='1rem'>{t('actions')}</Typography>
        <Box sx={styles.actionsContainer}>
          <Button text={t('save')} onClick={save} width='100%' mb='0.75rem' />
          <Button text={t('cancel')} onClick={cancel} variant='text' width='100%'  />
        </Box>
      </Box>
      {renderSeasonModal()}
    </Box>
  )
}

export default observer(Field)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '10rem',
    pt: '2rem',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '48rem',
    // height: '100vh',
    overflowY: 'auto'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem 2rem 0.5rem 2rem'
  },
  noSeasonsContainer: {
    backgroundColor: Colors.brandSecondary10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '2rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    top: '2rem',
    ml: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  }
} as const
