import { createContext, useContext } from 'react'
import SessionStore from './SessionStore'
import OrganizationStore from './OrganizationStore'
import FieldStore from './FieldStore'
import SlotStore from './SlotStore'
import ProShopStore from './ProShopStore'
import MessageStore from './MessageStore'
import FeedbackStore from './FeedbackStore'
import NotificationStore from './NotificationStore'
import ReportStore from './ReportStore'

class RootStore {
  sessionStore
  organizationStore
  fieldStore
  slotStore
  proShopStore
  messageStore
  feedbackStore
  notificationStore
  reportStore

  constructor() {
    this.sessionStore = new SessionStore(this)
    this.organizationStore = new OrganizationStore(this)
    this.fieldStore = new FieldStore(this)
    this.slotStore = new SlotStore(this)
    this.proShopStore = new ProShopStore(this)
    this.messageStore = new MessageStore(this)
    this.feedbackStore = new FeedbackStore(this)
    this.notificationStore = new NotificationStore(this)
    this.reportStore = new ReportStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)
