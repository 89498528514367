import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import Chip from '@mui/material/Chip'
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { Button } from '../../../Components'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import SquareButton from '../../../Components/Common/SquareButton'
import EditIcon from '../../../Assets/Icons/edit.svg'
import TrashIcon from '../../../Assets/Icons/trash.svg'
import { Colors } from '../../../Utils/theme'

const Notifications = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { sessionStore, notificationStore }: any = useStore()
  const { language } = sessionStore
  const { loading } = notificationStore

  // Table states
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [archiveRow, setArchiveRow] = useState<any>(null)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    notificationStore.getTrackNotifications()
  }, [])

  const handleArchive = () => {
    if (archiveRow) {
      notificationStore.archiveTrackNotification(archiveRow?.trackId, archiveRow?.id)
      setArchiveRow(null)
    }
  }
  const toNotification = (row: any) => navigate(`/tracks/${row.trackId}/notifications/${row.id}`)
  const toCreateNotification = () => navigate('/track-notifications/create')

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'titleFi',
      headerName: t('title'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.titleFi}
          onClick={() => toNotification(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'startTime',
      headerName: t('validity'),
      flex: 1,
      maxWidth: 240,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        // If years are different, show both years
        if (DateTime.fromISO(params.row.startTime).year !== DateTime.fromISO(params.row.endTime).year) {
          return `${DateTime.fromISO(params.row.startTime).toFormat('dd.MM.yyyy')}-${DateTime.fromISO(params.row.endTime).toFormat('dd.MM.yyyy')}`
        } else {
          return `${DateTime.fromISO(params.row.startTime).toFormat('dd.MM.')}-${DateTime.fromISO(params.row.endTime).toFormat('dd.MM.yyyy')}`
        }
      }
    },
    {
      field: 'track',
      headerName: t('track'),
      flex: 1,
      maxWidth: 240,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => params.row.track?.nameFi || ''
    },
    {
      field: 'archivedAt',
      headerName: t('state'),
      flex: 1,
      maxWidth: 180,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const now = DateTime.now()
        let text = params?.row?.archivedAt ?
          t('archived') :
          params.row?.endTime && DateTime.fromISO(params.row.endTime) < now ?
          t('ended') :
          t('active')
        return <Chip label={text} variant='outlined' size='small' sx={styles.chip} />
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 240,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actionsContainer}>
          <SquareButton
            icon={EditIcon}
            onClick={() => toNotification(params.row)}
            iconSize='1.25rem'
            mr='0.5rem'
            blue
          />
          <SquareButton
            icon={TrashIcon}
            onClick={() => setArchiveRow(params.row)}
            grey
          />
        </Box>
      )
    }
  ], [language])

  const rows = useMemo(() => {
    let notifications = notificationStore.trackNotifications
    if (searchKey) {
      notifications = notifications.filter((notification: any) => notification?.titleFi?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return notifications
  }, [notificationStore.trackNotifications, searchKey])

  const renderArchiveConfirmation = () => {
    if (archiveRow) {
      return (
        <ConfirmationDialog
          title={t('delete_notification')}
          content={t('delete_notification_confirmation')}
          onClose={() => setArchiveRow(null)}
          cancelText={t('cancel')}
          onAction={handleArchive}
          actionText={t('archive')}
          delete
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={toCreateNotification}
      />
      <Table rows={rows} columns={columns} />
      {renderArchiveConfirmation()}
    </Box>
  )
}

export default observer(Notifications)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem'
  },
  chip: {
    height: '1.75rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: Colors.text,
    backgroundColor: Colors.chip,
    borderRadius: '0.5rem',
    border: 0,
    padding: '0rem 0.25rem'
  }
} as const
