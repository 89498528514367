import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridValueGetterParams
} from '@mui/x-data-grid'
import { Button } from '../../../Components'
import { Colors } from '../../../Utils/theme'

const Organizations = (props: any) => {
  const { sessionStore, organizationStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { language } = sessionStore
  const { loading, totalOrganizations, organizations } = organizationStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [bulkAction, setBulkAction] = useState('')

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  useEffect(() => {
    organizationStore.getOrganizations()
  }, [])

  const toOrganization = (id: number) => navigate(`/organizations/${id}`)
  const toCreateOrganization = () => navigate('/organizations/create')

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: t('organization_name'),
      flex: 1,
      maxWidth: 500,
      minWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.name}
          onClick={() => toOrganization(params.row.id)}
          variant='text'
        />
      )
    },
    {
      field: 'streetAddress',
      headerName: t('address'),
      flex: 1,
      maxWidth: 500,
      minWidth: 200,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.streetAddress && params.row.city) {
          return `${params.row.streetAddress}, ${params.row.city}`
        } else if (params.row.streetAddress) {
          return params.row.streetAddress
        } else if (params.row.city) {
          return params.row.city
        }
        return '-'
      }
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
    },
    {
      field: 'phone',
      headerName: t('phone_number'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'pdg-table--header',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value || '-'
    }
  ], [language])

  const rows = useMemo(() => {
    let organizations = organizationStore.organizations
    if (searchKey) {
      organizations = organizations.filter((organization: any) => organization?.name?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    return organizations
  }, [organizations, searchKey, filters])

  const isAdmin = ['superadmin', 'admin'].includes(sessionStore.user?.role)

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        onAddClick={isAdmin ? toCreateOrganization : null}
      />
      <Table rows={rows} columns={columns} loading={loading} />
    </Box>
  )
}

export default observer(Organizations)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pt: '2rem',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    },
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent
    }
  }
} as const
