import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from './Button'
import ReceiptIcon from '../../Assets/Icons/receipt.svg'
import { Colors } from '../../Utils/theme'

export default function ReceiptModal(props: any) {
  const { onClose, item, field, tracks, isAdmin } = props
  const { t } = useTranslation()

  const getPurchaseDate = () => {
    if (item.createdAt) {
      const date = DateTime.fromISO(item.createdAt).toFormat('dd.MM.yyyy')
      const time = DateTime.fromISO(item.createdAt).toFormat('HH.mm')
      return `${date} ${t('at')} ${time}`
    }
    return '-'
  }

  const getPurchaseType = () => {
    if (item.orderType) {
      return t(item.orderType)
    }
    return '-'
  }

  const getPrice = () => {
    if (item.priceProduct || item.priceProduct === 0) {
      return `${((item.priceProduct * item.productQuantity) / 100).toFixed(2).replace('.', ',')} €`
    }
    return '-'
  }

  const getFee = () => {
    if (item.priceFee || item.priceFee === 0) {
      return `${(item.priceFee / 100).toFixed(2).replace('.', ',')} €`
    }
    return '-'
  }

  const getVat = () => {
    if (item.vatAmount || item.vatAmount === 0) {
      return `${(item.vatAmount / 100).toFixed(2).replace('.', ',')} €`
    }
    return '-'
  }

  const getPriceFeeVat = () => {
    if (item.priceFeeVatAmount) {
      return `${(item.priceFeeVatAmount / 100).toFixed(2).replace('.', ',')} €`
    }
    return '-'
  }

  const getTotal = () => {
    if (item.priceTotal || item.priceTotal === 0) {
      return `${(item.priceTotal / 100).toFixed(2).replace('.', ',')} €`
    }
    return '-'
  }

  const getPaymentMethod = () => {
    if (item.paymentMethod === 'stripe') {
      return t('payment_card_or_mobile_pay')
    } else if (item.paymentMethod === 'payment_code') {
      return t('payment_code')
    } else if (item.paymentMethod === 'season_pass') {
      return t('season_pass')
    } else if (item.paymentMethod === 'season_pass_and_payment_code') {
      return t('season_pass_and_payment_code')
    } else if (item.paymentMethod === 'bill') {
      return t('bill')
    }
    return ''
  }

  const renderRow = (label: string, value: any) => {
    return (
      <Box sx={styles.infoRow}>
        <Typography sx={styles.label}>{label ?? ''}</Typography>
        <Typography sx={styles.value}>{value || value === 0 ? value : ''}</Typography>
      </Box>
    )
  }

  const renderProductName = () => {
    if (item?.orderType === 'product') {
      return renderRow(t('product'), item.productOrder?.productName)
    }
    return null
  }

  const renderField = () => {
    if (item?.fieldId && field) {
      return renderRow(t('field'), field)
    }
    return null
  }

  const renderTrack = () => {
    if (item?.trackId) {
      return renderRow(
        t('track'),
        tracks.find((track: any) => track.value === item.trackId)?.label
      )
    }
    return null
  }

  const renderTrackSlotTime = () => {
    if (item.trackSlotOrder?.startTime) {
      const date = DateTime.fromISO(item.trackSlotOrder.startTime).toFormat('d.M.yyyy')
      const time = DateTime.fromISO(item.trackSlotOrder.startTime).toFormat('HH.mm')
      return renderRow(t('start_time'), `${date} ${t('at')} ${time}`)
    }
    return null
  }

  const renderQuantity = () => {
    if (
      item.orderType !== 'product' &&
      item.orderType !== 'support_payment' &&
      item.productQuantity
    ) {
      return renderRow(t('amount'), item?.productQuantity)
    }
    return null
  }

  const renderPlayerCount = () => {
    if (item.trackSlotId) {
      return renderRow(t('player_count'), item?.productQuantity)
    }
    return null
  }

  const renderPaymentId = () => {
    if (item.externalPaymentId) {
      return renderRow(t('payment_id'), item.externalPaymentId)
    }
    return null
  }
  
  const renderBillNumber = () => {
    if (item?.trackExceptionSlot?.billNumber) {
      return renderRow(t('bill_number'), item.trackExceptionSlot.billNumber)
    }
    return null
  }

  const renderPrice = () => {
    if (item.priceProduct) {
      return renderRow(t('price'), getPrice())
    }
    return null
  }

  const renderUsedSeasonPasses = () => {
    if (item.usedSeasonPasses) {
      return renderRow(
        `${t('season_pass')} x ${item.usedSeasonPasses}`,
        `-${((item.priceProduct * item.usedSeasonPasses) / 100).toFixed(2).replace('.', ',')} €`
      )
    }
    return null
  }

  const renderUsedPaymentCodes = () => {
    if (item.usedPaymentCodes) {
      return renderRow(
        `${t('payment_code')} x ${item.usedPaymentCodes}`,
        `-${((item.usedPaymentCodes * item.priceProduct) / 100).toFixed(2).replace('.', ',')} €`
      )
    }
    return null
  }

  const renderDiscount = () => {
    if (item.discountAmount) {
      return renderRow(
        `${t('discount')} ${item.discountPercent}%`,
        `-${(item.discountAmount / 100).toFixed(2).replace('.', ',')} €`
      )
    }
    return null
  }

  const renderVat = () => {
    if (item.vatAmount || item.vatAmount === 0) {
      return renderRow(`${t('vat')} ${item.vatPercent}%`, getVat())
    }
    return null
  }

  const renderEventFee = () => {
    if (item.priceFee) {
      return renderRow(t('event_fee'), getFee())
    }
    return null
  }

  const renderEventFeeVat = () => {
    if (item.priceFeeVatAmount) {
      return renderRow(`${t('event_fee_vat')} ${item.priceFeeVatPercent}%`, getPriceFeeVat())
    }
    return null
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Box sx={styles.titleRow}>
        <Box component='img' sx={styles.icon} src={ReceiptIcon} alt='icon' />
        <DialogTitle variant='h2' sx={styles.title}>
          {getPurchaseDate()}
        </DialogTitle>
      </Box>
      <DialogContent sx={styles.content}>
        {renderRow(t('purchase_type'), getPurchaseType())}
        {renderProductName()}
        {renderQuantity()}
        {renderField()}
        {renderTrack()}
        {renderTrackSlotTime()}
        {renderPlayerCount()}
        {renderPaymentId()}
        {renderRow(t('payment_method'), getPaymentMethod())}
        {renderBillNumber()}
        {item?.orderType !== 'system_reservation' && <Box style={styles.hr} />}
        {renderPrice()}
        {renderUsedSeasonPasses()}
        {renderUsedPaymentCodes()}
        {renderDiscount()}
        {renderVat()}
        {isAdmin ? renderEventFee() : null}
        {isAdmin ? renderEventFeeVat() : null}
        {isAdmin && item?.orderType !== 'system_reservation' && <Box style={styles.hr} />}
        {isAdmin && item?.orderType !== 'system_reservation' && renderRow(t('total'), getTotal())}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.button}
          text={t('close')}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '36rem',
      width: '36rem',
      borderRadius: '0.625rem',
      padding: '1.5rem 1.5rem'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1.25rem',
    marginBottom: '0.5rem'
  },
  icon: {
    height: '2rem'
  },
  title: {
    color: Colors.heading,
    paddingLeft: '1rem'
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // minHeight: '15.5rem',
    maxHeight: '70vh',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.darkBorder}`,
    p: '2rem 2rem 1.5rem 2rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  label: {
    color: Colors.heading,
    fontWeight: 700,
    fontSize: '1rem'
  },
  value: {
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 400
  },
  hr: {
    borderBottom: `1px solid ${Colors.border}`,
    marginTop: '0.5rem',
    marginBottom: '1rem'
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  textButton: {
    color: Colors.heading,
    fontWeight: 700
  },
  button: {
    width: '11rem',
    fontSize: '1.125rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1.5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    border: 0,
    mt: '1rem'
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  }
} as const
