import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Input, Button } from '../../../Components'
import VerifiedIcon from '../../../Assets/Icons/verified.svg'
import { Colors } from '../../../Utils/theme'

const PasswordChange = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sessionStore }: any = useStore()

  const [passwordChanged, setPasswordChanged] = useState(false)

  let code = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      code = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (code) {
      sessionStore.getPasswordResetRequest(code)
    }
  }, [code])

  useEffect(() => {
    if (passwordChanged) {
      setTimeout(() => navigate('/login'), 3000)
    }
  }, [passwordChanged])

  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordAgainVisible, setPasswordAgainVisible] = useState(false)

  const togglePasswordVisible = () => setPasswordVisible(!passwordVisible)
  const togglePasswordAgainVisible = () => setPasswordAgainVisible(!passwordAgainVisible)

  const handleKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && password && passwordAgain) {
      save()
    }
  }

  const save = () => {
    if (password && password !== passwordAgain) {
      // TODO: show error
      return
    }
    const callback = () => {
      setPasswordChanged(true)
    }
    sessionStore.completePasswordReset(password, callback)
  }

  const renderContent = () => {
    if (code && sessionStore.passwordResetRequest) {
      if (passwordChanged) {
        return (
          <>
            <Typography variant='h1' sx={styles.title} mb='2rem'>{t('password_changed_title')}</Typography>
            <Box component='img' src={VerifiedIcon} alt='verified' />
            <Typography variant='body1' sx={styles.info} mt='3rem'>{t('password_changed_info')}</Typography>
          </>
        )
      }
      return (
        <>
          <Typography variant='h1' sx={styles.title}>{t('enter_new_password')}</Typography>
          <Typography variant='body1' sx={styles.info}>{t('enter_new_password_info')}</Typography>
          <Input
            type={passwordVisible ? 'text' : 'password'}
            placeholder={t('password')}
            value={password}
            onChange={setPassword}
            onAction={togglePasswordVisible}
            handleKeyPress={handleKeyPress}
            password
            auth
          />
          <Input
            type={passwordAgainVisible ? 'text' : 'password'}
            placeholder={t('password_again')}
            value={passwordAgain}
            onChange={setPasswordAgain}
            onAction={togglePasswordAgainVisible}
            handleKeyPress={handleKeyPress}
            password
            auth
          />
          <Button
            variant='alt'
            sx={styles.button}
            text={t('save')}
            onClick={save}
            disabled={!password || !passwordAgain || password !== passwordAgain}
          />
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      {renderContent()}
    </Box>
  )
}

export default observer(PasswordChange)

const styles = {
  container: {
    width: {
      sm: '40rem',
      xs: '100%'
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    color: Colors.white,
    mb: '2rem'
  },
  info: {
    fontSize: '1rem',
    color: Colors.white,
    marginBottom: '2.5rem',
    textAlign: 'center'
  },
  button: {
    width: '100%',
    minHeight: '3.25rem',
    mt: '1.25rem'
  }
} as const
