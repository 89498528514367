import { makeAutoObservable } from 'mobx'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import { Api } from '../Services'

export default class FieldStore {
  rootStore

  fields: any = []
  totalFields = 0
  field: any = null
  fieldTracks: any = []
  track: any = null
  loading: boolean = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateFields = (organizations: any) => { this.fields = organizations }
  updateTotalFields = (total: any) => { this.totalFields = total }
  updateField = (organization: any) => { this.field = organization }
  updateFieldTracks = (fieldTracks: any) => { this.fieldTracks = fieldTracks }
  updateTrack = (track: any) => { this.track = track }
  updateLoading = (loading: boolean) => { this.loading = loading }

  async getAllFields() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getAllFields({
        skip: 0,
        take: 10000,
        eagerTracks: true
      })

      if (response.ok) {
        const fields = response.data?.items || []
        const total = response.data?.total || 0
        this.updateFields(fields)
        this.updateTotalFields(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationFields(organizationId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationFields(organizationId, {
        skip: 0,
        take: 10000,
        eagerTracks: true
      })

      if (response.ok) {
        const fields = response.data?.items || []
        const total = response.data?.total || 0
        this.updateFields(fields)
        this.updateTotalFields(total)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationField(organizationId: number, fieldId: number) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getOrganizationField(
        organizationId,
        fieldId,
        { eagerTracks: true }
      )
      if (response.ok) {
        this.updateField(response.data)
        this.updateFieldTracks(response.data?.tracks || [])
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createOrganizationField(organizationId: number, field: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationField(organizationId, field)
      if (response.ok) {
        this.updateField(response.data)
        showMessage(i18n.t('field_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('field_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async editOrganizationField(organizationId: number, fieldId: number, field: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationField(organizationId, fieldId, field)
      if (response.ok) {
        this.updateField(response.data)
        showMessage(i18n.t('field_updated'))
      } else {
        showMessage(i18n.t('field_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getOrganizationFieldTrack(organizationId: number, fieldId: number, trackId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.getOrganizationFieldTrack(organizationId, fieldId, trackId)
      if (response.ok) {
        const track = response.data ?? null
        this.updateTrack(track)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createOrganizationFieldTrack(organizationId: number, fieldId: number, track: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationFieldTrack(organizationId, fieldId, track)
      if (response.ok) {
        const track = response.data ?? null
        this.updateTrack(track)
        showMessage(i18n.t('track_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('track_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateOrganizationFieldTrack(organizationId: number, fieldId: number, trackId: number, track: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationFieldTrack(organizationId, fieldId, trackId, track)
      if (response.ok) {
        const track = response.data ?? null
        this.updateTrack(track)
        showMessage(i18n.t('track_updated'))
      } else {
        showMessage(i18n.t('track_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateOrganizationFieldTrackPhoto(organizationId: number, fieldId: number, trackId: number, file: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationFieldTrackPhoto(organizationId, fieldId, trackId, file)
      if (response.ok) {
        const track = response.data ?? null
        this.updateTrack(track)
        showMessage(i18n.t('track_photo_updated'))
      } else {
        showMessage(i18n.t('track_photo_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async deleteOrganizationFieldTrackPhoto(organizationId: number, fieldId: number, trackId: number, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.deleteOrganizationFieldTrackPhoto(organizationId, fieldId, trackId)
      if (response.ok) {
        const track = response.data ?? null
        this.updateTrack(track)
        showMessage(i18n.t('track_photo_deleted'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('track_photo_delete_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async searchForLocation(query: string) {
    this.updateLoading(true)
    try {
      const response = await Api.searchForLocation(query)
      if (response.ok) {
        return response.data
      } else {
        showMessage(i18n.t('location_search_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async createOrganizationFieldSeason(organizationId: number, fieldId: number, season: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.createOrganizationFieldSeason(organizationId, fieldId, season)
      if (response.ok) {
        showMessage(i18n.t('season_created'))
        this.getOrganizationField(organizationId, fieldId)
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('season_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateOrganizationFieldSeason(organizationId: number, fieldId: number, seasonId: number, season: any, callback?: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateOrganizationFieldSeason(organizationId, fieldId, seasonId, season)
      if (response.ok) {
        showMessage(i18n.t('season_updated'))
        this.getOrganizationField(organizationId, fieldId)
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('season_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async archiveOrganizationFieldSeason(organizationId: number, fieldId: number, seasonId: number) {
    this.updateLoading(true)
    try {
      const response = await Api.archiveOrganizationFieldSeason(organizationId, fieldId, seasonId)
      if (response.ok) {
        showMessage(i18n.t('season_archived'))
        this.getOrganizationField(organizationId, fieldId)
      } else {
        showMessage(i18n.t('season_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
