import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SessionStore {
  rootStore

  user: any = null
  language: string = 'fi'
  loading = false
  userInvite: any = null
  passwordResetRequest: any = null
  keepAliveStarted: boolean = false
  isLoggedIn = false
  initialized = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateUser = (user: any) => { this.user = user }
  updateLanguage = (language: string) => { this.language = language }
  updateIsLoggedIn = (isLoggedIn: boolean) => { this.isLoggedIn = isLoggedIn }
  updateInitialized = (initialized: boolean) => { this.initialized = initialized }
  updateUserInvite = (userInvite: any) => { this.userInvite = userInvite }
  updatePasswordResetRequest = (passwordResetRequest: any) => { this.passwordResetRequest = passwordResetRequest }
  updateKeepAliveStarted = (started: any) => { this.keepAliveStarted = started }
  updateLoading = (loading: boolean) => { this.loading = loading }

  async login(email: string, password: string) {
    this.updateLoading(true)
    try {
      const response: any = await Api.login({ email, password })
      if (response.ok) {
        const user = response?.data ?? null
        this.updateUser(user)
        this.updateIsLoggedIn(true)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async logout() {
    this.updateLoading(true)
    try {
      const response = await Api.logout()
      if (response.ok) {
        this.updateUser(null)
        this.updateIsLoggedIn(false)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getMe() {
    this.updateLoading(true)
    try {
      const response = await Api.getMe()
      if (response.ok) {
        const user = response.data ?? null
        this.updateUser(user)
        this.updateIsLoggedIn(true)
        this.startKeepAlive()
      }
    } catch (e) {
      console.log(e)
    }
    this.updateInitialized(true)
    this.updateLoading(false)
  }

  async updateMe(data: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateMe(data)
      if (response.ok) {
        const user = response.data ?? null
        this.updateUser(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async runKeepAlive() {
    if (this.isLoggedIn && this.initialized) {
      const result = await Api.keepAlive()
      if (result.status === 401) {
        this.updateUser(null)
        this.updateIsLoggedIn(false)
      }
    }
  }

  async startKeepAlive() {
    if (!this.keepAliveStarted) {
      this.updateKeepAliveStarted(true)
      setTimeout(this.runKeepAlive, 1000)
      setInterval(() => this.runKeepAlive(), 1000 * 60 * 60)
    }
  }
  async getUserInvite(code: string) {
    this.updateLoading(true)
    try {
      const response = await Api.getInvite(code)
      if (response.ok) {
        const invite = response?.data ?? null
        this.updateUserInvite(invite)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async verifyUserAccount(password: string, callback: any) {
    this.updateLoading(true)
    try {
      const verifyBody = {
        code: this.userInvite?.code,
        inviteId: this.userInvite?.id,
        password
      }
      const response = await Api.verifyAccount(verifyBody)
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getPasswordResetRequest(code: string) {
    this.updateLoading(true)
    try {
      const response = await Api.getPasswordResetRequest(code)
      if (response.ok) {
        const passwordResetRequest = response?.data ?? null
        this.updatePasswordResetRequest(passwordResetRequest)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async requestPasswordReset(email: string, callback: any) {
    this.updateLoading(true)
    try {
      const response = await Api.requestPasswordReset({ email })
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async completePasswordReset(password: string, callback: any) {
    this.updateLoading(true)
    try {
      const passwordResetBody = {
        code: this.passwordResetRequest?.code,
        resetId: this.passwordResetRequest?.id,
        password
      }
      const response = await Api.completePasswordReset(passwordResetBody)
      if (response.ok) {
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async updateMyProfilePhoto(data: any) {
    this.updateLoading(true)
    try {
      const response = await Api.updateMyProfilePhoto(data)
      if (response.ok) {
        const user = response.data ?? null
        this.updateMe(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async deleteMyProfilePhoto() {
    this.updateLoading(true)
    try {
      const response = await Api.deleteMyProfilePhoto()
      if (response.ok) {
        const user = response.data ?? null
        this.updateMe(user)
        showMessage(i18n.t('profile_updated'))
      } else {
        showMessage(i18n.t('profile_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }
}
